import { combineReducers } from "redux";
import { auth } from "./auth.reducer";
import { dashboard } from "./dashboard.reducer";
import { user } from "./user.reducer";
import { rider } from "./rider.reducer";
import { trackOrder } from "./trackOrder.reducer";
import { wallet } from "./wallet.reducer";
import { profile } from "./profiles.reducer";
import { settings } from "./settings.reducer";
import { distance } from "./distance.reducer";
import { interState } from "./interState.reducer";
import { intraState } from "./intraState.reducer";
import { delivery } from "./delivery.reducer";
import { deliveryOptions } from "./delivery.reducer";
import { team } from "./team.reducer";
import { teamOptions } from "./team.reducer";
import { ride } from "./delivery.reducer";
import { rideOptions } from "./delivery.reducer";
import { deliveryTime } from "./delivery.reducer";
import { deliveryTimeOptions } from "./delivery.reducer";
import { category } from "./category.reducer";
import { mappings } from "./mappings.reducer";
import { location, locationOption } from "./location.reducer";
import { summary } from "./delivery.reducer";
import { color } from "./settings.reducer";
import {
  subscription,
  subscriptionHistory,
  subscriptionPay,
} from "./subscription.reducer";
import { chart } from "./charts.reducer";
import { assignPriviledge, priviledge, role } from "./role.reducer";
import { assignOrders, order } from "./order.reducer";
import { orderDetails } from "./order.reducer";
import { completedOrderDetails } from "./order.reducer";

const rootReducer = combineReducers({
  auth,
  dashboard,
  user,
  rider,
  wallet,
  trackOrder,
  profile,
  settings,
  distance,
  interState,
  intraState,
  delivery,
  deliveryOptions,
  team,
  teamOptions,
  ride,
  rideOptions,
  deliveryTime,
  deliveryTimeOptions,
  category,
  mappings,
  location,
  locationOption,
  summary,
  color,
  subscription,
  chart,
  subscriptionPay,
  role,
  subscriptionHistory,
  order,
  assignOrders,
  orderDetails,
  completedOrderDetails,
  priviledge,
  assignPriviledge,
});

export default rootReducer;
