
import { userTypes } from "../types";
import axios from "axios";
import { axiosConfig, requestStatus } from "../constants";
import { config } from '../config'
import Utils from "../helpers/utils";


const USER_BASE_URL = `${config.testUrl}/users`

// dispatchers
const getUser = (pageRequest) => async (dispatch) =>{
    dispatch(loading(true))
    let reqPagination = pageRequest ? pageRequest : {current: 1, pageSize: 10}
    let url = `${USER_BASE_URL}?rows=${reqPagination.pageSize}&page=${reqPagination.current}`
    let header = axiosConfig()
    
    try {
        const response = await axios.get(url, header);
        const data = response.data;
        if(data && data.pagination){
            let payload = dataConstructor(data)
            dispatch(get_user(payload))
        }   
        dispatch(loading(false))

    } catch (error) {
        dispatch(get_user({status: requestStatus.failed}))
        dispatch(loading(false))
        Utils.warningNotification('An error occured');
        return error && error.response
    }
}

const filterUser = (pageRequest) => async(dispatch) =>{
   if(pageRequest.start_date.trim() === "")  {
       dispatch(filter_user({status: requestStatus.failed}))
    }
   let reqPagination = pageRequest?.pagination || {current: 1, pageSize: 10}
   let url = `${USER_BASE_URL}/filter?start_date=${pageRequest.start_date}&end_date=${pageRequest.end_date}&rows=${reqPagination.pageSize}&page=${reqPagination.current}`
   let header = axiosConfig()
    
   try {
     const response = await axios.get(url, header);
    dispatch(loading(true))
       const data = response.data;
       if(data && data.pagination){
          let payload = dataConstructor(data)
           dispatch(filter_user(payload))
            dispatch(loading(false))
       }   
   } catch (error) {
       dispatch(filter_user({status: requestStatus.failed}))
       dispatch(loading(false))
       return error && error.response
   }
}
const disableUser = (id) => async(dispatch) =>{
    let url = `${config.testUrl}/user/${id}`
    let header = axiosConfig()
    
    try {
        const response = await axios.post(url, {status: 'disabled'}, header);
        const data = response.data;
        if(data && data.success){
            Utils.successNotification('Action Successful');
            dispatch(getUser())
        }   
    } catch (error) {
        return error && error.response
    }
}
const deleteUser = (id) => async(dispatch) =>{
    let url = `${config.testUrl}/user/${id}`
    let header = axiosConfig()

    try {
        const response = await axios.delete(url, header);
        const data = response.data;
        if(data){
            Utils.successNotification('Action Successful')
            dispatch(getUser())
        }   
    } catch (error) {
        return error && error.response
    }
}

// actions
const get_user = payload => {
    return{
        type: userTypes.GET_USER_LIST,
        payload
    }
}
const loading = payload => {
    return{
        type: userTypes.LOADING,
        payload
    }
}
const filter_user = payload => {
    return{
        type: userTypes.FILTER_USER_LIST,
        payload
    }
}
// helper function
const dataConstructor = (data) =>{
    let pagination = {
        current: data.pagination.current_page,
        total: data.pagination.total,
        pageSize: data.pagination.per_page,
      }
    let payload = {
        data: data.pagination.data,
        pagination,
        status: requestStatus.success
    }
    return payload
}
export const userActions = {
    getUser,
    filterUser,
    disableUser,
    deleteUser
}