import { brandColorTypes, settingsTypes } from "../types"


export function settings (state = {}, action) {
  switch (action.type) {
    case settingsTypes.GET_PAYMENT_OPTIONS_REQUEST:
      return {
        ...state
      }
    case settingsTypes.GET_PAYMENT_OPTIONS_SUCCESS:
      return {
        ...state,
        settings: action.settings
      }
    case settingsTypes.GET_PAYMENT_OPTIONS_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case settingsTypes.UPDATE_MULTIPLE_STATUS_REQUEST:
    return {
        ...state
    }
    case settingsTypes.UPDATE_MULTIPLE_STATUS_SUCCESS:
    return {
        ...state,
        // settings: action.settings
    }
    case settingsTypes.UPDATE_MULTIPLE_STATUS_FAILURE:
    return {
        ...state,
        error: action.error
        }
    case settingsTypes.GET_RIDER_SETTINGS_REQUEST:
    return {
        ...state
    }
    case settingsTypes.GET_RIDER_SETTINGS_SUCCESS:
    return {
        ...state,
        settings: action.settings
    }
    case settingsTypes.GET_RIDER_SETTINGS_FAILURE:
    return {
        ...state,
        error: action.error
        }
    case settingsTypes.UPDATE_RIDER_REQUEST:
    return {
        ...state
    }
    case settingsTypes.UPDATE_RIDER_SUCCESS:
    return {
        ...state,
        // settings: action.settings
    }
    case settingsTypes.UPDATE_RIDER_FAILURE:
    return {
        ...state,
        error: action.error
        }
    default:
      return state
  }
}


export function color (state = {}, action) {
  switch (action.type) {
    case brandColorTypes.GET_BRAND_COLOR_REQUEST:
      return {
        ...state
      }
    case brandColorTypes.GET_BRAND_COLOR_SUCCESS:
      return {
        ...state,
        color: action.color
      }
    case brandColorTypes.GET_BRAND_COLOR_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case brandColorTypes.UPDATE_BRAND_COLOR_REQUEST:
    return {
        ...state
    }
    case brandColorTypes.UPDATE_BRAND_COLOR_SUCCESS:
    return {
        ...state,
    }
    case brandColorTypes.UPDATE_BRAND_COLOR_FAILURE:
    return {
        ...state,
        error: action.error
        }
    default:
      return state
  }
}

