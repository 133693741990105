export const ongoingOders = [
    {
      key: '1',
      sender_name: 'Andrew Dubai',
      status:  'Picked Up',
      receiver_name: 'Emmanuel Ify',
      item_description: 'Laptop Bag',
      amount: '5000',
    },
    {
      key: '2',
      sender_name: 'Tobi Timothy',
      status:  'Picked Up',
      receiver_name: 'Elijah Badui',
      item_description: 'Muffin Serum',
      amount: '2000',

    },
    {
      key: '3',
      sender_name: 'Mundane Light',
      status:  'Ongoing',
      receiver_name: 'Sherrif',
      item_description: 'Car',
      amount: '100000',


    },
  ];

  export const completedOrders = [
    {
      key: '1',
      sender_name: 'Andrew Dubai',
      status:  'Completed',
      receiver_name: 'John Smith',
      item_description: 'Benz Car',
      amount: '20000',
    },
    {
      key: '2',
      sender_name: 'Tobi Timothy',
      status:  'Completed',
      receiver_name: 'John Smith',
      item_description: 'Laptop Model',
      amount: '5000',
    },
    {
      key: '3',
      sender_name: 'Mundane Light',
      status:  'Completed',
      receiver_name: 'John Smith',
      item_description: 'Ferarri Wagon',
      amount: '3000',
    },
  ];