import React, {useEffect} from "react";
import { Tabs, Button} from "antd";
import { Icon } from "@iconify/react";
import BikesTable from "./components/BikeTable";
import "./style.css";
import Headers from "../../components/Header";
import RiderTable from './components/RiderTable'
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../components/Spinner";
import { riderActions } from '../../actions'

const { TabPane } = Tabs;

const Bikes = (props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(riderActions.getRider())
  }, [dispatch]);

  const rider = useSelector(state=>state.rider)
  const riderList = !!rider?.riderList 
  return (
    <div className="containerWrapper lg:h-screen flex flex-col px-0 lg:p-8">
      <Headers />
    { !riderList ? <Spinner/> :
      <div className="container my4 riderTabs">
          <Tabs defaultActiveKey="1">
            <TabPane tab={<h3>Bike List</h3>} key="1">
               <BikesTable />
            </TabPane>
            <TabPane tab={<h3>Riders Catalog</h3>} key="2">
              <RiderTable/>
            </TabPane>
          </Tabs>
    

          <div className="absolute abs">
            <Button className="btndownload">
              <Icon
                className="icons"
                icon="ant-design:cloud-download-outlined"
                color="#006FC1"
              />
              Download
            </Button>
          </div>
      </div>
}
    </div>
  );
};

export default Bikes;
