import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import EditProfile from './EditProfile'
import Notifications from './Notifications'
import PasswordSecurity from './PasswordSecurity'

const MyProfile = () => {
    return (
        <div className='flex flex-col'>
            <section>
                <Link to='/merchant-admin/setting/my-profile' className='text-black font-bold focus:text-hex-blue'>Edit Profile</Link>
                <Link to='/merchant-admin/setting/my-profile/notifications' className='text-black font-bold focus:text-hex-blue mx-8'>Notifications</Link>
                <Link to='/merchant-admin/setting/my-profile/password-security' className='text-black font-bold focus:text-hex-blue'>Change Password</Link>
            </section>
            <section className='flex-1'>
              <Switch>
                <Route exact path='/merchant-admin/setting/my-profile' component={EditProfile} />
                <Route path='/merchant-admin/setting/my-profile/notifications' component={Notifications} />
                <Route path='/merchant-admin/setting/my-profile/password-security' component={PasswordSecurity} />
              </Switch>
              </section>
        </div>
    )
}

export default MyProfile
