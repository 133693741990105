import React from 'react'
import Header from '../../components/Header'
import { Link, Route, Switch } from 'react-router-dom'
import CustomizeApplication from './CustomizeApplication'
import BusinessProfileSetting from './BusinessProfileSetting'
import ManageRiders from './ManageRiders'
import ManageOrders from './ManageOrders'
import Subscription from './Subscription'
import MyProfile from './MyProfile'
import PriviledgeRoles from './PriviledgeRoles'
import Team from './Team'

const Settings = () => {
  return (
    <div className='lg:h-screen flex flex-col px-0 lg:p-8'>
      <Header />

      <div className='flex-1 w-full bg-white mt-8 rounded-xl p-8'>
          
          <h1 style={{color:'#4A6387'}} className='text-md border-b border-gray-200 pb-2'>Settings</h1>

          <div className='h-full flex-1 flex flex-col lg:flex-row justify-between'>
            <section className='h-full'>
            <div className='mt-0'>
              <Link to='/merchant-admin/setting/customize-application' className='setting-link'>
                    <div className='setting-icon'>
                      <img src={`${process.env.REACT_APP_IMAGE}/setting-1.svg`} alt='' className='w-8' />
                    </div>
                    <p className='my-auto mx-4 font-bold'>
                    Customize Application
                    </p>
              </Link>
              <Link to='/merchant-admin/setting/business-profile-setting' className='setting-link'>
                    <div className='setting-icon'>
                      <img src={`${process.env.REACT_APP_IMAGE}/setting-2.svg`} alt='' className='w-8' />
                    </div>
                    <p className='my-auto mx-4 font-bold'>
                    Business Profile Setting
                    </p>
              </Link>
              <Link to='/merchant-admin/setting/priviledge-roles' className='setting-link'>
                    <div className='setting-icon'>
                      <img src={`${process.env.REACT_APP_IMAGE}/setting-3.svg`} alt='' className='w-8' />
                    </div>
                    <p className='my-auto mx-4 font-bold'>
                    Priviledge Roles
                    </p>
              </Link>
              <Link to='/merchant-admin/setting/manage-riders' className='setting-link'>
                    <div className='setting-icon'>
                      <img src={`${process.env.REACT_APP_IMAGE}/setting-4.svg`} alt='' className='w-8' />
                    </div>
                    <p className='my-auto mx-4 font-bold'>
                    Manage Riders
                    </p>
              </Link>
              <Link to='/merchant-admin/setting/subscription' className='setting-link'>
                    <div className='setting-icon'>
                      <img src={`${process.env.REACT_APP_IMAGE}/setting-5.svg`} alt='' className='w-8' />
                    </div>
                    <p className='my-auto mx-4 font-bold'>
                    Subscription
                    </p>
              </Link>
              <Link to='/merchant-admin/setting/manage-orders' className='setting-link'>
                    <div className='setting-icon'>
                      <img src={`${process.env.REACT_APP_IMAGE}/setting-6.svg`} alt='' className='w-8' />
                    </div>
                    <p className='my-auto mx-4 font-bold'>
                    Manage Orders
                    </p>
              </Link>
              <Link to='/merchant-admin/setting/my-profile' className='setting-link'>
                    <div className='setting-icon'>
                      <img src={`${process.env.REACT_APP_IMAGE}/setting-7.svg`} alt='' className='w-8' />
                    </div>
                    <p className='my-auto mx-4 font-bold'>
                    My Profile
                    </p>
              </Link>
              <Link to='/merchant-admin/setting/team' className='setting-link'>
                    <div className='setting-icon'>
                      <img src={`${process.env.REACT_APP_IMAGE}/setting-8.svg`} alt='' className='w-8' />
                    </div>
                    <p className='my-auto mx-4 font-bold'>
                    Team
                    </p>
              </Link>
            </div>
            </section>
          
            <section className='flex-1 h-full bg-light-gray p-8 rounded-md overflow-y-scroll'>
              <Switch>
                <Route path='/merchant-admin/setting/customize-application' component={CustomizeApplication} />
                <Route path='/merchant-admin/setting/business-profile-setting' component={BusinessProfileSetting} />
                <Route path='/merchant-admin/setting/priviledge-roles' component={PriviledgeRoles} />
                <Route path='/merchant-admin/setting/manage-riders' component={ManageRiders} />
                <Route path='/merchant-admin/setting/manage-orders' component={ManageOrders} />
                <Route path='/merchant-admin/setting/subscription' component={Subscription} />
                <Route path='/merchant-admin/setting/my-profile' component={MyProfile} />
                <Route path='/merchant-admin/setting/team' component={Team} />
              </Switch>
            </section>
          </div>
      </div>
    </div>
  )
}

export default Settings
