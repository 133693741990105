export const roleTypes = {
  GET_ROLES_REQUEST: "GET_ROLES_REQUEST",
  GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
  GET_ROLES_FAILURE: "GET_ROLES_FAILURE",

  CREATE_ROLES_REQUEST: "CREATE_ROLES_REQUEST",
  CREATE_ROLES_SUCCESS: "CREATE_ROLES_SUCCESS",
  CREATE_ROLES_FAILURE: "CREATE_ROLES_FAILURE",
};

export const priviledgeTypes = {
  GET_ALL_PRIVILEDGES_REQUEST: "GET_ALL_PRIVILEDGES_REQUEST",
  GET_ALL_PRIVILEDGES_SUCCESS: "GET_ALL_PRIVILEDGES_SUCCESS",
  GET_ALL_PRIVILEDGES_FAILURE: "GET_ALL_PRIVILEDGES_FAILURE",

  PUT_PRIVILEDGES_REQUEST: "PUT_PRIVILEDGES_REQUEST",
  PUT_PRIVILEDGES_SUCCESS: "PUT_PRIVILEDGES_SUCCESS",
  PUT_PRIVILEDGES_FAILURE: "PUT_PRIVILEDGES_FAILURE",
};

export const assignPriviledgeTypes = {
  GET_ASSIGNED_PRIVILEDGES_REQUEST: "GET_ASSIGNED_PRIVILEDGES_REQUEST",
  GET_ASSIGNED_PRIVILEDGES_SUCCESS: "GET_ASSIGNED_PRIVILEDGES_SUCCESS",
  GET_ASSIGNED_PRIVILEDGES_FAILURE: "GET_ASSIGNED_PRIVILEDGES_FAILURE",
};
