import { profileTypes } from "../types"


export function profile (state = {}, action) {
  switch (action.type) {
    case profileTypes.GET_USER_PROFILE_REQUEST:
      return {
        ...state
      }
    case profileTypes.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.profile
      }
    case profileTypes.GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case profileTypes.POST_USER_PROFILE_REQUEST:
    return {
        ...state
    }
    case profileTypes.POST_USER_PROFILE_SUCCESS:
    return {
        ...state,
        // profile: action.profile
    }
    case profileTypes.POST_USER_PROFILE_FAILURE:
    return {
        ...state,
        error: action.error
    }
    case profileTypes.UPDATE_NOTIFICATION_REQUEST:
    return {
        ...state
    }
    case profileTypes.UPDATE_NOTIFICATION_SUCCESS:
    return {
        ...state,
        // profile: action.profile
    }
    case profileTypes.UPDATE_NOTIFICATION_FAILURE:
    return {
        ...state,
        error: action.error
        }
    case profileTypes.CHANGE_PASSWORD_REQUEST:
    return {
        ...state
    }
    case profileTypes.CHANGE_PASSWORD_SUCCESS:
    return {
        ...state,
        profile: action.profile
    }
    case profileTypes.CHANGE_PASSWORD_FAILURE:
    return {
        ...state,
        error: action.error
                }
    case profileTypes.UPDATE_BUSINESS_REQUEST:
    return {
        ...state
    }
    case profileTypes.UPDATE_BUSINESS_SUCCESS:
    return {
        ...state,
        // profile: action.profile
    }
    case profileTypes.UPDATE_BUSINESS_FAILURE:
    return {
        ...state,
        error: action.error
                }
    case profileTypes.GET_BUSINESS_REQUEST:
      return {
          ...state
      }
      case profileTypes.GET_BUSINESS_SUCCESS:
      return {
          ...state,
          profile: action.profile
      }
      case profileTypes.GET_BUSINESS_FAILURE:
      return {
          ...state,
          error: action.error
                  }
    default:
      return state
  }
}
