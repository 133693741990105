import React from 'react';
import { Card, Divider } from 'antd';
import DropDown from './DropDown'
import Utils from '../../../helpers/utils';

const Mobilecard = ({data}) => {
    const cards = data.map((data) => {
      let stat = data.status || "-"
      return (
        <Card key={data.key} className="my4 bikeCard">
          <div className="flex space-btw">
            <p style={{color: "#006FC1"}}><b>{data.user.full_name}</b></p>
            <p > {stat}</p >

          </div>
          <div>
            <span>{data.payment_channel || "-"}</span>
            <Divider type="vertical" />
            <span className="greyText">{Utils.currencyFormater(data.amount)}</span>
          </div>
          <div className="flex space-btw">
          <div><small>{data.description}</small><Divider type="vertical" /><small>{data.date}</small></div>
          <DropDown data={data}/>
          </div>
        </Card>
      );
    });
    return (
        <div>
            {cards}
        </div>
    );
}

export default Mobilecard;
