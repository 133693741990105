import { roleTypes, priviledgeTypes, assignPriviledgeTypes } from "../types";
import { roleService } from "../services";

export const roleActions = {
  getRoles,
  createRoles,
};

export const priviledgeActions = {
  getAllPriviledges,
  putPriviledges,
};

export const assignPriviledgeActions = {
  getAssignedPriviledges,
};

function getRoles() {
  return async (dispatch) => {
    dispatch(request());

    try {
      const role = await roleService.getRoles();
      dispatch(success(role));
      return role;
    } catch (error) {
      dispatch(failure(error.toString()));
      return error?.response;
    }
  };

  function request() {
    return { type: roleTypes.GET_ROLES_REQUEST };
  }
  function success(role) {
    return { type: roleTypes.GET_ROLES_SUCCESS, role };
  }
  function failure(error) {
    return { type: roleTypes.GET_ROLES_FAILURE, error };
  }
}

function createRoles(formdata) {
  return async (dispatch) => {
    dispatch(request(formdata));

    try {
      const role = await roleService.createRole(formdata);
      dispatch(success(role));
      return role;
    } catch (error) {
      dispatch(failure(error.toString()));
      return error?.response;
    }
  };

  function request(formdata) {
    return { type: roleTypes.CREATE_ROLES_REQUEST, formdata };
  }
  function success(role) {
    return { type: roleTypes.CREATE_ROLES_SUCCESS, role };
  }
  function failure(error) {
    return { type: roleTypes.CREATE_ROLES_FAILURE, error };
  }
}

function getAllPriviledges() {
  return async (dispatch) => {
    dispatch(request());

    try {
      const priviledge = await roleService.getAllPriviledges();
      dispatch(success(priviledge));
      return priviledge;
    } catch (error) {
      dispatch(failure(error.toString()));
      return error?.response;
    }
  };

  function request() {
    return { type: priviledgeTypes.GET_ALL_PRIVILEDGES_REQUEST };
  }
  function success(priviledge) {
    return { type: priviledgeTypes.GET_ALL_PRIVILEDGES_SUCCESS, priviledge };
  }
  function failure(error) {
    return { type: priviledgeTypes.GET_ALL_PRIVILEDGES_FAILURE, error };
  }
}

function getAssignedPriviledges(id) {
  return async (dispatch) => {
    dispatch(request(id));

    try {
      const assignPriviledge = await roleService.getAssignedPriviledges(id);
      dispatch(success(assignPriviledge));
      return assignPriviledge;
    } catch (error) {
      dispatch(failure(error.toString()));
      return error?.response;
    }
  };

  function request(id) {
    return { type: assignPriviledgeTypes.GET_ASSIGNED_PRIVILEDGES_REQUEST, id };
  }
  function success(priviledge) {
    return {
      type: assignPriviledgeTypes.GET_ASSIGNED_PRIVILEDGES_SUCCESS,
      priviledge,
    };
  }
  function failure(error) {
    return {
      type: assignPriviledgeTypes.GET_ASSIGNED_PRIVILEDGES_FAILURE,
      error,
    };
  }
}

function putPriviledges(id, formdata) {
  return async (dispatch) => {
    dispatch(request(id, formdata));

    try {
      const priviledge = await roleService.putPriviledges(id, formdata);
      dispatch(success(priviledge));
      return priviledge;
    } catch (error) {
      dispatch(failure(error.toString()));
      return error?.response;
    }
  };

  function request(id, formdata) {
    return { type: priviledgeTypes.PUT_PRIVILEDGES_REQUEST, id, formdata };
  }
  function success(priviledge) {
    return { type: priviledgeTypes.PUT_PRIVILEDGES_SUCCESS, priviledge };
  }
  function failure(error) {
    return { type: priviledgeTypes.PUT_PRIVILEDGES_FAILURE, error };
  }
}
