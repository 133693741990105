import { chartsTypes } from "../types"
import { chartService } from "../services"

export const chartActions = {
    getChartsInfo
}

function getChartsInfo (params,month,year) {
    return async dispatch => {
      dispatch(request(params,month,year))
  
      try {
        const chart = await chartService.getChartsInfo(params,month,year)
        dispatch(success(chart))
        return chart

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (params,month,year) { return { type: chartsTypes.GET_CHARTS_REQUEST,params,month,year } }
    function success (chart) { return { type: chartsTypes.GET_CHARTS_SUCCESS, chart } }
    function failure (error) { return { type: chartsTypes.GET_CHARTS_FAILURE, error } }
    
  }
