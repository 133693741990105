import React, { Fragment,useState,useEffect } from 'react'
import { Tabs } from 'antd';
import InterPricing from './InterPricing';
import InterLocation from './InterLocation';
import IntraPricing from './IntraPricing';
import IntraLocation from './IntraLocation';
import { mappingsActions,categoryActions ,locationActions } from '../../../../../actions';
import { useDispatch ,useSelector} from 'react-redux';

const { TabPane } = Tabs;

const InterState = () => {
    const category = useSelector(state=>state.category)
    const mappings = useSelector(state=>state.mappings)
    const location = useSelector(state=>state.location)

    
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(mappingsActions.getMappings('inter'))
    }, [dispatch])

    useEffect(() => {
        dispatch(categoryActions.getCategories('inter'))
     }, [dispatch])

     let interCategory = category?.category?.data

     useEffect(() => {
        dispatch(locationActions.getLocations(interCategory[0]?.id))
// eslint-disable-next-line
     }, [dispatch])

    let interMapping =  mappings?.mappings?.data
    let interLocation = location?.location?.data

    const [show,setShow] = useState(false)

        return(<Fragment>
            <div>
                <button onClick={()=>setShow(!show)} className={!show ? 'text-white p-1 px-2 rounded-sm text-xs bg-hex-blue' : 'text-xs'}>Pricing</button>
                <button onClick={()=>setShow(!show)} className={show ? 'text-white p-1 px-2 rounded-sm text-xs bg-hex-blue mx-8' :'text-xs mx-8'}>Adding Location</button>
            </div>

            <div className='mt-12'>
                {!show ? (<InterPricing interCategory={interCategory} interMapping={interMapping} />) : (<InterLocation interCategory={interCategory} interLocation={interLocation} />)}
            </div>
        </Fragment>)
}

const IntraState = () => {
    const dispatch = useDispatch()
    const category = useSelector(state=>state.category)
    const mappings = useSelector(state=>state.mappings)
    const location = useSelector(state=>state.location)


    useEffect(() => {
        dispatch(categoryActions.getCategories('intra'))
     }, [dispatch])

    let intraCategory = category?.category?.data
    let intraMapping =  mappings?.mappings?.data
    let interLocation = location?.location?.data



    const [show,setShow] = useState(false)

        return(<Fragment>
            <div>
                <button onClick={()=>setShow(!show)} className={!show ? 'text-white p-1 px-2 rounded-sm text-xs bg-hex-blue' : 'text-xs'}>Pricing</button>
                <button onClick={()=>setShow(!show)} className={show ? 'text-white p-1 px-2 rounded-sm text-xs bg-hex-blue mx-8' :'text-xs mx-8'}>Adding Location</button>
            </div>

            <div className='mt-12'>
                {!show ? (<IntraPricing intraCategory={intraCategory} intraMapping={intraMapping} />) : (<IntraLocation interLocation={interLocation} intraCategory={intraCategory} />)}
            </div>
        </Fragment>)
}



const Edit = () => {
    const dispatch = useDispatch()
    const category = useSelector(state=>state.category)

    let interCategory = category?.category?.data

    function callback(key) {
        dispatch(mappingsActions.getMappings(key))
        dispatch(categoryActions.getCategories(key))
        dispatch(locationActions.getLocations(interCategory[0]?.id))

        
    }
    return (
        <Fragment>
        <div className='flex flex-col'>

         <div className='flex flex-row'>
             <div className='flex flex-col'>
                 <h1 className='text-hex-blue font-bold'>Determine Payment By Category</h1>

                 <p className='text-gray-500 text-xs'>Enter Location on different categories</p>
             </div>

             <div>
                 <img src={`${process.env.REACT_APP_IMAGE}/price+tag.png`} alt='' className='w-12 ml-16' />
             </div>
         </div>

         <div className='mt-2'>
         <Tabs defaultActiveKey="1" onChange={callback}>
             <TabPane tab="Inter State" key="inter">
                  <InterState />
             </TabPane>
             <TabPane tab="Intra State" key="intra">
                 <IntraState />
             </TabPane>
         </Tabs>
         </div>

         </div>
     </Fragment>
    )
}

export default Edit