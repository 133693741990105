import React, { Fragment, useEffect,useState } from 'react'
import { Modal, Table, Tabs } from 'antd';
import { columns } from './tabledata';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { categoryActions } from '../../../../../actions/category.actions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../../../components/Loader';
import axios from 'axios';
import { authHeader } from '../../../../../helpers';
import { BASE_URL } from '../../../../../constants';

const { TabPane } = Tabs;


const InterState = () => {
    const dispatch = useDispatch()
    const [loading,setLoading] = useState(false)
    const category = useSelector(state=>state.category)
    const [activeId, setActiveId] = useState(null)
    const [ID, setID] = useState(null)
    const [row, setRow] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const [isModalVisible2, setIsModalVisible2] = useState(false);

    const showModal2 = () => {
        setIsModalVisible2(true);
    };

    const handleCancel2 = () => {
        setIsModalVisible2(false);
    };


    const handleClick = () => {
        setActiveId(false)
      }
    
      useEffect(() => {
        document.addEventListener('click', handleClick)
        return () => {
          document.removeEventListener('click', handleClick)
        }
      }, [])

      useEffect(() => {
        setList({
         name: row?.Categories ? row?.Categories : '',
        })
     }, [row])

    const [list,setList] = useState({
        name: row?.Categories ? row?.Categories : '',
    })

    
    
    useEffect(() => {
       dispatch(categoryActions.getCategories('inter'))
    }, [dispatch])


    let interCategory = category?.category?.data

    const [query, setQuery] = useState({
        name: '',
        type: 'inter'
      })

    const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
        ...prevState,
        [name]: value
    }));
    };
    
    const editInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setList((prevState) => ({
            ...prevState,
            [name]: value
        }));
        };


    const submit = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const response =  await dispatch(categoryActions.addCategories(query))
        if(response.status === 200) {
            setLoading(false) 
            setQuery((x)=>({
                ...x,
                name: '',
            }))
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }
       dispatch(categoryActions.getCategories('inter'))
     }

     const editList = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const response =  await dispatch(categoryActions.putCategories(row.id,list))
        if(response.status === 200) {
            setLoading(false) 
            handleCancel()
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }
       dispatch(categoryActions.getCategories('inter'))
     }

     const deleteCategory = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const response =  await dispatch(categoryActions.deleteCategories(ID))

        if(response.status === 200) {
            setLoading(false) 
            handleCancel2()
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }
            dispatch(categoryActions.getCategories('inter'))
     }

    const data = interCategory !== '' ?  interCategory?.map((item,i) => ({
        id: item.id,
        key: item?.id,
        SN: ++i,
        Categories: item?.name,
    })) : [] 
    // const data = []

            return (
                <Fragment>
                    <Modal title={'Edit Category'} closable={true} centered={true} onCancel={handleCancel} visible={isModalVisible} footer={null}>

                        <form onSubmit={(e)=>editList(e)}>

                        <div className='flex-1'>
                                <label className='font-bold text-gray-500'>Category</label>
                                <input type={'text'} className='regular-form' name='name' value={list.name} onChange={(e)=>editInput(e)}  />
                        </div>

                            <div>
                                <button type='submit' className='mt-12 w-full bg-hex-blue text-white py-4 rounded-sm font-bold flex items-center justify-center'>
                                    Submit {loading &&  <Loader />}
                                </button>
                                    </div>
                        </form>

                    </Modal>
                    <Modal title={null} closable={true} centered={true} onCancel={handleCancel2} visible={isModalVisible2} footer={null}>
                        <div className='text-center text-md'>Are you sure you want to delete this category type?</div>
                        <div className='mt-8 flex flex-row justify-center items-center'>
                            <button onClick={handleCancel2} className='border-none bg-gray-300 text-white rounded-sm mx-4 p-2 px-4'>Cancel</button>
                            <button onClick={(e)=>deleteCategory(e,ID)} className='border-none bg-hex-blue text-white rounded-sm mx-4 p-2 px-4 flex items-center justify-center'>
                                        Submit {loading &&  <Loader />}</button>
                        </div>
                    </Modal>
                    <form onSubmit={(e)=>submit(e)} className='flex flex-row items-end'>
                        <div className='flex-1'>
                                <label className='font-bold text-gray-500'>Category</label>
                                <input type={'text'} className='register-text-box' name='name' value={query.name} onChange={(e)=>handleInput(e)}  />
                        </div>

                        <div>
                            <button className='w-auto px-4 py-3 bg-hex-blue text-white rounded-md mx-4 flex items-center justify-center'>Save {loading &&  <Loader />}</button>
                        </div>

                    </form>

                    <div className='mt-8'>
                        <Table dataSource={data} pagination={{defaultPageSize: '5'}}  {...{columns:columns({activeId, 
                    setActiveId,
                    handleEdit: async (row) => {
                         await setRow(row)
                         if (row !== null)   showModal()

                      },
                    handleDelete: async (row) => {
                    showModal2()
                    setID(row.id)
                    },
                    })  }}/>
                    </div>
                </Fragment>
            )
}


const IntraState = () => {
    const dispatch= useDispatch()
    const [loading,setLoading] = useState(false)
    const category = useSelector(state=>state.category)
    const [activeId, setActiveId] = useState(null)
    const [ID, setID] = useState(null)
    const [states, setStates] = useState(null)
    const [row, setRow] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        const fetchRoles = async () => {
        const response = await axios.get(`${BASE_URL}/settings/countries/1157/states`,{
            headers:{
                ...authHeader()
            }
        })
        const stateList = await response.data
        setStates(stateList)
        }
        fetchRoles()
}, [])

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const [isModalVisible2, setIsModalVisible2] = useState(false);

    const showModal2 = () => {
        setIsModalVisible2(true);
    };

    const handleCancel2 = () => {
        setIsModalVisible2(false);
    };


    const handleClick = () => {
        setActiveId(false)
      }
    
      useEffect(() => {
        document.addEventListener('click', handleClick)
        return () => {
          document.removeEventListener('click', handleClick)
        }
      }, [])

      useEffect(() => {
        setList({
         state_id: row?.Categories ? row?.Categories : '',
        })
     }, [row])

    const [list,setList] = useState({
        state_id: row?.Categories ? row?.Categories : '',
    })

    
    
    useEffect(() => {
       dispatch(categoryActions.getCategories('intra'))
    }, [dispatch])


    let intraCategory = category?.category?.data

    const [query, setQuery] = useState({
        state_id: '',
        type: 'intra'
      })

    const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
        ...prevState,
        [name]: value
    }));
    };
    
    const editInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setList((prevState) => ({
            ...prevState,
            [name]: value
        }));
        };


    const submit = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const response =  await dispatch(categoryActions.addCategories(query))
        if(response.status === 200) {
            setLoading(false) 
            setQuery((x)=>({
                ...x,
                state_id: '',
            }))
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }
       dispatch(categoryActions.getCategories('intra'))
     }

     const editList = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const response =  await dispatch(categoryActions.putCategories(row.id,list))
        if(response.status === 200) {
            setLoading(false) 
            handleCancel()
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }
       dispatch(categoryActions.getCategories('intra'))
     }

     const deleteCategory = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const response =  await dispatch(categoryActions.deleteCategories(ID))

        if(response.status === 200) {
            setLoading(false) 
            handleCancel2()
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }
            dispatch(categoryActions.getCategories('intra'))
     }

    const data = intraCategory !== '' ?  intraCategory?.map((item,i) => ({
        id: item.id,
        key: item?.id,
        SN: ++i,
        Categories: item?.name,
    })) : [] 
            return (
                <Fragment>
                    <Modal title={'Edit Category Size'} closable={true} centered={true} onCancel={handleCancel} visible={isModalVisible} footer={null}>

                        <form onSubmit={(e)=>editList(e)}>

                        <div className='flex-1'>
                                <label className='font-bold text-gray-500'>State</label>
                                <select className='regular-form' name='state_id' value={list.state_id} onChange={(e)=>editInput(e)}>
                                    <option>*Select State</option>
                                    {states?.map((x)=>(
                                        <option key={x.id} value={x.id}>{x.name}</option>
                                    ))}
                                </select>
                        </div>

                            <div>
                                <button type='submit' className='mt-12 w-full bg-hex-blue text-white py-4 rounded-sm font-bold flex items-center justify-center'>
                                    Submit {loading &&  <Loader />}
                                </button>
                                    </div>
                        </form>

                    </Modal>
                    <Modal title={null} closable={true} centered={true} onCancel={handleCancel2} visible={isModalVisible2} footer={null}>
                        <div className='text-center text-md'>Are you sure you want to delete this category type?</div>
                        <div className='mt-8 flex flex-row justify-center items-center'>
                            <button onClick={handleCancel2} className='border-none bg-gray-300 text-white rounded-sm mx-4 p-2 px-4'>Cancel</button>
                            <button onClick={(e)=>deleteCategory(e,ID)} className='border-none bg-hex-blue text-white rounded-sm mx-4 p-2 px-4 flex items-center justify-center'>
                                        Submit {loading &&  <Loader />}</button>
                        </div>
                    </Modal>
                    <form onSubmit={(e)=>submit(e)} className='flex flex-row items-end'>
                        <div className='flex-1'>
                                <label className='font-bold text-gray-500'>Category</label>
                                <select className='regular-form' name='state_id' value={query.state_id} onChange={(e)=>handleInput(e)}>
                                    <option>*Select State</option>
                                    {states?.map((x)=>(
                                        <option key={x.id} value={x.id}>{x.name}</option>
                                    ))}
                                </select>                        
                        </div>

                        <div>
                            <button className='w-auto px-4 py-3 bg-hex-blue text-white rounded-md mx-4 flex items-center justify-center'>Save {loading &&  <Loader />}</button>
                        </div>

                    </form>

                    <div className='mt-8'>
                        <Table dataSource={data} pagination={{defaultPageSize: '5'}}  {...{columns:columns({activeId, 
                    setActiveId,
                    handleEdit: async (row) => {
                         await setRow(row)
                         if (row !== null)   showModal()

                      },
                    handleDelete: async (row) => {
                    showModal2()
                    setID(row.id)
                    },
                    })  }}/>
                    </div>
                </Fragment>
            )
}

const Create = () => {
    const dispatch = useDispatch()

    function callback(key) {
        dispatch(categoryActions.getCategories(key))
    }
       return (
       <Fragment>
           <div className='flex flex-col'>

            <div className='flex flex-row'>
                <div className='flex flex-col'>
                    <h1 className='text-hex-blue font-bold'>Determine Payment By Category</h1>

                    <p className='text-gray-500 text-xs'>Create Category here</p>
                </div>

                <div>
                    <img src={`${process.env.REACT_APP_IMAGE}/price+tag.png`} alt='' className='w-12 ml-16' />
                </div>
            </div>

            <div className='mt-2'>
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Inter State" key="inter">
                     <InterState />
                </TabPane>
                <TabPane tab="Intra State" key="intra">
                    <IntraState />
                </TabPane>
            </Tabs>
            </div>

            </div>
        </Fragment>
    )
}

export default Create
