import React,{Fragment} from 'react'
import spinner from '../assets/spin.gif'

const Spinner = () => {
    return (
        <Fragment>
        < img
        src={spinner}
        style={{ width: '150px', margin: 'auto', display:'block'}}
        alt='Loading...'
        /> 
    </Fragment> 
    )
}

export default Spinner
