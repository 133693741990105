import { dashboardTypes } from "../types"


export function dashboard (state = {}, action) {
  switch (action.type) {
    case dashboardTypes.GET_DASHBOARD_REQUEST:
      return {
        ...state
      }
    case dashboardTypes.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: action.dashboard
      }
    case dashboardTypes.GET_DASHBOARD_FAILURE:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}
