import React, { Fragment, useState,useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { deliveryActions, deliveryOptionsActions } from '../../../../actions'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../../components/Loader';
import { Modal, Table } from 'antd'
import { columns3 } from '../tabledata'


const PackageSize = () => {
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()
    const [activeId, setActiveId] = useState(null)
    const [ID, setID] = useState(null)
    const delivery = useSelector(state=>state.delivery)
    const deliveryOptions = useSelector(state=>state.deliveryOptions)
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const [isModalVisible2, setIsModalVisible2] = useState(false);

    const showModal2 = () => {
        setIsModalVisible2(true);
    };

    const handleCancel2 = () => {
        setIsModalVisible2(false);
    };


    const handleClick = () => {
        setActiveId(false)
      }
    
      useEffect(() => {
        document.addEventListener('click', handleClick)
        return () => {
          document.removeEventListener('click', handleClick)
        }
      }, [])

    useEffect(() => {
       dispatch(deliveryActions.getPackageSize())
    }, [dispatch])

    let packageSizes = delivery?.delivery?.data?.data
    let deliveryList = deliveryOptions?.deliveryOptions?.data?.data

    useEffect(() => {
        setList({
         weight_unit: deliveryList?.weight_unit ? deliveryList?.weight_unit : '',
         min_weight: deliveryList?.min_weight ? deliveryList?.min_weight : '',
         max_weight: deliveryList?.max_weight ? deliveryList?.max_weight : '',
         status: deliveryList?.status ? deliveryList?.status : '',
         image_url: deliveryList?.image_url ? deliveryList?.image_url : '',
         price_effect: deliveryList?.price_effect ? deliveryList?.price_effect : ''
        })
     }, [deliveryList])

    const [list,setList] = useState({
        weight_unit: deliveryList?.weight_unit ? deliveryList?.weight_unit : '',
        min_weight: deliveryList?.min_weight ? deliveryList?.min_weight : '',
         max_weight: deliveryList?.max_weight ? deliveryList?.max_weight : '',
        status: deliveryList?.status ? deliveryList?.status : '',
        image_url: deliveryList?.image_url ? deliveryList?.image_url : '',
        price_effect: deliveryList?.price_effect ? deliveryList?.price_effect : ''

    })

    const [query, setQuery] = useState({
        weight_unit: packageSizes?.weight_unit,
        min_weight: packageSizes?.min_weight,
        max_weight: packageSizes?.max_weight,
        status: packageSizes?.status,
        image_url: packageSizes?.image,
        price_effect: packageSizes?.price_effect
      })

    const editInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setList((prevState) => ({
        ...prevState,
        [name]: value
    }));
    };

    const edit = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setList((prevState) => ({
            ...prevState,
            [name]: value
        }));
        };

    const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
        ...prevState,
        [name]: value
    }));
    };

    const editFile = async (e) => {
        const file = e.target.files[0]
        const name = e.target.name;
        setList((prevState) => ({
            ...prevState,
            [name]: file
          }));
      }

    const handleFile = async (e) => {
        const file = e.target.files[0]
        const name = e.target.name;
        setQuery((prevState) => ({
            ...prevState,
            [name]: file
          }));
      }

    const handle = (e) => {
        const name = e.target.name;
        const value = parseInt(e.target.value);
        setQuery((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const editList = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const formdata = new FormData();
          Object.entries(list).forEach(([key, value]) => {
          formdata.append(key, value);
            });

        const response =  await dispatch(deliveryActions.updatePackageSize(deliveryList?.id,formdata))
        console.log(response)
        if(response.status === 200) {
            setLoading(false) 
            handleCancel()
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }
        dispatch(deliveryActions.getPackageSize())
     }



    const submit = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const formdata = new FormData();
        Object.entries(query).forEach(([key, value]) => {
        formdata.append(key, value);
          });

        const response =  await dispatch(deliveryActions.addPackageSize(formdata))
        if(response.status_code === 200) {
            setLoading(false) 
            handleCancel()
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }
        dispatch(deliveryActions.getPackageSize())
     }

     const deletePackage = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const response =  await dispatch(deliveryActions.deletePackageSize(ID))
        if(response.status === 200) {
            setLoading(false) 
            handleCancel2()
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }
        dispatch(deliveryActions.getPackageSize())
     }
    const data = packageSizes !== '' ?  packageSizes?.map((item,i) => ({
        id: item.id,
        key: item?.id,
        SN: ++i,
        PackageSize: item?.title,
        Price: item?.price_effect,
        ImageURL:item?.image_url?.slice(0,10) + '...',
        Status: item?.status,
    })) : [] 
    
    
        

    return (
        <>
        <Modal title={null} closable={true} centered={true} onCancel={handleCancel2} visible={isModalVisible2} footer={null}>
            <div className='text-center text-md'>Are you sure you want to delete this package size?</div>
            <div className='mt-8 flex flex-row justify-center items-center'>
                <button onClick={handleCancel2} className='border-none bg-gray-300 text-white rounded-sm mx-4 p-2 px-4'>Cancel</button>
                <button onClick={(e)=>deletePackage(e,ID)} className='border-none bg-hex-blue text-white rounded-sm mx-4 p-2 px-4 flex items-center justify-center'>
                            Submit {loading &&  <Loader />}</button>
            </div>
        </Modal>
        <Modal title={'Edit Package Size'} closable={true} centered={true} onCancel={handleCancel} visible={isModalVisible} footer={null}>

                <form onSubmit={(e)=>editList(e)}>

                    <div>
                        <label className='font-bold text-xs'>Weight Unit</label>
                        <input type={'text'} className='regular-form' name='weight_unit' value={list.weight_unit} onChange={(e)=>editInput(e)} />
                    </div>

                    <div className='mt-8'>
                        <label className='font-bold text-xs'>Minimum Weight</label>
                        <input type={'number'} className='regular-form' name='min_weight' value={list.min_weight} onChange={(e)=>edit(e)} />
                    </div>

                    <div className='mt-8'>
                        <label className='font-bold text-xs'>Maximum Weight</label>
                        <input type={'number'} className='regular-form' name='max_weight' value={list.max_weight} onChange={(e)=>edit(e)} />
                    </div>

                    <div className='mt-8'>
                        <label className='text-xs font-bold'>Price</label>
                        <input type={'text'} className='regular-form' name='price_effect' value={list.price_effect} onChange={(e)=>edit(e)} />
                    </div>

                    <div className='mt-8 flex flex-col'>
                         <label className='text-xs font-bold'>Image</label>
                        <input type={'file'} className='file-btn mt-2' name='image_url' onChange={(e)=>editFile(e)} />
                    </div>

                    <div className='mt-8'>
                        <label className='text-xs font-bold'>Status</label>
                        <select name='status' value={list.status} onChange={(e)=>edit(e)} className='regular-form'>
                            <option value={''}>*Select</option>
                            <option value={'1'}>Active</option>
                            <option value={'0'}>Inactive</option>
                        </select>
                    </div>

                    <div>
                        <button type='submit' className='mt-12 w-full bg-hex-blue text-white py-4 rounded-sm font-bold flex items-center justify-center'>
                            Submit {loading &&  <Loader />}
                        </button>
                    </div>
                </form>

        </Modal>
        <Fragment>
            <div className='flex flex-row'>
                <div className='flex flex-col'>
                    <h1 className='text-hex-blue font-bold'>Determine Payment By Package Size</h1>

                    <p className='text-gray-500 text-xs'>Edit price information here</p>
                </div>

                <div>
                    <img src={`${process.env.REACT_APP_IMAGE}/price+tag.png`} alt='' className='w-12 ml-16' />
                </div>
            </div>

            <form onSubmit={(e)=>submit(e)} className='mt-12' >
                      <div className='grid grid-cols-3 gap-8 items-end'>
                        <div>
                            <label className='font-bold text-gray-500'>Weight Unit</label>
                            <input type={'text'} className='register-text-box' name='weight_unit' value={query.weight_unit} onChange={(e)=>handleInput(e)} />
                        </div>

                        <div>
                            <label className='font-bold text-gray-500'>Minimum Weight</label>
                            <input type={'number'} className='register-text-box' name='min_weight' value={query.min_weight} onChange={(e)=>handle(e)} />
                        </div>

                        <div>
                            <label className='font-bold text-gray-500'>Maximum Weight</label>
                            <input type={'number'} className='register-text-box' name='max_weight' value={query.max_weight} onChange={(e)=>handle(e)} />
                        </div>

                        <div>
                            <label className='font-bold text-gray-500'>Price</label>
                            <div className='w-full px-0 py-0 rounded-sm focus:outline-none bg-light-gray border border-space-gray flex flex-row items-center'>
                                <label className='bg-blue-200 py-3 px-1 text-hex-blue font-bold'>N</label>
                                <input type={'number'} className='px-1 flex-1 bg-transparent focus:outline-none' name='price_effect' value={query.price_effect} onChange={(e)=>handle(e)} />
                            </div>
                        </div>

                        <div>
                            <label className='font-bold text-gray-500'>Image Url</label>
                            <input type={'file'} className='register-text-box' name='image' onChange={(e)=>handleFile(e)} />
                        </div>

                        <div>
                            <label className='font-bold text-gray-500'>Status</label>
                            <select className='register-text-box' name='status' value={query.status} onChange={(e)=>handle(e)} >
                                <option value={''}>* Select Status</option>
                                <option value={'1'}>Active</option>
                                <option value={'0'}>Inactive</option>
                            </select>
                        </div>


                        <div>
                        <button type='submit' className='w-full py-3 bg-hex-blue text-white rounded-md flex flex-row items-center justify-center'>Save Price{loading && <Loader />}</button>
                        </div>
                    </div>
            </form>


            <div className='mt-24'>
                <Table dataSource={data} pagination={{defaultPageSize: '5'}}  {...{columns:columns3({activeId, 
                    setActiveId,
                    handleEdit: async (row) => {
                        const response = await dispatch(deliveryOptionsActions.getSinglePackageSize(row.id))
                        if (response) showModal()
                      },
                    handleDelete: async (row) => {
                    showModal2()
                    setID(row.id)
                    },
                    })  }}/>
            </div>
        </Fragment>
        </>
    )
}

export default PackageSize


