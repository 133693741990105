import React, {useEffect, useState} from 'react';
import {Line} from 'react-chartjs-2'
import { useSelector, useDispatch } from 'react-redux';
// import { chartActions } from '../../../actions';
import { walletActions } from '../../../actions';

const WalletGraph = () =>{

    const dispatch = useDispatch()
    const chart = useSelector(state=>state.wallet);
    const [active, setActive] = useState('today');

      useEffect(() => {
        dispatch(walletActions.getChart(active))
      }, [dispatch,active])
    
      let chartData = chart?.chartInfo
    const LineGraph  = () => {
    const todaylabels = [0, chartData?.success?.['0']?.Date];
    const weeklabels = [0,'Mon','Tues','Wed','Thurs','Fri','Sat','Sun'];
    const yearlabels = [0,'Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    const monthlabels = [0,2,4,6,8,10,12,14,16,18,20,22,24,26,28,30];
    const data = {
    labels: active === 'today' ? todaylabels : active === 'week' ? weeklabels : active === 'month' ? monthlabels : active === 'year' ? yearlabels : '',
    datasets: [
    {
      label: 'Success',
      data: active === 'today' ? [0,parseInt(chartData?.success?.['0']?.total)] : [0,200,800,250,600,400,500,700,300,800,600,1000,400,600,700,300],
      fill: false,
      borderColor: '#6DB637',
      borderWidth: 1.5,
      tension: 0.1
  },
{
    label: 'Pending',
    data: active === 'today' ? [0,parseInt(chartData?.pending?.['0']?.total)] : [0,200,800,250,600,400,500,700,300,800,600,1000,400,600,700,300],
    fill: false,
    borderColor: '#006FC1',
    borderWidth: 1.5,
    tension: 0.1
},
{
    label: 'Failed',
    data: active === 'today' ? [0,parseInt(chartData?.failed?.['0']?.total)] : [0,200,800,250,600,400,500,700,300,800,600,1000,400,600,700,300],
    fill: false,
    borderColor: '#E3000F',
    borderWidth: 1.5,
    tension: 0.1
},
]
    };

    const config = {
    type: 'line',
    data: data,
    maintainAspectRatio:false,
    responsive: true,
    plugins:{
      legend: {
        display: false
      },
    },
    };

 return (
     <Line 
     data={data} height={350} options={config}
     />
 )
    }

    return(
        <div className=''>

        <strong className='text-gray-500'>CHART INFORMATION</strong>
        <div className='w-full h-full bg-white mt-2 p-8'>
          <div className='flex flex-row my-4'>
            <button className={active === 'today' ? 'text-xs border-none flex justify-center p-2 rounded sm mr-8 bg-hex-blue text-white' : 'text-xs border border-gray-200 flex justify-center p-2 rounded sm mr-8'} onClick={()=>setActive('today')}>Today</button>
            <button className={active === 'week' ? 'text-xs border-none flex justify-center p-2 rounded sm mr-8 bg-hex-blue text-white' : 'text-xs border border-gray-200 flex justify-center p-2 rounded sm mr-8'} onClick={()=>setActive('week')}>This Week</button>
            <button className={active === 'month' ? 'text-xs border-none flex justify-center p-2 rounded sm mr-8 bg-hex-blue text-white' : 'text-xs border border-gray-200 flex justify-center items-center p-2 rounded sm mr-8'} onClick={()=>setActive('month')}>This Month 
            {/* <FontAwesomeIcon icon={faChevronDown} size='1x' className='ml-2' /> */}
            </button>
            <button className={active === 'year' ? 'text-xs border-none flex justify-center p-2 rounded sm mr-8 bg-hex-blue text-white' : 'text-xs border border-gray-200 flex justify-center items-center p-2 rounded sm'} onClick={()=>setActive('year')}>2021
            {/* <FontAwesomeIcon icon={faChevronDown} size='1x' className='ml-2' /> */}
            </button>
          </div>
        <div>
          <LineGraph />
        </div>
          
  

    <div className='mt-8 text-xs'>
      {/* <div className='flex flex-row'>
        <p className='h-4 w-8 bg-hex-blue mx-4'></p>
        <label>Delivery</label>
      </div> */}

      <div className='flex flex-row'>
        <p className='h-4 w-8 mx-4' style={{backgroundColor:'#6DB637'}}></p>
        <label>Success</label>
      </div>
      <div className='flex flex-row'>
        <p className='h-4 w-8 mx-4' style={{backgroundColor:'#006FC1'}}></p>
        <label>Pending</label>
      </div>
      <div className='flex flex-row'>
        <p className='h-4 w-8 mx-4' style={{backgroundColor:'#E3000F'}}></p>
        <label>Failed</label>
      </div>
    </div>       

    </div>

  </div>
    )
}
export default WalletGraph