import axios from "axios"
import { config } from '../config'
import { authHeader } from "../helpers"

export const subscriptionService = {
    getSubscription,
    getSingleSubscription,
    getSubscriptionStatus,
    getSubscriptionHistory,
    postSubscription,
    putSubscription
}

async function getSubscription () {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/subscriptions/plans`, requestOptions)
    return response;
}

async function getSingleSubscription (id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/subscriptions/plans/${id}`, requestOptions)
    return response;
}

async function getSubscriptionStatus () {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/subscriptions/status`, requestOptions)
    return response;
}

async function getSubscriptionHistory () {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/subscriptions/history`, requestOptions)
    return response;
}

async function postSubscription (id) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/settings/subscriptions/initiate-payment/${id}`,{} , requestOptions)
    return response;
}

async function putSubscription (id,formdata) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.put(`${config.testUrl}/settings/subscriptions/initiate-payment/${id}`, formdata , requestOptions)
    return response;
}
