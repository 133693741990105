import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import Cards from './Cards'
import Plan from './Plan'

const Subscription = () => {
    return (
        <Fragment>

            <section>
                <Switch>
                    <Route exact path={'/merchant-admin/setting/subscription'} component={Cards} />
                    <Route path={'/merchant-admin/setting/subscription/:id'} component={Plan} />
                </Switch>
            </section>
        </Fragment>
    )
}

export default Subscription
