import React, { Fragment, useState,useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { paymentDistanceActions } from '../../../../actions'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../../components/Loader';

const PaymentDistance = () => {
    const distance = useSelector(state=>state.distance)
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(paymentDistanceActions.getDistancePayment())
    }, [dispatch])

    let distanceDetails = distance?.distance?.data

    useEffect(() => {
        setQuery({
            minimum_distance: distanceDetails?.minimum_distance ? distanceDetails?.minimum_distance : '',
            price: distanceDetails?.price ? distanceDetails?.price : ''
      })
    }, [distanceDetails])

   
    const [query, setQuery] = useState({
        minimum_distance: distanceDetails?.minimum_distance ? distanceDetails?.minimum_distance : '',
        price: distanceDetails?.price ? distanceDetails?.price : ''
      })

    const handle = (e) => {
        const name = e.target.name;
        const value = parseInt(e.target.value);
        setQuery((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const submit = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const response =  await dispatch(paymentDistanceActions.putDistancePayment(query))
        console.log(response)
        if(response.status === 200) {
        setLoading(false) 
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }
        dispatch(paymentDistanceActions.getDistancePayment())
     }
    return (
        <Fragment>
            {distanceDetails !== '' ?
            (<div>

            <div className='flex flex-row'>
                <div className='flex flex-col'>
                    <h1 className='text-hex-blue font-bold'>Determine Payment By Distance</h1>

                    <p className='text-gray-500 text-xs'>Edit price information here</p>
                </div>

                <div>
                    <img src={`${process.env.REACT_APP_IMAGE}/price+tag.png`} alt='' className='w-12 ml-16' />
                </div>
            </div>

            <form onSubmit={(e)=>submit(e)} className='mt-12' >
                      <div className='grid grid-cols-3 gap-8 items-end'>
                        <div>
                            <label className='font-bold text-gray-500'>Min Kilometers</label>
                            <input type={'number'} className='register-text-box' name='minimum_distance' value={query.minimum_distance} onChange={(e)=>handle(e)} />
                        </div>

                        <div>
                            <label className='font-bold text-gray-500'>Price</label>
                            <div className='w-full px-0 py-0 rounded-sm focus:outline-none bg-light-gray border border-space-gray flex flex-row items-center'>
                                <label className='bg-blue-200 py-3 px-1 text-hex-blue font-bold'>N</label>
                                <input type={'number'} className='px-1 flex-1 bg-transparent focus:outline-none' name='price' value={query.price} onChange={(e)=>handle(e)} />
                                <label className='bg-blue-200 py-3 px-1 text-hex-blue font-bold'>/km</label>
                            </div>
                        </div>


                        <div>
                            <button type='submit' className='w-full py-3 bg-hex-blue text-white rounded-md flex flex-row items-center justify-center'>Save Price{loading && <Loader />}</button>
                        </div>
                    </div>
            </form>


            <div className='mt-24 w-4/6 border border-gray-300 text-gray-500 px-4 py-3 text-sm flex flex-row justify-between items-center rounded-md'>
                    <span>New price now cost N{distanceDetails?.price} per km </span>
            </div>
            </div>) : ''}
        </Fragment>
    )
}

export default PaymentDistance
