import axios from "axios"
import { config } from '../config'
import { authHeader } from "../helpers"

export const teamService = {
    getTeamMembers,
    addTeamMember,
    updateTeamMember,
    deleteTeamMember
}

export const teamOptionsService = {
    singleTeamMember
}


async function getTeamMembers (page) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/admins?page=${page}`, requestOptions)
    return response;
}

async function singleTeamMember (id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/admins/${id}`, requestOptions)
    return response;
}

async function addTeamMember (formdata) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/add-user`,formdata ,requestOptions)
    return response;
}

async function updateTeamMember (id,formdata) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/admins/${id}`, formdata ,requestOptions)
    return response;
}

async function deleteTeamMember (id,formdata) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.delete(`${config.testUrl}/admins/${id}`,formdata ,requestOptions)
    return response;
}
