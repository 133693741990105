import React, {useState, } from "react";
import Headers from "../../components/Header";
import { Tabs, Button, DatePicker } from "antd";
import "./style.css";
import { Icon } from "@iconify/react";
import OngoingOrderTable from "./components/OngoingOrderTable"
import CompletedOrderTable from "./components/CompletedOrderTable"
import Emptydata from "./components/EmptyData";
import { completedOrders } from './components/data'
import { useDispatch } from "react-redux";
import { orderActions } from '../../actions'
import moment from 'moment';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

function callback(key) {
  console.log(key);
}

const Orders = (props) => {
  const [oders ] = useState(['ll'])
  const dispatch = useDispatch()

  const onDateChange = (val, date)=>{
    let pageRequest = {
      start_date: date[0],
      end_date: date[1]
    }
    dispatch(orderActions.filterPendingOrders(pageRequest))
  }

  const disabledDate = current => {
    return  current > moment().endOf('day');
  };
  const getLast30Days = () =>{
    let pageRequest = {
      end_date: moment().format('YYYY-MM-DD'),
      start_date: moment().subtract(30, 'days').format('YYYY-MM-DD')
    }
    dispatch(orderActions.filterPendingOrders(pageRequest))
  }


  return (
    <div className="containerWrapper">
      <Headers />

      {/* Table */}
      <div className="container my4 riderTabs">
          <Tabs defaultActiveKey="1" onChange={callback}>
            <TabPane tab={<h3>Pending</h3>} key="1">

              {<OngoingOrderTable /> }
            </TabPane>
            <TabPane tab={<h3>Completed</h3>} key="2">
              {oders.length > 0 ? <CompletedOrderTable data={completedOrders}/>: <Emptydata/> }
            </TabPane>
          </Tabs>
          <div className="absolute abs">
          <RangePicker onChange={onDateChange} disabledDate={disabledDate}/>
          <Button className="btndownload d-md-none" onClick={getLast30Days}>
              <Icon
                className="icons"
                icon="bi:filter-right"
              />
             <small className="filterSpan">Filtered By Orders From Last 30 Days</small>
            </Button>
            <Button className="btndownload d-md-none">
              <Icon
                className="icons"
                icon="ant-design:cloud-download-outlined"
                color="#006FC1"
              />
              <small className="filterSpan">Download</small>
            </Button>
            

          </div>
          <div className="absolute abs">
            <Button className="btndownload">
              <Icon
                className="icons"
                icon="ant-design:cloud-download-outlined"
                color="#006FC1"
              />
              Download
            </Button>
          </div>
      </div>
      </div>
  )
}

export default Orders
