import React from 'react'
import {Route, Redirect } from 'react-router-dom';


function PrivateRoute  ({component: Component, ...rest}) {
    const auth = sessionStorage.getItem('isAuthenticated')
    return (
        <Route
      {...rest}
      render={
        ({ location,props }) => (
          auth
            ? (
                <Component {...props} />
            ) : (

              <Redirect
                to={{
                  pathname: '/merchant-admin/login',
                  state: { from: location }
                }}
              />
              ))
      }
    />
  );
}


export default PrivateRoute
