import React from 'react';

const Emptybike = () => {
    return (
        <div className="flex center empty">
            <div>
                <p>No Data Available</p>
            </div>
        </div>
    );
}

export default Emptybike;
