import React from 'react';
import { Table } from 'antd';
import DropDown from './DropDown'
import MobileCardBike from './MobileCardBike';
import EmptyData from './EmptyData';
import { riderActions } from '../../../actions'
import { useDispatch, useSelector } from "react-redux";
import Utils from '../../../helpers/utils';


const BikesTable = () => {
  const dispatch = useDispatch()
  const rider = useSelector(state=>state.rider)
  const riderList = rider?.riderList 

const handleTableChange = (pagination) =>{
  dispatch(riderActions.getRider(pagination))
}
const pagination =  riderList?.pagination
console.log(pagination);
    const columns = [
      {
        title: 'S/N',
        key: 'id',
        render:(item, record, index)=>{
          return <p>{Utils.getSerialNumber(pagination.current, index, pagination.pageSize)}</p>
        }
      },
      // {
      //   title: 'Bike Name',
      //   dataIndex: 'name',
      //   key: 'name',
      //   render:(name)=>{
      //     return <p>{name || "-"}</p>
      //   }
      // },
      {
        title: 'Plate No',
        dataIndex: 'plate_no',
        key: 'plate_no',
        render:(plate_no)=>{
          return <p>{plate_no.toUpperCase() || "-"}</p>
        }
      },
      // {
      //   title: 'Status',
      //   key: 'status',
      //   dataIndex: 'status',
      //   render: status => {
      //       let color = status === "Functional" ? '#7AAC52' : status === "Faulty" ? '#FFB000' :  '#E3000F';
      //        return(
      //           <Tag color={color} className="bikeTags">
      //           {status}
      //         </Tag>
      //        )
      //   },
      // },
      {
        title: 'Rider Name',
        dataIndex: 'user',
        key: 'user',
        render: (user) => {
          return( <p>{user.name || "-"}</p>)
        },
      },
      {
        title: 'Bike Location',
        dataIndex: 'user',
        key: 'user',
        render: (user) => {
          return( <p>{user.address || "-"}</p>)
        },
      },
      {
        title: 'Action',
        key: 'row',
        render: (row) => {
          return( <DropDown data={row} type="bike"/>)
        },
      },
    ];
    
      
    return (
      <>
      {
  
      riderList?.data?.length > 0 ?
      <>
      <div className="d-sm-none">
        <Table dataSource={riderList?.data} columns={columns} className="userTable" pagination={ riderList?.pagination} onChange={handleTableChange}/>
        </div>
        <div className="d-lg-none">
        <MobileCardBike bikes={riderList}/>
        </div>
        </>
        : 
        <EmptyData/>
      }
      </>
    );
}
    export default BikesTable;
