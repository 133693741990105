import React, { useState } from "react";
import { Button, Modal } from "antd";
import { Icon } from "@iconify/react";
import Utils from "../../../helpers/utils";

const OrderDetailsModal = ({ data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="text" onClick={showModal} className="flex align-center">
        <Icon icon="ant-design:eye-outlined" className="icons" />
        View
      </Button>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <h2>Order Details</h2>
        <p className="greyText">
          Please view all the details in the transactions below carefully and
          confirm all inputs
        </p>
        <div className="walletInfo">
          <div className="flex space-btw left bord">
            <p>Sender Name: </p>
            <p>{data.user.full_name}</p>
          </div>
          <div className="flex space-btw left bord">
            <p>Sender Phone Number: </p>
            <p>{data.user.phone}</p>
          </div>
          <div className="flex space-btw left bord">
            <p>Sender Address: </p>
            <p>{data.user.address || "-"}</p>
          </div>
          <div className="flex space-btw left bord">
            <p>Transaction ID: </p>
            <p>{data.transaction_id}</p>
          </div>
          <div className="flex space-btw left bord">
            <p>Description: </p>
            <p>{data.description}</p>
          </div>
          <div className="flex space-btw left bord">
            <p>Amount: </p>
            <p>{Utils.currencyFormater(data.amount)}</p>
          </div>
          <div className="flex space-btw left bord">
            <p>Date Sent: </p>
            <p>{data.date}</p>
          </div>
        </div>
        <Button className="modalBtn" onClick={handleCancel}>
          Close
        </Button>
      </Modal>
    </>
  );
};

export default OrderDetailsModal;
