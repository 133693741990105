export const subscriptionTypes = {
   
    GET_SUBSCRIPTION_REQUEST: 'GET_SUBSCRIPTION_REQUEST',
    GET_SUBSCRIPTION_SUCCESS: 'GET_SUBSCRIPTION_SUCCESS',
    GET_SUBSCRIPTION_FAILURE: 'GET_SUBSCRIPTION_FAILURE',

    GET_SINGLE_SUBSCRIPTION_REQUEST: 'GET_SINGLE_SUBSCRIPTION_REQUEST',
    GET_SINGLE_SUBSCRIPTION_SUCCESS: 'GET_SINGLE_SUBSCRIPTION_SUCCESS',
    GET_SINGLE_SUBSCRIPTION_FAILURE: 'GET_SINGLE_SUBSCRIPTION_FAILURE',

    GET_SUBSCRIPTION_STATUS_REQUEST: 'GET_SUBSCRIPTION_STATUS_REQUEST',
    GET_SUBSCRIPTION_STATUS_SUCCESS: 'GET_SUBSCRIPTION_STATUS_SUCCESS',
    GET_SUBSCRIPTION_STATUS_FAILURE: 'GET_SUBSCRIPTION_STATUS_FAILURE',

    PUT_SUBSCRIPTION_REQUEST: 'PUT_SUBSCRIPTION_REQUEST',
    PUT_SUBSCRIPTION_SUCCESS: 'PUT_SUBSCRIPTION_SUCCESS',
    PUT_SUBSCRIPTION_FAILURE: 'PUT_SUBSCRIPTION_FAILURE',
  
}

export const subscriptionPayTypes = {

    POST_SUBSCRIPTION_REQUEST: 'POST_SUBSCRIPTION_REQUEST',
    POST_SUBSCRIPTION_SUCCESS: 'POST_SUBSCRIPTION_SUCCESS',
    POST_SUBSCRIPTION_FAILURE: 'POST_SUBSCRIPTION_FAILURE',
}

export const subscriptionHistoryTypes = {

    GET_SUBSCRIPTION_HISTORY_REQUEST: 'GET_SUBSCRIPTION_HISTORY_REQUEST',
    GET_SUBSCRIPTION_HISTORY_SUCCESS: 'GET_SUBSCRIPTION_HISTORY_SUCCESS',
    GET_SUBSCRIPTION_HISTORY_FAILURE: 'GET_SUBSCRIPTION_HISTORY_FAILURE',

}