export const categoryTypes = {
   
    GET_CATEGORIES_REQUEST: 'GET_CATEGORIES_REQUEST',
    GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAILURE: 'GET_CATEGORIES_FAILURE',

    ADD_CATEGORIES_REQUEST: 'ADD_CATEGORIES_REQUEST',
    ADD_CATEGORIES_SUCCESS: 'ADD_CATEGORIES_SUCCESS',
    ADD_CATEGORIES_FAILURE: 'ADD_CATEGORIES_FAILURE',

    UPDATE_CATEGORIES_REQUEST: 'UPDATE_CATEGORIES_REQUEST',
    UPDATE_CATEGORIES_SUCCESS: 'UPDATE_CATEGORIES_SUCCESS',
    UPDATE_CATEGORIES_FAILURE: 'UPDATE_CATEGORIES_FAILURE',

    DELETE_CATEGORIES_REQUEST: 'DELETE_CATEGORIES_REQUEST',
    DELETE_CATEGORIES_SUCCESS: 'DELETE_CATEGORIES_SUCCESS',
    DELETE_CATEGORIES_FAILURE: 'DELETE_CATEGORIES_FAILURE',

}