import { dashboardTypes } from "../types"
import { dashboardService } from "../services"

export const dashboardActions = {
    getDashboard
}

function getDashboard () {
    return async dispatch => {
      dispatch(request())
  
      try {
        const dashboard = await dashboardService.getDashboard()
        dispatch(success(dashboard))
        return dashboard

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request () { return { type: dashboardTypes.GET_DASHBOARD_REQUEST } }
    function success (dashboard) { return { type: dashboardTypes.GET_DASHBOARD_SUCCESS, dashboard } }
    function failure (error) { return { type: dashboardTypes.GET_DASHBOARD_FAILURE, error } }
    
  }
