import axios from "axios"
import { config } from '../config'
import { authHeader } from "../helpers"

export const interStateDeliveryService = {
    getInterStateDelivery,
    putInterStateDelivery
}

export const intraStateDeliveryService = {
    getIntraStateDelivery,
    putIntraStateDelivery
}

export const paymentDistanceService = {
    getDistancePayment,
    putDistancePayment
}

export const deliveryService = {
    getPackageSize,
    getSinglePackageSize,
    addPackageSize,
    updatePackageSize,
    deletePackageSize

}

export const rideTypeService = {
    getRideType,
    getSingleRideType,
    addRideType,
    updateRideType,
    deleteRideType
}
export const deliveryTimeService = {
    getDeliveryTime,
    getSingleDeliveryTime,
    addDeliveryTime,
    updateDeliveryTime,
    deleteDeliveryTime
}

export const summaryService = {
    getDeliverySummary
}

async function getDeliverySummary () {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/delivery-summary`, requestOptions)
    return response;
}

async function getInterStateDelivery () {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/delivery-inter-state`, requestOptions)
    return response;
}

async function putInterStateDelivery (value) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.put(`${config.testUrl}/settings/delivery-inter-state`, {value} , requestOptions)
    return response;
}


async function getIntraStateDelivery () {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/delivery-intra-state`, requestOptions)
    return response;
}

async function putIntraStateDelivery (value) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.put(`${config.testUrl}/settings/delivery-intra-state`, {value} , requestOptions)
    return response;
}

async function getDistancePayment () {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/payment-by-distance`, requestOptions)
    return response;
}

async function putDistancePayment (value) {

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.put(`${config.testUrl}/settings/payment-by-distance`, value , requestOptions)
    return response;
}

async function getPackageSize () {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/package-sizes`, requestOptions)
    return response;
}

async function getSinglePackageSize (id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/package-sizes/${id}`, requestOptions)
    return response;
}

async function deletePackageSize (id) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.delete(`${config.testUrl}/settings/package-sizes/${id}`, requestOptions)
    return response;
}

async function addPackageSize(formdata) {

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/settings/package-sizes`, formdata , requestOptions)
    return response;
}

async function updatePackageSize(id,formdata) {

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/settings/package-sizes/${id}`, formdata , requestOptions)
    return response;
}

async function getRideType () {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/delivery-types`, requestOptions)
    return response;
}

async function getSingleRideType (id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/delivery-types/${id}`, requestOptions)
    return response;
}

async function deleteRideType(id) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.delete(`${config.testUrl}/settings/delivery-types/${id}`, requestOptions)
    return response;
}

async function addRideType(formdata) {

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/settings/delivery-types`, formdata , requestOptions)
    return response;
}

async function updateRideType(id,formdata) {

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/settings/delivery-types/${id}`, formdata , requestOptions)
    return response;
}

async function getDeliveryTime () {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/delivery-times`, requestOptions)
    return response;
}

async function getSingleDeliveryTime (id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/delivery-times/${id}`, requestOptions)
    return response;
}

async function deleteDeliveryTime(id) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.delete(`${config.testUrl}/settings/delivery-times/${id}`, requestOptions)
    return response;
}

async function addDeliveryTime(formdata) {

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/settings/delivery-times`, formdata , requestOptions)
    return response;
}

async function updateDeliveryTime(id,formdata) {

    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.put(`${config.testUrl}/settings/delivery-times/${id}`, formdata , requestOptions)
    return response;
}