import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Table } from 'antd'
import React, {Fragment, useEffect,useState} from 'react'
import { columns } from './tabledata'
import { useDispatch,useSelector } from 'react-redux'
import { teamActions, teamOptionsActions } from '../../../actions'
import Loader from '../../../components/Loader'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import { authHeader } from '../../../helpers'
import Spinner from '../../../components/Spinner'
import { BASE_URL } from '../../../constants'

const Team = () => {
    const dispatch = useDispatch()
    const [loading,setLoading] = useState(false)
    const team = useSelector(state=>state.team)
    const teamOptions = useSelector(state=>state.teamOptions)
    const [activeId, setActiveId] = useState(null)
    const [roles,setRoles] = useState(null)
    const [ID,setID] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        const fetchRoles = async () => {
        const response = await axios.get(`${BASE_URL}/roles`,{
            headers:{
                ...authHeader()
            }
        })
        const roleList = await response.data.data
        setRoles(roleList)
        }
        fetchRoles()
}, [])

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const [isModalVisible2, setIsModalVisible2] = useState(false);

    const showModal2 = () => {
        setIsModalVisible2(true);
    };

    const handleCancel2 = () => {
        setIsModalVisible2(false);
    };

    const [isModalVisible3, setIsModalVisible3] = useState(false);

    const showModal3 = () => {
        setIsModalVisible3(true);
    };

    const handleCancel3 = () => {
        setIsModalVisible3(false);

        setQuery({
           full_name: '',
           email: '',
           address:  '',
           phone_no:  '',
           role_id: '' 
        })

        setError({
            phone_no: ''
        })
    };

    const [isModalVisible4, setIsModalVisible4] = useState(false);

    const showModal4 = () => {
        setIsModalVisible4(true);
    };

    const handleCancel4 = () => {
        setIsModalVisible4(false);
    };

    const handleClick = () => {
        setActiveId(false)
      }
    
      useEffect(() => {
        document.addEventListener('click', handleClick)
        return () => {
          document.removeEventListener('click', handleClick)
        }
      }, [])
    
      const [current, setCurrent] = useState(1)


    useEffect(() => {
       dispatch(teamActions.getTeamMembers(1))
    }, [dispatch])

    const changePage = async (page) => {
        setCurrent(page)
       await dispatch(teamActions.getTeamMembers(page))

      
      }

      const [error,setError] = useState({
        phone_no: ''
      })

    let TeamMembers = team?.team?.data?.data
    let TeamRoute = team?.team?.data?.pagination
    let TeamDetails = teamOptions?.teamOptions?.data?.data

    useEffect(() => {
        setList({
         full_name: TeamDetails?.full_name ? TeamDetails?.full_name : '',
         role_name: TeamDetails?.role?.name ? TeamDetails?.role?.name : '',
         email: TeamDetails?.email ? TeamDetails?.email : '',
         status: TeamDetails?.status ? TeamDetails?.status : '',
         address: TeamDetails?.address ? TeamDetails?.address : '',
         role_id: TeamDetails?.role?.id ? TeamDetails?.role?.id : ''
        })
     }, [TeamDetails])

    const [list,setList] = useState({
        full_name: TeamDetails?.full_name ? TeamDetails?.full_name : '',
        role_name: TeamDetails?.role?.name ? TeamDetails?.role?.name : '',
         email: TeamDetails?.email ? TeamDetails?.email : '',
        status: TeamDetails?.status ? TeamDetails?.status : '',
        address: TeamDetails?.address ? TeamDetails?.address : '',
        phone_no: TeamDetails?.phone_no ? TeamDetails?.phone_no : '',
        role_id: TeamDetails?.role?.id ? TeamDetails?.role?.id : ''
    })

    const [query,setQuery] = useState({
        full_name: '',
         email: '',
        address:  '',
        phone_no:  '',
        role_id: ''
    })

         const editInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setList((prevState) => ({
            ...prevState,
            [name]: value
        }));
        };

        const edit = (e) => {
        const name = e.target.name;
        const value = parseInt(e.target.value);
        setList((prevState) => ({
            ...prevState,
            [name]: value
        }));
        };

        const handleInput = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            setQuery((prevState) => ({
                ...prevState,
                [name]: value
            }));
            };
    
            const handle = (e) => {
            const name = e.target.name;
            const value = parseInt(e.target.value);
            setQuery((prevState) => ({
                ...prevState,
                [name]: value
            }));
            };

            const createForm = async (e) => {
                e.preventDefault();
                setLoading(true) 
        
                const formdata = new FormData();
                  Object.entries(query).forEach(([key, value]) => {
                  formdata.append(key, value);
                    });

                const response =  await dispatch(teamActions.addTeamMember(formdata))
                console.log(response?.data?.data?.phone_no)
                if(response.data.status === true) {
                    setLoading(false) 
                    // setHandle
                    handleCancel3()
                    toast.success(response?.data?.message,{
                        autoClose: 2000,
                        hideProgressBar: true})
                    }
                else {
                    toast.error(response?.data?.message,{
                      autoClose: 2000,
                      hideProgressBar: true })
                      setLoading(false) 

                      setError(x=>({
                        ...x,
                        phone_no: response?.data?.data?.phone_no
                    })) 

                    }
                dispatch(teamActions.getTeamMembers())
             }

        const editForm = async (e) => {
            e.preventDefault();
            setLoading(true) 
    
            const formdata = new FormData();
              Object.entries(list).forEach(([key, value]) => {
              formdata.append(key, value);
                });
    
            const response =  await dispatch(teamActions.updateTeamMember(TeamDetails?.id,formdata))
            if(response.data.status === true) {
                setLoading(false) 
                handleCancel2()
              toast.success(response?.data?.message,{
                autoClose: 2000,
                hideProgressBar: true})
            }
            else {
                toast.error(response?.data?.message,{
                  autoClose: 2000,
                  hideProgressBar: true })
                  setLoading(false) 
                }
            dispatch(teamActions.getTeamMembers())
         }

         const deleteMember = async (e) => {
            e.preventDefault();
            setLoading(true) 
    
            const response =  await dispatch(teamActions.deleteTeamMember(ID))
            if(response.status === 200) {
                setLoading(false) 
                handleCancel4()
              toast.success(response?.data?.message,{
                autoClose: 2000,
                hideProgressBar: true})
            }
            else {
                toast.error(response?.data?.message,{
                  autoClose: 2000,
                  hideProgressBar: true })
                  setLoading(false) 
                }
            dispatch(teamActions.getTeamMembers())
         }

    const data = TeamMembers !== '' ?  TeamMembers?.map((item,i) => ({
        id: item.id,
        key: item?.id,
        SN: ++i,
        Name: item?.full_name !== null ? item?.full_name : '-',
        Role: item?.role?.name,
        EmailAddress:item?.email,
        Status: item?.status,
    })) : [] 
    return (
        <>
         <Modal title={null} closable={true} centered={true} onCancel={handleCancel} visible={isModalVisible} footer={null}>

                <h1 className='font-bold text-lg'>View Team Member</h1> 
                <p className='text-gray-500'>Please view all the informations in the details below</p> 

                <div className='mt-8 w-full flex flex-row justify-between'>
                    <div>Staff Name</div>
                    <div>{TeamDetails?.full_name ? TeamDetails?.full_name : '-'}</div>
                </div>      

                <hr className='mt-2 text-gray-50'></hr>

                <div className='mt-2 w-full flex flex-row justify-between'>
                    <div>Staff Role</div>
                    <div>{TeamDetails?.role_name ? TeamDetails?.role_name : '-'}</div>
                </div> 

                <hr className='mt-2 text-gray-50'></hr>

                <div className='mt-2 w-full flex flex-row justify-between'>
                    <div>Email Address</div>
                    <div>{TeamDetails?.email ? TeamDetails?.email : '-'}</div>
                </div> 

                <hr className='mt-2 text-gray-50'></hr>

                <div className='mt-2 w-full flex flex-row justify-between'>
                    <div>Status</div>
                    <div className='capitalize'>{TeamDetails?.status}</div>
                </div> 

                <hr className='mt-2 text-gray-50'></hr>

                <div className='mt-2 w-full flex flex-row justify-between'>
                    <div>Phone Number</div>
                    <div>{TeamDetails?.phone_no ? TeamDetails?.phone_no : '-'}</div>
                </div> 

                <hr className='mt-2 text-gray-50'></hr>

                <div className='mt-2 w-full flex flex-row justify-between'>
                    <div>Staff Address</div>
                    <div>{TeamDetails?.address ? TeamDetails?.address : '-'}</div>
                </div> 

                <div>
                    <button className='p-3 bg-hex-blue text-white border-none px-6 mt-12 rounded-sm' onClick={handleCancel}>Close</button>
                </div>
                
        </Modal>
        <Modal title={null} closable={true} centered={true} onCancel={handleCancel2} visible={isModalVisible2} footer={null}>

            <h1 className='font-bold text-lg'>Edit Team Member Information</h1> 

            <form onSubmit={(e)=>editForm(e)}>
                    <div>
                        <label className='font-bold text-xs'>Staff Name</label>
                        <input type={'text'} className='regular-form' name='full_name' value={list.full_name} onChange={(e)=>editInput(e)} />
                    </div>

                    <div className='mt-8'>
                        <label className='font-bold text-xs'>Staff Role</label>
                        <select name='role_id' value={list.role_id} onChange={(e)=>editInput(e)} className='regular-form'>
                                     <option>* Select Role</option>
                                {roles?.length > 0 ? roles?.map((x)=>(
                                    <option key={x.id} value={x.id}>{x.name}</option>
                                )) : ''}
                        </select>
                    </div>

                    <div className='mt-8'>
                        <label className='font-bold text-xs'>Email Address</label>
                        <input type={'text'} className='regular-form' name='email' value={list.email} onChange={(e)=>editInput(e)} />
                    </div>

                    <div className='mt-8'>
                        <label className='text-xs font-bold'>Status</label>
                        <select name='status' value={list.status} onChange={(e)=>editInput(e)} className='regular-form'>
                            <option value={''}>*Select</option>
                            <option value={'1'}>Active</option>
                            <option value={'0'}>Inactive</option>
                        </select>
                    </div>

                    <div className='mt-8'>
                        <label className='text-xs font-bold'>Phone Number</label>
                        <input type={'number'} className='regular-form' name='phone_no' value={list.phone_no} onChange={(e)=>edit(e)} />
                    </div>

                    <div className='mt-8'>
                        <label className='font-bold text-xs'>Address</label>
                        <input type={'text'} className='regular-form' name='address' value={list.address} onChange={(e)=>editInput(e)} />
                    </div>

                    <input type={'hidden'} name='role_id' value={list?.role_id} />

                    <div>
                        <button type='submit' className='mt-12 w-full bg-hex-blue text-white py-4 rounded-sm font-bold flex items-center justify-center'>
                            Submit {loading &&  <Loader />}
                        </button>
                    </div>
                </form>


        </Modal>
        <Modal title={null} closable={true} centered={true} onCancel={handleCancel3} visible={isModalVisible3} footer={null}>

        <h1 className='font-bold text-lg'>Create Team Member Information</h1> 

        <form onSubmit={(e)=>createForm(e)}>
            <div>
                <label className='font-bold text-xs'>Staff Name</label>
                <input type={'text'} className='regular-form' name='full_name' value={query.full_name} onChange={(e)=>handleInput(e)} />
        </div>

        <div className='mt-8'>
            <label className='font-bold text-xs'>Email Address</label>
            <input type={'text'} className='regular-form' name='email' value={query.email} onChange={(e)=>handleInput(e)} />
        </div>

        <div className='mt-8'>
                        <label className='font-bold text-xs'>Staff Role</label>
                        <select name='role_id' value={query.role_id} onChange={(e)=>handleInput(e)} className='regular-form'>
                                     <option>* Select Role</option>
                                {roles?.length > 0 ? roles?.map((x)=>(
                                    <option key={x.id} value={x.id}>{x.name}</option>
                                )) : ''}
                        </select>
                    </div>

    
        <div className='mt-8'>
            <label className='text-xs font-bold'>Phone Number (234xxxxxxxxxx)</label>
            <input type={'number'} className='regular-form' name='phone_no' value={query.phone_no} onChange={(e)=>handle(e)} />
        </div>
        <div className='text-red-500 float-left text-xs mt-2'>{error?.phone_no}</div>

        <div className='mt-8'>
            <label className='font-bold text-xs'>Address</label>
            <input type={'text'} className='regular-form' name='address' value={query.address} onChange={(e)=>handleInput(e)} />
        </div>

        <div>
            <button type='submit' className='mt-12 w-full bg-hex-blue text-white py-4 rounded-sm font-bold flex items-center justify-center'>
                Submit {loading &&  <Loader />}
            </button>
        </div>
        </form>


        </Modal>
        <Modal title={null} closable={true} centered={true} onCancel={handleCancel4} visible={isModalVisible4} footer={null}>
            <div className='text-center text-md'>Are you sure you want to delete this Member?</div>
            <div className='mt-8 flex flex-row justify-center items-center'>
                <button onClick={handleCancel4} className='border-none bg-gray-300 text-white rounded-sm mx-4 p-2 px-4'>Cancel</button>
                <button onClick={(e)=>deleteMember(e,ID)} className='border-none bg-hex-blue text-white rounded-sm mx-4 p-2 px-4 flex items-center justify-center'>
                            Submit {loading &&  <Loader />}</button>
            </div>
        </Modal>
        <Fragment>
        {TeamMembers?.length > 0 ? (<div className='text-black'>
            <div className='w-full flex flex-row justify-between items-center'>
                <h1 className='font-bold text-hex-blue'>Team</h1>
                <button>
                    <FontAwesomeIcon onClick={()=>showModal3()} icon={faPlusCircle} size='2x' color='#006fc1' />
                </button>
            </div>

            <div className='mt-8'>
            <Table 
                  dataSource={data}
                //   pagination={{ current={current} defaultCurrent={clientRoute?.current_page} total={clientRoute?.total} onChange={changePage}}}
                  pagination={{ current: current,total: TeamRoute?.total,defaultPageSize:TeamRoute?.to,onChange:changePage }}
                  {...{
                    columns:columns({
                        activeId, 
                        setActiveId,
                        handleView: async (row) => {
                            const response = await dispatch(teamOptionsActions.singleTeamMember(row.id))
                            if (response) showModal()
                        },
                        handleEdit: async (row) => {
                            const response = await dispatch(teamOptionsActions.singleTeamMember(row.id))
                            if (response) showModal2()
                        },
                        handleDelete: async (row) => {
                            showModal4()
                            setID(row.id)
                        },
                    })    
                  }}
                  />
            </div>
        </div>) : (
            <Spinner />
        )}
        </Fragment>
        </>
    )
}

export default Team
