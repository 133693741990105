import React, { Fragment, useEffect,useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { settingsActions } from '../../../actions'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../components/Loader';
import Spinner from '../../../components/Spinner';

const ManageRiders = () => {
    const dispatch = useDispatch()
    const settings = useSelector(state=>state.settings)

    useEffect(() => {
       dispatch(settingsActions.getRiderSettings())
    }, [dispatch])

    let riderSettings = settings?.settings?.data?.data


    useEffect(() => {
        setQuery({maximum_request_per_day: riderSettings?.['2']?.value ? riderSettings?.['2']?.value : '',
        maximum_rider_age_limit: riderSettings?.['1']?.value ? riderSettings?.['1']?.value : '',
        minimum_rider_age_limit: riderSettings?.['0']?.value ? riderSettings?.['0']?.value : '',
        rider_start_time: riderSettings?.['3']?.value ? riderSettings?.['3']?.value : '',
        rider_end_time: riderSettings?.['4']?.value ? riderSettings?.['4']?.value : ''})
    }, [riderSettings])


    const [loading,setLoading] = useState(false)
    const [query, setQuery] = useState({
        maximum_request_per_day: riderSettings?.['2']?.value ? riderSettings?.['2']?.value : '',
        maximum_rider_age_limit: riderSettings?.['1']?.value ? riderSettings?.['1']?.value : '',
        minimum_rider_age_limit: riderSettings?.['0']?.value ? riderSettings?.['0']?.value : '',
        rider_start_time: riderSettings?.['3']?.value ? riderSettings?.['3']?.value : '',
        rider_end_time: riderSettings?.['4']?.value ? riderSettings?.['4']?.value : ''
      })

    const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
        ...prevState,
        [name]: value
    }));
    };

    const handle = (e) => {
        const name = e.target.name;
        const value = parseInt(e.target.value);
        setQuery((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }



    const submit = async (e) => {
        e.preventDefault();
        setLoading(true) 

        console.log(query)
        
        const response =  await dispatch(settingsActions.updateRiderSettings(query))
        if(response.data.status === true) {
            setLoading(false) 
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }
        dispatch(settingsActions.getRiderSettings())
     }
    return (
        <Fragment>
                  {riderSettings && riderSettings?.['0']?.value !== '' ?
                  (<form onSubmit={(e)=>submit(e)} >
                      <div className='grid grid-cols-3 gap-8'>
                        <div>
                            <label className='font-bold'>Min Riders Age Limit </label>
                            <input type={'number'} className='register-text-box' name='minimum_rider_age_limit' value={query.minimum_rider_age_limit} onChange={(e)=>handle(e)} />
                        </div>

                        <div>
                            <label className='font-bold'>Max Riders Age Limit</label>
                            <input type={'number'} className='register-text-box' name='maximum_rider_age_limit' value={query.maximum_rider_age_limit} onChange={(e)=>handle(e)} />
                        </div>

                        <div></div>

                        <div>
                            <label className='font-bold'>Start Time (H:M am/pm)</label>
                            <input type={'text'} className='register-text-box' name='rider_start_time' value={query.rider_start_time} onChange={(e)=>handleInput(e)}/>
                        </div>

                        <div>
                            <label className='font-bold'>End Time (H:M am/pm)</label>
                            <input type={'text'} className='register-text-box' name='rider_end_time' value={query.rider_end_time} onChange={(e)=>handleInput(e)} />
                        </div>

                        <div></div>

                        <div>
                            <label className='font-bold'>Maximum Request Per Day</label>
                            <input type={'number'} className='register-text-box' name='maximum_request_per_day' value={query.maximum_request_per_day} onChange={(e)=>handle(e)} />
                        </div>
                        </div>

                        <button type='submit' className='w-auto py-4 px-8 mt-8 bg-hex-blue focus:outline-none border-none flex flex-row items-center justify-between text-white rounded-md text-sm'>Save Settings {loading && <Loader />}</button>


            </form>) : (
                <Spinner />
            )}
        </Fragment>
    )
}

export default ManageRiders
