import { DeliveryTypes, RideTypes, RideOptionsTypes } from "../types"
import { DeliveryOptionsTypes, DeliverySummaryTypes, DeliveryTimeOptionsTypes, DeliveryTimeTypes } from "../types/delivery.types"



export function deliveryOptions (state = {}, action) {
    switch (action.type) {
      case DeliveryOptionsTypes.GET_SINGLE_PACKAGE_REQUEST:
      return {
          ...state
      }
      case DeliveryOptionsTypes.GET_SINGLE_PACKAGE_SUCCESS:
      return {
          ...state,
          deliveryOptions: action.deliveryOptions
      }
      case DeliveryOptionsTypes.GET_SINGLE_PACKAGE_FAILURE:
      return {
          ...state,
          error: action.error
      }
      default:
        return state
    }
  }





  export function summary (state = {}, action) {
    switch (action.type) {
      case DeliverySummaryTypes.GET_DELIVERY_SUMMARY_REQUEST:
      return {
          ...state
      }
      case DeliverySummaryTypes.GET_DELIVERY_SUMMARY_SUCCESS:
      return {
          ...state,
          summary: action.summary
      }
      case DeliverySummaryTypes.GET_DELIVERY_SUMMARY_FAILURE:
      return {
          ...state,
          error: action.error
      }
      default:
        return state
    }
  }


export function delivery (state = {}, action) {
  switch (action.type) {
    case DeliveryTypes.GET_PACKAGE_SIZE_REQUEST:
      return {
        ...state
      }
    case DeliveryTypes.GET_PACKAGE_SIZE_SUCCESS:
      return {
        ...state,
        delivery: action.delivery
      }
    case DeliveryTypes.GET_PACKAGE_SIZE_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case DeliveryTypes.ADD_PACKAGE_SIZE_REQUEST:
    return {
        ...state
    }
    case DeliveryTypes.ADD_PACKAGE_SIZE_SUCCESS:
    return {
        ...state,
    }
    case DeliveryTypes.ADD_PACKAGE_SIZE_FAILURE:
    return {
        ...state,
        error: action.error
    }
    case DeliveryTypes.DELETE_PACKAGE_SIZE_REQUEST:
    return {
        ...state
    }
    case DeliveryTypes.DELETE_PACKAGE_SIZE_SUCCESS:
    return {
        ...state,
    }
    case DeliveryTypes.DELETE_PACKAGE_SIZE_FAILURE:
    return {
        ...state,
        error: action.error
    }
    case DeliveryTypes.UPDATE_PACKAGE_SIZE_REQUEST:
    return {
        ...state
    }
    case DeliveryTypes.UPDATE_PACKAGE_SIZE_SUCCESS:
    return {
        ...state,
    }
    case DeliveryTypes.UPDATE_PACKAGE_SIZE_FAILURE:
    return {
        ...state,
        error: action.error
    }
    default:
      return state
  }
}



export function rideOptions (state = {}, action) {
    switch (action.type) {
      case RideOptionsTypes.GET_SINGLE_RIDE_REQUEST:
      return {
          ...state
      }
      case RideOptionsTypes.GET_SINGLE_RIDE_SUCCESS:
      return {
          ...state,
          rideOptions: action.rideOptions
      }
      case RideOptionsTypes.GET_SINGLE_RIDE_FAILURE:
      return {
          ...state,
          error: action.error
      }
      default:
        return state
    }
  }


export function ride (state = {}, action) {
  switch (action.type) {
    case RideTypes.GET_RIDE_TYPES_REQUEST:
      return {
        ...state
      }
    case RideTypes.GET_RIDE_TYPES_SUCCESS:
      return {
        ...state,
        ride: action.ride
      }
    case RideTypes.GET_RIDE_TYPES_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case RideTypes.ADD_RIDE_TYPES_REQUEST:
    return {
        ...state
    }
    case RideTypes.ADD_RIDE_TYPES_SUCCESS:
    return {
        ...state,
    }
    case RideTypes.ADD_RIDE_TYPES_FAILURE:
    return {
        ...state,
        error: action.error
    }
    case RideTypes.DELETE_RIDE_TYPES_REQUEST:
    return {
        ...state
    }
    case RideTypes.DELETE_RIDE_TYPES_SUCCESS:
    return {
        ...state,
    }
    case RideTypes.DELETE_RIDE_TYPES_FAILURE:
    return {
        ...state,
        error: action.error
    }
    case RideTypes.UPDATE_RIDE_TYPES_REQUEST:
    return {
        ...state
    }
    case RideTypes.UPDATE_RIDE_TYPES_SUCCESS:
    return {
        ...state,
    }
    case RideTypes.UPDATE_RIDE_TYPES_FAILURE:
    return {
        ...state,
        error: action.error
    }
    default:
      return state
  }
}

export function deliveryTimeOptions (state = {}, action) {
    switch (action.type) {
      case DeliveryTimeOptionsTypes.GET_SINGLE_DELIVERY_REQUEST:
      return {
          ...state
      }
      case DeliveryTimeOptionsTypes.GET_SINGLE_DELIVERY_SUCCESS:
      return {
          ...state,
          deliveryTimeOptions: action.deliveryTimeOptions
      }
      case DeliveryTimeOptionsTypes.GET_SINGLE_DELIVERY_FAILURE:
      return {
          ...state,
          error: action.error
      }
      default:
        return state
    }
  }


export function deliveryTime (state = {}, action) {
  switch (action.type) {
    case DeliveryTimeTypes.GET_DELIVERY_TIMES_REQUEST:
      return {
        ...state
      }
    case DeliveryTimeTypes.GET_DELIVERY_TIMES_SUCCESS:
      return {
        ...state,
        deliveryTime: action.deliveryTime
      }
    case DeliveryTimeTypes.GET_DELIVERY_TIMES_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case DeliveryTimeTypes.ADD_DELIVERY_TIMES_REQUEST:
    return {
        ...state
    }
    case DeliveryTimeTypes.ADD_DELIVERY_TIMES_SUCCESS:
    return {
        ...state,
    }
    case DeliveryTimeTypes.ADD_DELIVERY_TIMES_FAILURE:
    return {
        ...state,
        error: action.error
    }
    case DeliveryTimeTypes.DELETE_DELIVERY_TIMES_REQUEST:
    return {
        ...state
    }
    case DeliveryTimeTypes.DELETE_DELIVERY_TIMES_SUCCESS:
    return {
        ...state,
    }
    case DeliveryTimeTypes.DELETE_DELIVERY_TIMES_FAILURE:
    return {
        ...state,
        error: action.error
    }
    case DeliveryTimeTypes.UPDATE_DELIVERY_TIMES_REQUEST:
    return {
        ...state
    }
    case DeliveryTimeTypes.UPDATE_DELIVERY_TIMES_SUCCESS:
    return {
        ...state,
    }
    case DeliveryTimeTypes.UPDATE_DELIVERY_TIMES_FAILURE:
    return {
        ...state,
        error: action.error
    }
    default:
      return state
  }
}
