import { subscriptionTypes,subscriptionPayTypes,subscriptionHistoryTypes } from "../types"


export function subscription (state = {}, action) {
  switch (action.type) {
    case subscriptionTypes.GET_SUBSCRIPTION_REQUEST:
      return {
        ...state
      }
    case subscriptionTypes.GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: action.subscription
      }
    case subscriptionTypes.GET_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        error: action.error
      }
      case subscriptionTypes.PUT_SUBSCRIPTION_REQUEST:
      return {
        ...state
      }
    case subscriptionTypes.PUT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        // subscription: action.subscription
      }
    case subscriptionTypes.PUT_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        error: action.error
      }
      case subscriptionTypes.GET_SINGLE_SUBSCRIPTION_REQUEST:
        return {
          ...state
        }
      case subscriptionTypes.GET_SINGLE_SUBSCRIPTION_SUCCESS:
        return {
          ...state,
          subscription: action.subscription
        }
      case subscriptionTypes.GET_SINGLE_SUBSCRIPTION_FAILURE:
        return {
          ...state,
          error: action.error
        }
    case subscriptionTypes.GET_SUBSCRIPTION_STATUS_REQUEST:
        return {
            ...state
        }
    case subscriptionTypes.GET_SUBSCRIPTION_STATUS_SUCCESS:
    return {
        ...state,
        subscription: action.subscription
    }
    case subscriptionTypes.GET_SUBSCRIPTION_STATUS_FAILURE:
    return {
        ...state,
        error: action.error
    }
    default:
      return state
  }
}



export function subscriptionPay (state = {}, action) {
  switch (action.type) {
    case subscriptionPayTypes.POST_SUBSCRIPTION_REQUEST:
    return {
      ...state
    }
  case subscriptionPayTypes.POST_SUBSCRIPTION_SUCCESS:
    return {
      ...state,
      subscriptionPay: action.subscriptionPay
    }
  case subscriptionPayTypes.POST_SUBSCRIPTION_FAILURE:
    return {
      ...state,
      error: action.error
    }
    default:
      return state
  }
}

export function subscriptionHistory (state = {}, action) {
  switch (action.type) {
    case subscriptionHistoryTypes.GET_SUBSCRIPTION_HISTORY_REQUEST:
        return {
            ...state
        }
    case subscriptionHistoryTypes.GET_SUBSCRIPTION_HISTORY_SUCCESS:
    return {
        ...state,
        subscriptionHistory: action.subscriptionHistory
    }
    case subscriptionHistoryTypes.GET_SUBSCRIPTION_HISTORY_FAILURE:
    return {
        ...state,
        error: action.error
    }
    default:
      return state
  }
}
