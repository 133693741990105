import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const columns = ({activeId, setActiveId,handleEdit,handleDelete,handleView}) => [
    {
        title: 'S/N',
        dataIndex: 'SN',
        key: 'SN',
      },
      {
        title: 'Staff Name',
        dataIndex: 'Name',
        key: 'Name',
      },
      {
        title: 'Role',
        dataIndex: 'Role',
        key: 'Role',
      },
      {
        title: 'Email Address',
        dataIndex: 'EmailAddress',
        key: 'EmailAddress',
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        // render: status => 
        // (<div className={`${status === 'in_transit' ? 'bg-warning flex justify-center items-center text-black  rounded-sm text-xs' : status === 'pending' ? 'bg-warning flex justify-center items-center text-black  rounded-sm text-xs' : status === 'completed' ? 'bg-success flex justify-center items-center text-black  rounded-sm text-xs' : status === 'incomplete' ? 'bg-fail flex justify-center items-center text-black  rounded-sm text-xs' : 'flex justify-center items-center text-black  rounded-sm text-xs'}`}>
        //     {status === 'in_transit' ? 'In-Progress' : status === 'pending' ? 'Pending' :  status === 'completed' ? 'Completed' : status === 'incomplete' ? 'Failed' : ''}
        // </div> )
      },
      {
        title: 'Action',
        render: (row) => 
        (
        <div onClick={(e) => {
          e.stopPropagation()
          setActiveId(row.id)
        }}   className="relative flex flex-row justify-center">
            <FontAwesomeIcon  icon={faEllipsisV} color="#006fc1" size="sm" />
           
            {activeId === row.id && (
            <div className="absolute top-0 bottom-0 flex flex-col bg-white w-20 h-20 z-10 border border-gray-200 justify-center items-center">
              <button onClick={()=>handleView(row)} className="hover:bg-gray-100 w-full h-full">View</button>
              <button onClick={()=>handleEdit(row)} className="hover:bg-gray-100 w-full h-full">Edit</button>
              <button onClick={()=>handleDelete(row)} className="hover:bg-gray-100 w-full h-full">Delete</button>
            </div>
        )}
          </div>
          )
      },
]