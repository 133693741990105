import React,{Fragment, useMemo, useState} from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { authActions } from '../../../actions'
import LoginImage from '../loginImage'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../components/Loader'

const Recover = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [loading,setLoading] = useState(false)
    const [query, setQuery] = useState({
        email: '',
      })

    const disabled = useMemo(
    () =>  !query.email,
    [ query.email]
    );

    const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
        ...prevState,
        [name]: value
    }));
    };



    const submit = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const formdata = new FormData();
          Object.entries(query).forEach(([key, value]) => {
          formdata.append(key, value);
            });
        
        const response =  await dispatch(authActions.sendPassword(formdata))
        if(response.data.status === true) {
            setLoading(false) 
            history.push(`/check-otp/${query.email}`)
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }

     }
    return (
        <Fragment>
            <div className='flex flex-row'>
                <div className='background hidden md:flex md:flex-col md:w-1/2 h-screen px-12 lg:px-32'>


                <LoginImage />

                </div>
        
            <section className='w-full md:w-1/2'>


        <div className='min-w-full px-8 sm:px-12 xl:px-32 py-12 xl:py-20 h-screen'>
            
            <div>
                <img src={`${process.env.REACT_APP_IMAGE}/healthLogo`} alt='' className='w-12 lg:w-20' />
            </div>



             <h1 className='mt-12 text-xl lg:text-3xl font-bold'>
                 Recover Password
            </h1>

            <p className='text-gray-400'>
                    Fill the details below to recover your password 
            </p>


            <form className='mt-12' onSubmit={(e)=>submit(e)}>

                 <div>
                     <input type='text' placeholder = 'Email Address' className='login-text-box' value={query.email} name='email' onChange={(e)=>handleInput(e)} />
                </div>
                    

            
                <button type='submit' disabled={disabled} className={disabled ? 'cursor-not-allowed opacity-70 bg-gray-300 mt-56 w-full text-white py-4 rounded-sm font-bold justify-center' : 'mt-56 w-full bg-hex-blue text-white py-4 rounded-sm font-bold flex items-center justify-center'}>
                Send Intructions {loading &&  <Loader />}
                </button>

            </form> 



        </div>

        </section>
            </div>
        </Fragment>
    )
}

export default Recover
