export const authTypes = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    SEND_PASSWORD_REQUEST: 'SEND_PASSWORD_REQUEST',
    SEND_PASSWORD_SUCCESS: 'SEND_PASSWORD_SUCCESS',
    SEND_PASSWORD_FAILURE: 'SEND_PASSWORD_FAILURE',

    UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',

    ADD_BUSINESS_REQUEST: 'ADD_BUSINESS_REQUEST',
    ADD_BUSINESS_SUCCESS: 'ADD_BUSINESS_SUCCESS',
    ADD_BUSINESS_FAILURE: 'ADD_BUSINESS_FAILURE',

  
    LOGOUT: 'LOGOUT',
}   