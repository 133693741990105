import React from "react";
import { Button, Menu, Dropdown, Popconfirm } from "antd";
import ViewBikeModal from "./viewBikeModal";
import ViewRiderModal from "./viewRiderModal";
import { Icon } from "@iconify/react";
// import Deletebutton from './deleteButton';
import { riderActions } from "../../../actions";
import { useDispatch } from "react-redux";

const Viewbike = (props) => {
  const dispatch = useDispatch();
  const { id } = props.data;

  const deleteRider = () => {
    dispatch(riderActions.deleteRider(id));
  };
  const disableRider = () => {
    dispatch(riderActions.disableRider(id));
  };
  const menu = (
    <Menu className="bikeMenu">
      <Menu.Item className="bord" key={1}>
        {props.type === "bike" ? (
          <ViewBikeModal data={props.data} />
        ) : (
          <ViewRiderModal data={props.data} />
        )}
      </Menu.Item>
      <Menu.Item key={2}>
        <Popconfirm
          title="Are you sure to disable?"
          onConfirm={disableRider}
          okText="Yes"
          cancelText="No"
        >
          <Button className="flex align-center" type="text">
            <Icon icon="fe:disabled" className="icons" /> Disable
          </Button>
        </Popconfirm>
      </Menu.Item>
      <Menu.Item key={3}>
        <Popconfirm
          title="Are you sure to delete?"
          onConfirm={deleteRider}
          okText="Yes"
          cancelText="No"
        >
          <Button className="flex align-center" type="text">
            <Icon icon="fluent:delete-48-filled" className="icons" /> Delete
          </Button>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} placement="bottomLeft" arrow>
      <Button size="middle" type="text">
        <Icon icon="bi:three-dots-vertical" />
      </Button>
    </Dropdown>
  );
};

export default Viewbike;
