import React from "react";
import "./App.css";
import Dashboard from "./screens/dashboard/dashboard";
import Auth from "./screens/Auth/auth"
import "react-calendar/dist/Calendar.css";
import { BrowserRouter as Router ,Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux'
import store from './store'
import Recover from './screens/Auth/Recover/Recover'
import PrivateRoute from "./routing/PrivateRoute";
import Update from "./screens/Auth/Update/Update";
import CheckEmail from './screens/Auth/CheckEmail';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RegisterBusiness from "./screens/Auth/RegisterBusiness/RegisterBusiness";
import { Helmet } from 'react-helmet'

function App() {
  return (
    <Provider store={store}>
      <Router>
      <Helmet>
        <link rel="icon" href={`${process.env.REACT_APP_MERCHANT_IMAGE}/logo192.png`} />
      </Helmet>
        <ToastContainer />
                <Switch>
                  <Route exact path='/merchant-admin/login' component={Auth} />
                  <Route path='/merchant-admin/reset-password' component={Recover} />
                  <Route path='/merchant-admin/check-otp/:email' component={CheckEmail} />
                  <Route path='/merchant-admin/update-password/:email' component={Update} />
                  <Route path='/merchant-admin/add-business' component={RegisterBusiness} />
                  <PrivateRoute path="/merchant-admin" component={Dashboard} />
                </Switch>
      </Router>
     </Provider>
  );
}

export default App;
