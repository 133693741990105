import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const columns = ({activeId, setActiveId,handleEdit,handleDelete}) => [
    {
      title: 'S/N',
      dataIndex: 'SN',
      key: 'SN',
    },
    {
      title: 'Categories',
      dataIndex: 'Categories',
      key: 'Categories',
    },
    {
          title: 'Action',
           render: (row) => 
           (
           <div onClick={(e) => {
             e.stopPropagation()
             setActiveId(row.id)
           }}   className="relative flex flex-row justify-center">
               <FontAwesomeIcon  icon={faEllipsisV} color="#006fc1" size="sm" />
              
               {activeId === row.id && (
               <div className="absolute top-0 bottom-0 flex flex-col bg-white w-16 h-16 z-10 border border-gray-200 justify-center items-center">
                 <button onClick={()=>handleEdit(row)} className="hover:bg-gray-100 w-full h-full">Edit</button>
                 <button onClick={()=>handleDelete(row)} className="hover:bg-gray-100 w-full h-full">Delete</button>
               </div>
           )}
             </div>
             )
         },
  ];


  export const columns2 = [
    {
      title: 'S/N',
      dataIndex: 'SN',
      key: 'SN',
    },
    {
      title: 'State',
      dataIndex: 'State',
      key: 'State',
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
    },
  ];