import React from 'react';

const Emptyuser = () => {
    return (
        <div className="flex center empty">
            <div>
                <div className='flex center'>
                <img src={`${process.env.REACT_APP_IMAGE}/wallet.png`} alt="wallet" />
                </div>
                <p>You do not have transactions yet</p>
                <p>Once you perform one or more transaction(s), all records will be displayed here.</p>
            </div>
        </div>
    );
}

export default Emptyuser;
