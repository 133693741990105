import React from 'react';
import { Table, Rate } from 'antd';
import DropDown from './DropDown'
import MobileCardRider from './MobileCardRider';
import { riderActions } from '../../../actions'
import EmptyData from './EmptyData';
import { useDispatch, useSelector } from "react-redux";
import Utils from '../../../helpers/utils';

const RiderTable = () => {
  const dispatch = useDispatch()
  const rider = useSelector(state=>state.rider)
  const riderList = rider?.riderList 

const handleTableChange = (pagination) =>{
  dispatch(riderActions.getRider(pagination))
}
const pagination =  riderList?.pagination

    const columns = [
      {
        title: 'S/N',
        key: 'id',
        render:(item, record, index)=>{
          return <p>{Utils.getSerialNumber(pagination.current, index, pagination.pageSize)}</p>
        }
      },
      {
        title: 'Rider Name',
        dataIndex: 'user',
        key: 'user',
        render: (user) => {
          return( <p>{user.name || "-"}</p>)
        },
      },
      {
        title: 'Ratings',
        dataIndex: 'ratings',
        key: 'ratings',
        render: (rate) => {
          return( <Rate disabled value={rate}/>)
        },
      },
      {
        title: 'Bike Name',
        dataIndex: 'name',
        key: 'name',
        render:(name)=>{
          return <p>{name || "-"}</p>
        }
      },
      {
        title: 'Rider Contact',
        dataIndex: 'user',
        key: 'user',
        render: (user) => {
          return( <p>{user.phone || "-"}</p>)
        },
      },
  
      {
        title: 'Action',
        key: 'row',
        render: (row) => {
          return( <DropDown data={row} type="rider"/>)
        },
      },
    ];
    
      
    return (
      <>
      {
      riderList?.data?.length > 0 ?
      <>
      <div className="d-sm-none">
        <Table dataSource={riderList?.data} columns={columns} className="userTable" pagination={ riderList?.pagination} onChange={handleTableChange}/>
        </div>
        <div className="d-lg-none">
        <MobileCardRider riders={riderList}/>
        </div>
        </>
        : 
        <EmptyData/>
      }
      </>
    );
}
    export default RiderTable;
