import React,{Fragment} from 'react'
import Login from './Login/Login'
import "./styles.css"
import LoginImage from './loginImage'

const Auth = () => {
    return (
        <Fragment>
            <div className='flex flex-row'>
            <div className='background hidden md:flex md:flex-col md:w-1/2 h-screen px-12 lg:px-32'>

                <LoginImage />

                </div>

            <section className='w-full md:w-1/2'>

                <Login />

            </section>
            </div>
        </Fragment>
    )
}

export default Auth
