import React, {useEffect} from "react";
import { Tabs, Button, DatePicker } from "antd";
import { Icon } from "@iconify/react";
import UsersTable from "./components/table";
import "./style.css";
import Headers from "../../components/Header";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { userActions } from '../../actions'
import Spinner from "../../components/Spinner";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const Users = () => {
  const dispatch = useDispatch()
  const user = useSelector(state=>state.user)
  const userList = !!user?.usersList 

  useEffect(() => {
    dispatch(userActions.getUser())
  }, [dispatch]);

  const onDateChange = (val, date)=>{
    let pageRequest = {
      start_date: date[0],
      end_date: date[1]
    }
    dispatch(userActions.filterUser(pageRequest))
  }
  const disabledDate = current => {
    return  current > moment().endOf('day');
  };
  const getLast30Days = () =>{
    let pageRequest = {
      end_date: moment().format('YYYY-MM-DD'),
      start_date: moment().subtract(30, 'days').format('YYYY-MM-DD')
    }
    dispatch(userActions.filterUser(pageRequest))
  }
  return (
    <div className="containerWrapper lg:h-screen flex flex-col px-0 lg:p-8">
      <Headers />
      { !userList ? <Spinner/> :
      <div className="container my4 userTabs">
          <Tabs defaultActiveKey="1">
            <TabPane tab={<h3>All Users</h3>} key="1">
              <UsersTable/>
            </TabPane>
          </Tabs>
          <div className="absolute abs">
          <RangePicker onChange={onDateChange} disabledDate={disabledDate}/>
          <Button className="btndownload d-md-none" onClick={getLast30Days}>
              <Icon
                className="icons"
                icon="bi:filter-right"
              />
             <small className="filterSpan">Filtered By Users From Last 30 Days</small>
            </Button>
            <Button className="btndownload">
              <Icon
                className="icons"
                icon="ant-design:cloud-download-outlined"
                color="#006FC1"
              />
              <small>Download</small>
            </Button>
            
          </div>
      </div>
}
    </div>
  );
};

export default Users;
