import axios from "axios"
import { config } from '../config'
import { authHeader } from "../helpers"

export const locationService = {
    getLocations,
    getSingleLocation,
    deleteLocation,
    addLocationToCategory
}

async function getLocations (category_id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/pricing-categories/${category_id}/locations`, requestOptions)
    return response;
}

async function getSingleLocation (category_id,location_id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/pricing-categories/${category_id}/locations/${location_id}`, requestOptions)
    return response;
}

async function deleteLocation (category_id,location_id) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.delete(`${config.testUrl}/settings/pricing-categories/${category_id}/locations/${location_id}`, requestOptions)
    return response;
}

async function addLocationToCategory (category_id,query) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/settings/pricing-categories/${category_id}/locations/`,query , requestOptions)
    return response;
}

