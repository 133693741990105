import React, { useState } from 'react';
import {Button, Modal, Rate } from 'antd';
import { Icon } from '@iconify/react';

const ViewbikeModal = ({data}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Button type="text" onClick={showModal} className="flex align-center">
                <Icon icon="ant-design:eye-outlined" className="icons" /> <small>View</small>
            </Button>
            <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false}>
                <h2>View Rider Details</h2>
                <p className="greyText">Please view all the details in the bike list below carefully and confirm
                    all inputs</p>
                <div>
                <div className="space-btw left bikeInfo pb4">
                    { <img src={ data.user.profile_image || '../img/userImg.png'} className="bikeImg" alt="bike"/> }
                </div>
                <div className="flex space-btw left bord bikeInfo">
                    <p>Full Name: </p>
                    <p className='capitalize bold' >{data.user.name}</p>
                    </div>
                    <div className="flex space-btw left bord bikeInfo">
                <p>Date of Birth: </p>
                <p className='bold'>{data.user.dob}</p>
                </div>
                <div className="flex space-btw left bord bikeInfo">
                <p>Phone Number: </p>
                    <p className='bold'>{data.user.phone}</p>
                </div>
                <div className="flex space-btw left bord bikeInfo">
                <p>Address: </p>
                    <p className='capitalize bold'>{data.user.address}</p>
                </div>
                <div className="flex space-btw left bord bikeInfo">
                <p>Rating: </p>
                    <p className='bold'>{<Rate disabled value={data.ratings} />}</p>
                </div>
                
                <div className="flex space-btw left bord bikeInfo">
                <p>Bank Name: </p>
                    <p className='capitalize bold'>{data.bank.name}</p>
                </div>
                <div className="flex space-btw left bord bikeInfo">
                <p>Bank Number: </p>
                    <p className='bold'>{data.account_no}</p>
                </div>
                <div className="flex space-btw left bord bikeInfo">
                <p>Bike Name: </p>
                    <p className='bold'>{data.name}</p>
                </div>
                <div className="flex space-btw left bord bikeInfo">
                <p>Bike Plate Number: </p>
                    <p className='bold'>{data.plate_no}</p>
                </div>
                </div>
                <Button className="modalBtn"  onClick={handleCancel}>Close</Button>
            </Modal>
        </>
    );
};

export default ViewbikeModal


