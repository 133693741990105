import { riderTypes } from "../types"

const initState = {}
export const rider =  (state = initState, action) => {
  switch (action.type) {
    case riderTypes.GET_RIDER_LIST:
      return {
        ...state,
        riderList: action.payload
      }
      case riderTypes.LOADING:
        return {
          ...state,
          isLoading: action.payload
        }
    default:
      return state
  }
}
