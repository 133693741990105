export const columns = [
  {
    title: "S/N",
    dataIndex: "SN",
    key: "SN",
  },
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    key: "Amount",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    render: (status) => (
      <div
        className={`${
          status === "in_transit"
            ? "bg-warning flex justify-center items-center text-black  rounded-sm text-xs"
            : status === "pending"
            ? "bg-warning flex justify-center items-center text-black  rounded-sm text-xs"
            : status === "successful"
            ? "bg-success flex justify-center items-center text-black  rounded-sm text-xs"
            : status === "incomplete"
            ? "bg-fail flex justify-center items-center text-black  rounded-sm text-xs"
            : "flex justify-center items-center text-black  rounded-sm text-xs"
        }`}
      >
        {status === "in_transit"
          ? "In-Progress"
          : status === "pending"
          ? "Pending"
          : status === "successful"
          ? "Success"
          : status === "incomplete"
          ? "Failed"
          : ""}
      </div>
    ),
  },
];

export const columns2 = [
  {
    title: "S/N",
    dataIndex: "SN",
    key: "SN",
  },
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
  },
  {
    title: "Delivery Desc",
    dataIndex: "Delivery",
    key: "Delivery",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    render: (status) => (
      <div
        className={`${
          status === "in_transit"
            ? "bg-warning flex justify-center items-center text-black  rounded-sm text-xs"
            : status === "pending"
            ? "bg-warning flex justify-center items-center text-black  rounded-sm text-xs"
            : status === "completed"
            ? "bg-success flex justify-center items-center text-black  rounded-sm text-xs"
            : status === "accepted"
            ? "bg-success flex justify-center items-center text-black  rounded-sm text-xs"
            : status === "incomplete"
            ? "bg-fail flex justify-center items-center text-black  rounded-sm text-xs"
            : "flex justify-center items-center text-black  rounded-sm text-xs"
        }`}
      >
        {status === "in_transit"
          ? "In-Progress"
          : status === "pending"
          ? "Pending"
          : status === "completed"
          ? "Completed"
          : status === "accepted"
          ? "Accepted"
          : status === "incomplete"
          ? "Failed"
          : ""}
      </div>
    ),
  },
];
