import React from "react";
import { Button, Menu, Dropdown, Popconfirm } from "antd";
import ViewUserModal from "./viewUserModal";
import { Icon } from "@iconify/react";
import { userActions } from "../../../actions";
import { useDispatch } from "react-redux";

const Viewuser = (props) => {
  const dispatch = useDispatch();
  const disableUser = () => {
    let id = props.data.id;
    dispatch(userActions.disableUser(id));
  };
  const deleteUser = () => {
    let id = props.data.id;
    dispatch(userActions.deleteUser(id));
  };

  const menu = (
    <Menu className="userMenu">
      <Menu.Item className="bord" key={1}>
        <ViewUserModal data={props.data} />
      </Menu.Item>
      <Menu.Item className="bord" key={2}>
        <Popconfirm
          title="Are you sure to disable?"
          onConfirm={disableUser}
          okText="Yes"
          cancelText="No"
        >
          <Button className="flex align-center" type="text">
            <Icon icon="fe:disabled" className="icons" /> Disable
          </Button>
        </Popconfirm>
      </Menu.Item>
      <Menu.Item key={3}>
        <Popconfirm
          title="Are you sure to delete?"
          onConfirm={deleteUser}
          okText="Yes"
          cancelText="No"
        >
          <Button className="flex align-center" type="text">
            <Icon icon="fluent:delete-48-filled" className="icons" /> Delete
          </Button>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} placement="bottomLeft" arrow>
      <Button size="middle" type="text">
        <Icon icon="bi:three-dots-vertical" />
      </Button>
    </Dropdown>
  );
};

export default Viewuser;
