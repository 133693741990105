import React, { Fragment, useState , useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { deliveryTimeActions, deliveryTimeOptionsActions } from '../../../../actions'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../../components/Loader';
import { Table,Modal } from 'antd'
import { columns } from '../tabledata'


const DeliveryTime = () => {
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()
    const deliveryTime = useSelector(state=>state.deliveryTime)
    const deliveryTimeOptions = useSelector(state=>state.deliveryTimeOptions)
    const [activeId, setActiveId] = useState(null)
    const [ID, setID] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const [isModalVisible2, setIsModalVisible2] = useState(false);

    const showModal2 = () => {
        setIsModalVisible2(true);
    };

    const handleCancel2 = () => {
        setIsModalVisible2(false);
    };


    const handleClick = () => {
        setActiveId(false)
      }
    
      useEffect(() => {
        document.addEventListener('click', handleClick)
        return () => {
          document.removeEventListener('click', handleClick)
        }
      }, [])

    useEffect(() => {
       dispatch(deliveryTimeActions.getDeliveryTime())
    }, [dispatch])

    let deliveryDetails = deliveryTime?.deliveryTime?.data?.data
    let deliveryList = deliveryTimeOptions?.deliveryTimeOptions?.data

    console.log(deliveryList)


    useEffect(() => {
        setList({
         name: deliveryList?.name ? deliveryList?.name : '',
         description: deliveryList?.description ? deliveryList?.description : '',
         price_effect: deliveryList?.price_effect ? deliveryList?.price_effect : ''
        })
     }, [deliveryList])

    const [list,setList] = useState({
        name: deliveryList?.name ? deliveryList?.name : '',
        description: deliveryList?.description ? deliveryList?.description : '',
        price_effect: deliveryList?.price_effect ? deliveryList?.price_effect : ''
    })

    const [query, setQuery] = useState({
        name: deliveryDetails?.name,
        description: deliveryDetails?.description,
        price_effect: deliveryDetails?.price_effect
      })

    const editInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setList((prevState) => ({
        ...prevState,
        [name]: value
    }));
    };

    const edit = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setList((prevState) => ({
            ...prevState,
            [name]: value
        }));
        };

    const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
        ...prevState,
        [name]: value
    }));
    };

    const handle = (e) => {
        const name = e.target.name;
        const value = parseInt(e.target.value);
        setQuery((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const editList = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const response =  await dispatch(deliveryTimeActions.updateDeliveryTime(deliveryList?.id,list))
        console.log(response)
        if(response.status === 200) {
            setLoading(false) 
            handleCancel()
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }
       dispatch(deliveryTimeActions.getDeliveryTime())

     }



    const submit = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const formdata = new FormData();
        Object.entries(query).forEach(([key, value]) => {
        formdata.append(key, value);
          });

        const response =  await dispatch(deliveryTimeActions.addDeliveryTime(formdata))
        if(response.status === 200) {
            setLoading(false) 
            handleCancel()
            setQuery({
                name: '',
                description: '',
                price_effect: ''
            })
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }
       dispatch(deliveryTimeActions.getDeliveryTime())

     }

     const deletePackage = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const response =  await dispatch(deliveryTimeActions.deleteDeliveryTime(ID))
        if(response.status === 200) {
            setLoading(false) 
            handleCancel2()
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }
       dispatch(deliveryTimeActions.getDeliveryTime())

     }
    const data = deliveryDetails !== '' ?  deliveryDetails?.map((item,i) => ({
        id: item.id,
        key: item?.id,
        SN: ++i,
        DeliveryTime: item?.name,
        Name: item?.description,
        Price: item?.price_effect,
    })) : [] 
    
    return (
        <Fragment>
             <Modal title={null} closable={true} centered={true} onCancel={handleCancel2} visible={isModalVisible2} footer={null}>
            <div className='text-center text-md'>Are you sure you want to delete this deliveryTime type?</div>
            <div className='mt-8 flex flex-row justify-center items-center'>
                <button onClick={handleCancel2} className='border-none bg-gray-300 text-white rounded-sm mx-4 p-2 px-4'>Cancel</button>
                <button onClick={(e)=>deletePackage(e,ID)} className='border-none bg-hex-blue text-white rounded-sm mx-4 p-2 px-4 flex items-center justify-center'>
                            Submit {loading &&  <Loader />}</button>
            </div>
        </Modal>
        <Modal title={'Edit Delivery Size'} closable={true} centered={true} onCancel={handleCancel} visible={isModalVisible} footer={null}>

                <form onSubmit={(e)=>editList(e)}>

                    <div>
                        <label className='font-bold text-xs'>Delivery Time</label>
                        <input type={'text'} className='regular-form' name='name' value={list.name} onChange={(e)=>editInput(e)} />
                    </div>

                    <div className='mt-8'>
                        <label className='font-bold text-xs'>Description</label>
                        <input type={'text'} className='regular-form' name='description' value={list.description} onChange={(e)=>editInput(e)} />
                    </div>

                    <div className='mt-8'>
                        <label className='text-xs font-bold'>Price</label>
                        <input type={'number'} className='regular-form' name='price_effect' value={list.price_effect} onChange={(e)=>edit(e)} />
                    </div>

                    <div>
                        <button type='submit' className='mt-12 w-full bg-hex-blue text-white py-4 rounded-sm font-bold flex items-center justify-center'>
                            Submit {loading &&  <Loader />}
                        </button>
                    </div>
                </form>

        </Modal>
            <div className='flex flex-row'>
                <div className='flex flex-col'>
                    <h1 className='text-hex-blue font-bold'>Determine Price By Delivery time</h1>

                    <p className='text-gray-500 text-xs'>Edit price information here</p>
                </div>

                <div>
                    <img src={`${process.env.REACT_APP_IMAGE}/price+tag.png`} alt='' className='w-12 ml-16' />
                </div>
            </div>

            <form onSubmit={(e)=>submit(e)} className='mt-12' >
                      <div className='grid grid-cols-3 gap-8 items-end'>
                        <div>
                            <label className='font-bold text-gray-500'>Delivery Time</label>
                            <input type={'text'} className='register-text-box' name='name' value={query.name} onChange={(e)=>handleInput(e)} />
                        </div>

                        <div>
                            <label className='font-bold text-gray-500'>Description</label>
                            <input type={'text'} className='register-text-box' name='description' value={query.description} onChange={(e)=>handleInput(e)} />
                        </div>


                        <div>
                            <label className='font-bold text-gray-500'>Price</label>
                            <div className='w-full px-0 py-0 rounded-sm focus:outline-none bg-light-gray border border-space-gray flex flex-row items-center'>
                                <label className='bg-blue-200 py-3 px-1 text-hex-blue font-bold'>N</label>
                                <input type={'number'} className='px-1 flex-1 bg-transparent focus:outline-none' name='price_effect' value={query.price_effect} onChange={(e)=>handle(e)} />
                            </div>
                        </div>

                        <div>
                        <button type='submit' className='w-full py-3 bg-hex-blue text-white rounded-md flex flex-row items-center justify-center'>Save Price{loading && <Loader />}</button>
                        </div>
                    </div>
            </form>


            <div className='mt-24'>
                <Table dataSource={data}  pagination={{defaultPageSize: '5'}}  {...{columns:columns({activeId, 
                    setActiveId,
                    handleEdit: async (row) => {
                        const response = await dispatch(deliveryTimeOptionsActions.getSingleDeliveryTime(row.id))
                        if (response) showModal()
                      },
                    handleDelete: async (row) => {
                    showModal2()
                    setID(row.id)
                    },
                    })  }} />
            </div>
        </Fragment>
    )
}

export default DeliveryTime
