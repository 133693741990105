
import { trackOrderTypes } from "../types";
import axios from "axios";
import Utils from "../helpers/utils";
// import { axiosConfig, requestStatus } from "../constants";
// import { config } from '../config'
// import Utils from "../helpers/utils";


const TRACK_ORDER_BASE_URL = `${process.env.REACT_APP_TEST_TRACKING_API_URL}`;
// dispatchers
const trackOrder = (pageRequest)=> async(dispatch)=>{
    let url = `${TRACK_ORDER_BASE_URL}/tracking/${pageRequest}`;
     try {
       const response = await axios.get(url);
        dispatch(loading)
        let data = response.data
        console.log(data);
        if(data.status){
          dispatch(track_order(data))
        }
      } catch (error) {
        // dispatch(track_order(error.response.data))
        Utils.warningNotification("An error occured")
  
      }
    }



// actions
const track_order = payload => {
    return{
        type: trackOrderTypes.TRACK_ORDER,
        payload
    }
}
const loading = payload => {
    return{
        type: trackOrderTypes.LOADING,
        payload
    }
}

// helper function
// const dataConstructor = (data) =>{
//     let pagination = {
//         current: data.pagination.current_page,
//         total: data.pagination.total,
//         pageSize: data.pagination.per_page,
//       }
//     let payload = {
//         data: data.pagination.data,
//         pagination,
//         status: requestStatus.success
//     }
//     return payload
// }

export const trackOrderActions = {
   trackOrder
}