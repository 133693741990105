import { orderTypes,assignOrderTypes} from "../types"


const initState = {}
export const orderDetails =  (state = initState, action) => {
  switch (action.type) {
    case orderTypes.GET_PENDING_ORDER:
      return {
        ...state,
        pendingOrder: action.payload
      }
      case orderTypes.FILTER_PENDING_ORDER:
      return {
        ...state,
        filteredOrderList: action.payload
      }
      
      case orderTypes.LOADING:
        return {
          ...state,
          isLoading: !!action.payload
        }
        
    default:
      return state
  }
}


export const completedOrderDetails =  (state = initState, action) => {
  switch (action.type) {
      case orderTypes.GET_COMPLETED_ORDER:
      return {
        ...state,
        completedOrderList: action.payload
      }
      case orderTypes.FILTER_PENDING_ORDER:
        return {
          ...state,
          filteredOrderList: action.payload
        }
      case orderTypes.LOADING:
        return {
          ...state,
          isLoading: !!action.payload
        }
    default:
      return state
  }
}



export function assignOrders (state = {}, action) {
  switch (action.type) {
    case assignOrderTypes.GET_NEARBY_RIDERS_REQUEST:
      return {
        ...state
      }
    case assignOrderTypes.GET_NEARBY_RIDERS_SUCCESS:
      return {
        ...state,
        assignOrders: action.assignOrders
      }
    case assignOrderTypes.GET_NEARBY_RIDERS_FAILURE:
      return {
        ...state,
        error: action.error
      }
      case assignOrderTypes.ASSIGN_ORDERS_REQUEST:
      return {
        ...state
      }
    case assignOrderTypes.ASSIGN_ORDERS_SUCCESS:
      return {
        ...state,
        // assignOrders: action.assignOrders
      }
    case assignOrderTypes.ASSIGN_ORDERS_FAILURE:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}

export function order (state = {}, action) {
  switch (action.type) {
    case orderTypes.GET_PENDING_ORDERS_REQUEST:
      return {
        ...state
      }
    case orderTypes.GET_PENDING_ORDERS_SUCCESS:
      return {
        ...state,
        order: action.order
      }
    case orderTypes.GET_PENDING_ORDERS_FAILURE:
      return {
        ...state,
        error: action.error
      }
      default:
      return state
  }
}


