import { orderTypes,assignOrderTypes } from "../types"
import { orderService } from "../services"
import axios from "axios";
// import { orderTypes } from "../types/order.types";
import { config } from '../config'
import Utils from "../helpers/utils";
import { axiosConfig, requestStatus } from "../constants";

export const orderDashboardActions = {
    getPendingOrdersDashboard
}

export const assignOrderActions = {
  getNearbyRiders,
  assignOrders
}


function getPendingOrdersDashboard (row,page) {
    return async dispatch => {
      dispatch(request(row,page))
  
      try {
        const order = await orderService.getPendingOrders(row,page)
        dispatch(success(order))
        return order

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (row,page) { return { type: orderTypes.GET_PENDING_ORDERS_REQUEST,row,page } }
    function success (order) { return { type: orderTypes.GET_PENDING_ORDERS_SUCCESS, order } }
    function failure (error) { return { type: orderTypes.GET_PENDING_ORDERS_FAILURE, error } }
    
  }

  function getNearbyRiders (id,search) {
    return async dispatch => {
      dispatch(request(id,search))
  
      try {
        const assignOrders = await orderService.getNearbyRiders(id,search)
        dispatch(success(assignOrders))
        return assignOrders

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (id,search) { return { type: assignOrderTypes.GET_NEARBY_RIDERS_REQUEST,id,search } }
    function success (assignOrders) { return { type: assignOrderTypes.GET_NEARBY_RIDERS_SUCCESS, assignOrders } }
    function failure (error) { return { type: assignOrderTypes.GET_NEARBY_RIDERS_FAILURE, error } }
    
  }

  function assignOrders (formdata) {
    return async dispatch => {
      dispatch(request(formdata))
  
      try {
        const assignOrders = await orderService.assignOrders(formdata)
        dispatch(success(assignOrders))
        return assignOrders
 
      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (formdata) { return { type: assignOrderTypes.ASSIGN_ORDERS_REQUEST,formdata } }
    function success (assignOrders) { return { type: assignOrderTypes.ASSIGN_ORDERS_SUCCESS, assignOrders } }
    function failure (error) { return { type: assignOrderTypes.ASSIGN_ORDERS_FAILURE, error } }
    
  }



const PENDING_ORDER_URL = `${config.testUrl}/pending-orders`
// dispatchers
const getPendingOrders = (pageRequest) => async (dispatch) =>{
    dispatch(loading(true))
    let reqPagination = pageRequest ? pageRequest : {current: 1, pageSize: 10}
    let url = `${PENDING_ORDER_URL}?rows=${reqPagination.pageSize}&page=${reqPagination.current}`
    let header = axiosConfig();

    try {
        const response = await axios.get(url, header);
        
        const data = response.data;
        // console.log(data.meta)
        if(data && data.meta){
            let payload = dataConstructor(data)
            dispatch(get_pendingOrders(payload))
            // console.log(payload)
        }   
        dispatch(loading(false))

    } catch (error) {
        dispatch(get_pendingOrders({status: requestStatus.failed}))
        dispatch(loading(false))
        Utils.warningNotification('An Error occurred');
        return error && error.response
    }

}

const filterPendingOrders = (pageRequest) => async(dispatch) =>{
    if(pageRequest.start_date.trim() === "")  {
        dispatch(filter_pendingOrder({status: requestStatus.failed}))
     }
    let reqPagination = pageRequest?.pagination || {current: 1, pageSize: 10}
    let url = `${PENDING_ORDER_URL}/filter?start_date=${pageRequest.start_date}&end_date=${pageRequest.end_date}&rows=${reqPagination.pageSize}&page=${reqPagination.current}`
    let header = axiosConfig()
 
    try {
      const response = await axios.get(url, header);
     dispatch(loading(true))
        const data = response.data;
        if(data && data.meta){
           let payload = dataConstructor(data)
            dispatch(filter_pendingOrder(payload))
             dispatch(loading(false))
        }   
    } catch (error) {
        dispatch(filter_pendingOrder({status: requestStatus.failed}))
        dispatch(loading(false))
        return error && error.response
    }
 }

 const COMPLETED_ORDER_URL = `${config.testUrl}/orders`
 // dispatchers
const getCompletedOrders = (pageRequest) => async (dispatch) =>{
    dispatch(loading(true))
    let reqPagination = pageRequest ? pageRequest : {current: 1, pageSize: 10}
    let completed = 'completed'
    let url = `${COMPLETED_ORDER_URL}?rows=${reqPagination.pageSize}&page=${reqPagination.current}&s=${completed}`
    let header = axiosConfig();

    try {
        const response = await axios.get(url, header);
        
        const data = response.data;
        console.log(data)
        if(data && data.meta){
            let payload = dataConstructor(data)
            dispatch(get_completedOrders(payload))
            // console.log(payload)
        }   
        dispatch(loading(false))

    } catch (error) {
        dispatch(get_completedOrders({status: requestStatus.failed}))
        dispatch(loading(false))
        Utils.warningNotification('An Error occurred');
        return error && error.response
    }

}



// actions
const get_pendingOrders = payload => {
    return{
        type: orderTypes.GET_PENDING_ORDER,
        payload
    }
}

const get_completedOrders = payload => {
    return{
        type: orderTypes.GET_COMPLETED_ORDER,
        payload
    }
}

const filter_pendingOrder = payload => {
    return{
        type: orderTypes.FILTER_PENDING_ORDER,
        payload
    }
}
const loading = payload => {
    return{
        type: orderTypes.LOADING,
        payload
    }
}

// helper function
const dataConstructor = (data) =>{
    let pagination = {
        current: data.meta.current_page,
        total: data.meta.total,
        pageSize: data.meta.per_page,
      }
    let payload = {
        data: data?.data,
        pagination,
        status: requestStatus.success
    }
    return payload
}
export const orderActions = {
    getPendingOrders,
    filterPendingOrders,
    getCompletedOrders
}

