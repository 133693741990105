import React, { useState } from 'react';
import {Button, Modal, Rate } from 'antd';
import { Icon } from '@iconify/react';

const ViewbikeModal = ({data}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Button type="text" onClick={showModal} className="flex align-center">
                <Icon icon="ant-design:eye-outlined" className="icons" /> <small>View</small>
            </Button>
            <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false}>
                <h2>View Bike Details</h2>
                <p className="greyText">Please view all the details in the bike list below carefully and confirm
                    all inputs</p>
                <div>
                <div className="flex space-btw left bord bikeInfo">
                    <p>Rider Name: </p>
                    <p>{data.user.name}</p>
                    </div>
                <div className="flex space-btw left bord bikeInfo">
                <p>Rider Rating: </p>
                <p>{<Rate disabled value={data.ratings} />}</p>
                </div>
                <div className="flex space-btw left bord bikeInfo">
                <p>Rider Address: </p>
                    <p>{data.user.address}</p>
                </div>
                <div className="flex space-btw left bord bikeInfo">
                <p>Bike Name: </p>
                    <p>{data.name}</p>
                </div>
                <div className="flex space-btw left bord bikeInfo">
                <p>Bike Plate Number: </p>
                    <p>{data.plate_no}</p>
                </div>
                <div className="flex space-btw left bord bikeInfo">
                <p>Phone Number: </p>
                    <p>{data.user.phone}</p>
                </div>
                <div className="space-btw left bikeInfo pb4">
                    { <img src={`${data?.user?.profile_image}` || '../img/userImg.png'} className="bikeImg" alt="bike"/> }
                </div>
                </div>
                <Button className="modalBtn"  onClick={handleCancel}>Close</Button>
            </Modal>
        </>
    );
};

export default ViewbikeModal


