import { walletTypes } from "../types"

const initState = {}
export const wallet =  (state = initState, action) => {
  switch (action.type) {
    case walletTypes.GET_WALLET_LIST:
      return {
        ...state,
        walletList: action.payload
      }
      case walletTypes.GET_WALLET_CHART:
        return {
          ...state,
          chartInfo: action.payload
        }
    default:
      return state
  }
}
