import axios from "axios"
import { config } from '../config'
import { authHeader } from "../helpers"

export const dashboardService = {
    getDashboard
}

async function getDashboard (formdata) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/dashboard`, requestOptions)
    return response;
}
