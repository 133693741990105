import axios from "axios";
import { config } from "../config";
import { BASE_URL } from "../constants";
import { authHeader } from "../helpers";

export const orderService = {
  getPendingOrders,
  getNearbyRiders,
  assignOrders,
};

async function getPendingOrders(row, page) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  const response = await axios(
    `${config.testUrl}/orders?rows=${row}&page=${page}&s=pending`,
    requestOptions
  );
  // const response = await axios(`${config.testUrl}/pending-orders?rows=${row}&page=${page}`, requestOptions)
  return response;
}

async function getNearbyRiders(id, search) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  const response =
    search === ""
      ? await axios(
          `${BASE_URL}/riders?rows=10&page=1`,
          requestOptions
        )
      : await axios(
          `${config.testUrl}/nearby-riders/?order_id=${id}&search=${search}`,
          requestOptions
        );
  return response;
}

async function assignOrders(formdata) {
  const requestOptions = {
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  const response = await axios.post(
    `${config.testUrl}/assign-order-rider`,
    formdata,
    requestOptions
  );
  return response;
}
