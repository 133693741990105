import { brandColorTypes, settingsTypes } from "../types"
import { brandColorService, settingsService } from "../services"

export const settingsActions = {
    getPaymentOptions,
    updateMultipleStatus,
    getRiderSettings,
    updateRiderSettings
}

export const brandColorActions = {
  getBrandColor,
  putBrandColor
}

function getPaymentOptions () {
    return async dispatch => {
      dispatch(request())
  
      try {
        const settings = await settingsService.getPaymentOptions()
        dispatch(success(settings))
        return settings

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request () { return { type: settingsTypes.GET_PAYMENT_OPTIONS_REQUEST } }
    function success (settings) { return { type: settingsTypes.GET_PAYMENT_OPTIONS_SUCCESS, settings } }
    function failure (error) { return { type: settingsTypes.GET_PAYMENT_OPTIONS_FAILURE, error } }
    
  }

  function updateMultipleStatus (payment_options) {
    return async dispatch => {
      dispatch(request(payment_options))
  
      try {
        const settings = await settingsService.updateMultipleStatus(payment_options)
        dispatch(success(settings))
        return settings

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (payment_options) { return { type: settingsTypes.UPDATE_MULTIPLE_STATUS_REQUEST,payment_options } }
    function success (settings) { return { type: settingsTypes.UPDATE_MULTIPLE_STATUS_SUCCESS, settings } }
    function failure (error) { return { type: settingsTypes.UPDATE_MULTIPLE_STATUS_FAILURE, error } }
    
  }


  function getRiderSettings () {
    return async dispatch => {
      dispatch(request())
  
      try {
        const settings = await settingsService.getRiderSettings()
        dispatch(success(settings))
        return settings

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request () { return { type: settingsTypes.GET_RIDER_SETTINGS_REQUEST } }
    function success (settings) { return { type: settingsTypes.GET_RIDER_SETTINGS_SUCCESS, settings } }
    function failure (error) { return { type: settingsTypes.GET_RIDER_SETTINGS_FAILURE, error } }
    
  }


  function updateRiderSettings (body) {
    return async dispatch => {
      dispatch(request(body))
  
      try {
        const settings = await settingsService.updateRiderSettings(body)
        dispatch(success(settings))
        return settings

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (body) { return { type: settingsTypes.UPDATE_RIDER_REQUEST,body } }
    function success (settings) { return { type: settingsTypes.UPDATE_RIDER_SUCCESS, settings } }
    function failure (error) { return { type: settingsTypes.UPDATE_RIDER_FAILURE, error } }
    
  }


  function getBrandColor () {
    return async dispatch => {
      dispatch(request())
  
      try {
        const color = await brandColorService.getBrandColor()
        dispatch(success(color))
        return color

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request () { return { type: brandColorTypes.GET_BRAND_COLOR_REQUEST } }
    function success (color) { return { type: brandColorTypes.GET_BRAND_COLOR_SUCCESS, color } }
    function failure (error) { return { type: brandColorTypes.GET_BRAND_COLOR_FAILURE, error } }
    
  }

  function putBrandColor (formdata) {
    // console.log(formdata)
    return async dispatch => {
      dispatch(request(formdata))
  
      try {
        const color = await brandColorService.putBrandColor(formdata)
        dispatch(success(color))
        return color

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (formdata) { return { type: brandColorTypes.UPDATE_BRAND_COLOR_REQUEST,formdata } }
    function success (color) { return { type: brandColorTypes.UPDATE_BRAND_COLOR_SUCCESS, color } }
    function failure (error) { return { type: brandColorTypes.UPDATE_BRAND_COLOR_FAILURE, error } }
    
  }