import axios from "axios"
import { config } from '../config'
import { authHeader } from "../helpers"

export const categoryService = {
    getCategories,
    addCategories,
    putCategories,
    deleteCategories
}

async function getCategories (param) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/pricing-categories?type=${param}`, requestOptions)
    return response;
}

async function addCategories (value) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/settings/pricing-categories`, value , requestOptions)
    return response;
}

async function putCategories (id,formdata) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.put(`${config.testUrl}/settings/pricing-categories/${id}`,formdata , requestOptions)
    return response;
}

async function deleteCategories (id) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.delete(`${config.testUrl}/settings/pricing-categories/${id}`, requestOptions)
    return response;
}
