import React, {useState} from 'react'
import { Row, Col, Button, Form, Input } from 'antd'
import { Icon } from '@iconify/react';
import Headers from '../../components/Header'
import ProfileCards from './components/cards';
import './style.css'
import ProgressBar from './components/progressBar';
import Maps from './components/googleMaps/Maps';
import { useDispatch, useSelector } from 'react-redux';
import { trackOrderActions } from '../../actions';

const Tracking = () => {
  const [trackingId, setTrackingId] = useState('');
  const [hideForm, setFormVisibilty] = useState(false)
  const dispatch = useDispatch()
  const tracking= useSelector(state=>state.trackOrder)
  const trackingDetails = tracking?.trackingDetails
  const data = trackingDetails && trackingDetails.receiver_info ? {
    destination_address: trackingDetails.receiver_info.destination_address,
    pickup_address: trackingDetails.sender_info.pickup_address,
    center:{
    lat: trackingDetails.sender_info.pickup_lat,
    lng: trackingDetails.sender_info.pickup_long
  }
} : {...trackingDetails}
  const onFinish = (value) =>{
    if(value){
      console.log(value);
    dispatch(trackOrderActions.trackOrder(value.trackingId))

    setTrackingId(value.trackingId)
    setFormVisibilty(true)

    }
  }
  const switchContent = () =>{
    setFormVisibilty(false)
  }

  return (
    <div className="containerWrapper">
      <Headers />
      <div className="container my4 container-fluid">
        <div className="flex space-btw">
          <h4 className="title">
            Tracking {trackingId && <span>: {trackingId} </span>}
          </h4>
          <Button className="btndownload flex align-center">
            <Icon
              className="icons"
              icon="ant-design:cloud-download-outlined"
              color="#006FC1"
            />
            Download
          </Button>
          </div>
        {hideForm && trackingDetails?.receiver_info && trackingId ? (
          <Row className="py4">
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <ProfileCards data={trackingDetails}/>
            </Col>
            <Col xs={24} sm={16} md={16} lg={16} xl={16}>
              <ProgressBar trackingId={trackingId} data={trackingDetails}/>
              <div className="maps">
              <Maps data={data}/>
              </div>
              <div className='py4'>
              <Button className='prg' type='primary' block size='large' onClick={switchContent}>Track New Order</Button>
              </div>
            </Col>
          </Row>
        ) : (
          <div className="form-center">
            <div>
          <div >
          <Form onFinish={onFinish} className="py4" >
            <Form.Item name="trackingId">
              <Input prefix={ <Icon icon="bx:bx-search" />} placeholder="Enter Tracking ID" className="trackingInput"/>
            </Form.Item>
          </Form>
          </div>

         { <p style={{textAlign:"center"}}>{trackingDetails?.message && trackingDetails.message }</p>}
         </div>
</div>
         )} 
      </div>
    </div>
  );
}

export default Tracking
