import { Table } from 'antd'
import React, { Fragment ,useState} from 'react'
import { useDispatch } from 'react-redux'
import { categoryActions, locationActions, mappingsActions } from '../../../../../../actions'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { columns4 } from '../tabledata'

const IntraLocation = (props) => {
    const {intraCategory} = props
// eslint-disable-next-line
    const [loading,setLoading] = useState(false)
    // const [active,setActive] = useState(intraCategory[0]?.id)
    
    const dispatch = useDispatch()

// eslint-disable-next-line
    const [activeId, setActiveId] = useState(null)
    const data = []

    const [query, setQuery] = useState({
        // category_id: '',
        type: 'intra',
        name: '',
        category_id: intraCategory[0]?.id
      })

      const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setQuery((prevState) => ({
            ...prevState,
            [name]: value
        }));
        };


    const submit = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const response =  await dispatch(locationActions.addLocationToCategory(query?.category_id,query))
        if(response.status === 200) {
            setLoading(false) 
            setQuery({
                pickup_category_id: '',
                dest_category_id: '',
                price: '',
                type:'intra'
            })
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }
       dispatch(categoryActions.getCategories('intra'))
       dispatch(mappingsActions.getMappings('intra'))
     }
    return (
        <Fragment>
            <form onSubmit={(e)=>submit(e)} className='grid grid-cols-3 gap-8 items-end'>
                        <div>
                            <label className='font-bold text-gray-500'>Locations</label>
                            <input type={'text'} className='register-text-box' name='name' value={query.name} onChange={(e)=>handleInput(e)}  />
                        </div>

                        <div>
                            <label className='font-bold text-gray-500'>Category</label>
                            <select name='category_id' value={query.category_id} onChange={(e)=>handleInput(e)}  className='register-text-box'>
                                <option value={'0'}>* Select</option>
                                {intraCategory?.map((x)=> (
                                    <option value={x.id} key={x.id}>{x?.name}</option>
                                ))}
                            </select>
                        </div>

                        <div>
                        </div>

                        <button className='w-auto px-4 py-3 bg-hex-blue text-white rounded-md'>Add</button>

            </form>

            <div className='mt-8'>
                <Table dataSource={data} columns={columns4} />
            </div>
        </Fragment>
    )
}

export default IntraLocation
