import { mappingsTypes } from "../types"


export function mappings (state = {}, action) {
  switch (action.type) {
    case mappingsTypes.GET_MAPPINGS_REQUEST:
      return {
        ...state
      }
    case mappingsTypes.GET_MAPPINGS_SUCCESS:
      return {
        ...state,
        mappings: action.mappings
      }
    case mappingsTypes.GET_MAPPINGS_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case mappingsTypes.ADD_MAPPINGS_REQUEST:
      return {
        ...state
      }
    case mappingsTypes.ADD_MAPPINGS_SUCCESS:
      return {
        ...state,
      }
    case mappingsTypes.ADD_MAPPINGS_FAILURE:
      return {
        ...state,
        error: action.error
      }
      case mappingsTypes.UPDATE_MAPPINGS_REQUEST:
        return {
          ...state
        }
      case mappingsTypes.UPDATE_MAPPINGS_SUCCESS:
        return {
          ...state,
        }
      case mappingsTypes.UPDATE_MAPPINGS_FAILURE:
        return {
          ...state,
          error: action.error
        }
      
        case mappingsTypes.DELETE_MAPPINGS_REQUEST:
      return {
        ...state
      }
    case mappingsTypes.DELETE_MAPPINGS_SUCCESS:
      return {
        ...state,
      }
    case mappingsTypes.DELETE_MAPPINGS_FAILURE:
      return {
        ...state,
        error: action.error
      }
      
    default:
      return state
  }
}
