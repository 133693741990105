import React, {useEffect} from 'react';
import { Table, Tag, Spin } from 'antd';
import DropDown from './DropDown'
import { useDispatch, useSelector } from 'react-redux'
import { orderActions } from '../../../actions'
import Utils from '../../../helpers/utils';
import EmptyData from './EmptyData';

const OngoingOrderTable = () => {
  const dispatch = useDispatch()
  const orderDetails = useSelector(state=>state.orderDetails)
  const pendingOrder = orderDetails?.pendingOrder 
  // console.log(pendingOrder)
  const filteredOrderList = orderDetails?.filteredOrderList
  const Loading = orderDetails?.isLoading
  useEffect(() => {
    dispatch(orderActions.getPendingOrders())
  }, [dispatch]);


  const handleTableChange = (pagination) =>{
    dispatch(orderActions.getPendingOrders(pagination))
  }

  const dataSource = pendingOrder?.data
  const pagination = (filteredOrderList?.data === 'success' && filteredOrderList?.pagination) || pendingOrder?.pagination
    const columns = [
      {
        title: 'S/N',
        dataIndex: 'key',
        key: 'key',
        render:(item, record, index)=>{
          return <p>{Utils.getSerialNumber(pagination.current, index, pagination.pageSize)}</p>
        }
      },
      {
        title: 'Sender Name',
        dataIndex: 'sender_name',
        key: 'sender_name',
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: status => {
            let color = status === "pending" ? '#FFB113' : status === "Ongoing" ? '#7AAC52' :  '#E3000F';
             return(
                <Tag color={color} className="bikeTags">
                {status}
              </Tag>
             )
        },
      },
      {
        title: 'Receiver Name',
        dataIndex: 'receiver_name',
        key: 'receiver_name',
      },
     
      {
        title: 'Description',
        dataIndex: 'item_description',
        key: 'item_description',
      },
      // {
      //   title: 'Amount',
      //   dataIndex: 'payment.price',
      //   key: 'payment.price',
      // },
      {
        title: 'Action',
        key: 'row',
        render: (row) => {
          return( <DropDown data={row} type="ongoingOrders"/>)
        },
      },
    ];
    
      
    return (
        <>
        {
          Loading || !pendingOrder?.data ? 
          <div className="spinWrapper">
          <Spin />
          </div> 
        :
          !Loading && pendingOrder?.data?.length > 0 ?
          <>
          <div className="d-sm-none">
      <Table dataSource={dataSource} columns={columns} className="bikeTable" 
      pagination={ filteredOrderList?.pagination || pendingOrder?.pagination} onChange={handleTableChange}
      />
      </div>
      <div className="d-lg-none">
      {/* <MobileCardBike data={data}/> */}
      </div>
      </>
      :
      <EmptyData/>
        }
    </>
    );
}
    export default OngoingOrderTable;
