import axios from "axios"
import { config } from '../config'
import { authHeader } from "../helpers"

export const mappingsService = {
    getMappings,
    addMappings,
    putMappings,
    deleteMappings
}

async function getMappings (param) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/pricing-categories/mappings?type=${param}`, requestOptions)
    return response;
}

async function addMappings (value) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/settings/pricing-categories/mappings`, value , requestOptions)
    return response;
}

async function putMappings (id,formdata) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/settings/pricing-categories/mappings/`,formdata , requestOptions)
    return response;
}

async function deleteMappings (id) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.delete(`${config.testUrl}/settings/pricing-categories/mappings/${id}`, requestOptions)
    return response;
}
