import React , { useState, useMemo } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authActions } from '../../../actions/auth.actions';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../components/Loader';

const Login = () => {
    const [password,showPassword] = useState(false)
    const auth = sessionStorage.getItem('isAuthenticated')
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState({
        email: '',
        password: '',
      })

    const disabled = useMemo(
    () =>  !query.email || !query.password,
    [ query.email, query.password]
    );

    const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
        ...prevState,
        [name]: value
    }));
    };



    const submit = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const formdata = new FormData();
          Object.entries(query).forEach(([key, value]) => {
          formdata.append(key, value);
            });
        
        const response =  await dispatch(authActions.login(formdata))
        if(response.data.status === true) {
            setLoading(false) 
          toast.success('Welcome...',{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }

     }
    //  console.log(auth)

        // useEffect(() => {
            if (auth){
                return <Redirect to='/merchant-admin'/>
            }
        // }, [auth])
     

    return (
        <div className='min-w-full px-8 sm:px-12 xl:px-32 py-8 xl:py-10 h-screen'>
            <div>
                <img src={`${process.env.REACT_APP_IMAGE}/healthLogo.png`} alt='' className='w-12 lg:w-20' />
            </div>
            <h1 className='mt-10 lg:mt-28 text-xl lg:text-3xl font-bold'>
                Login into your account
            </h1>
            <p className='text-gray-400'>
            Fill in your valid credentials to continue.
            </p>
            <form className='mt-12' onSubmit={(e)=>submit(e)}>
                <div>
                     <input type='text' placeholder = 'Email Address' className='login-text-box' value={query.email} name='email' onChange={(e)=>handleInput(e)} />
                </div>
                <div className='mt-12 login-text-box flex bg-white flex-row item-center justify-between'>
                    <input type={password ? 'text' : 'password'} placeholder = 'Password' className='flex-1 outline-none' value={query.password} name='password' onChange={(e)=>handleInput(e)}/>
                    <button type='button' className='' onClick={()=>showPassword(!password)}>
                        {password ? <FontAwesomeIcon icon={faEyeSlash} color="#ADADAD" size="lg" /> : <FontAwesomeIcon icon={faEye} color="#ADADAD" size="lg" />}
                    </button>
                </div>


                <div className='mt-16'>
                    <Link to='/merchant-admin/reset-password' className='text-gray-700 text-xs hover:underline hover:text-black'>Forgot Password?</Link>
                </div>

                <button type='submit' disabled={disabled} className={disabled ? 'cursor-not-allowed opacity-70 bg-gray-300 mt-12 w-full text-white py-4 rounded-sm font-bold justify-center' : 'mt-12 w-full bg-hex-blue text-white py-4 rounded-sm font-bold flex items-center justify-center'}>
                        Login into Account {loading &&  <Loader />}
                </button>

            </form>


            {/* <p className='mt-16 text-center'>Dont have an account? <Link to='/add-business' className='text-green-400 hover:text-green-700 underline'>Register for one now</Link></p> */}

        </div>
    )
}

export default Login
