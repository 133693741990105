import React , { useState } from 'react'
import { authActions } from '../../../actions/auth.actions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../components/Loader';



const RegisterBusiness = () => {
    const [show,setShow] = useState(0)
    const dispatch = useDispatch()
    const [loading,setLoading] = useState(false)
    const [query, setQuery] = useState({
        name: '',
        email: '',
        phone_number: '',
        address: '',
        country:'',
        state: '',
        city: '',
        rc_number: '',
        logo:'',
        first_name:'',
        last_name:'',
        identity_type: '',
        identity_no:''
      })

    // const disabled = useMemo(
    // () =>  !query.email || !query.password,
    // [ query.email, query.password]
    // );

    const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
        ...prevState,
        [name]: value
    }));
    };

    const handleChange = async (e) => {
      const file = e.target.files[0]
      const name = e.target.name;
      setQuery((prevState) => ({
          ...prevState,
          [name]: file
        }));
    }



    const submit = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const formdata = new FormData();
          Object.entries(query).forEach(([key, value]) => {
          formdata.append(key, value);
            });

            console.log(...formdata)
        
        const response =  await dispatch(authActions.addBusiness(formdata))
        console.log(response)
        if(response.data.status === true) {
            setLoading(false) 
          toast.success('Welcome...',{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }

     }
    //  console.log(auth)


    //  if (auth){
    //     return <Redirect to='/dashboard'/>
    //   }
    return (
        <div className='w-screen h-screen flex flex-row justify-center items-center' style={{backgroundColor:'#f9f9f9'}}>

            <div className='card bg-white h-auto w-1/2 p-24 pt-12 rounded-xl shadow-sm'>
               
               <div className='w-full flex flex-row justify-center'>
                <button onClick={()=>setShow(!show)} className={!show ? 'h-4 w-4 bg-hex-blue rounded-full mx-1' : 'h-4 w-4 bg-gray-200 rounded-full mx-1'}></button>
                <button onClick={()=>setShow(!show)} className={show ? 'h-4 w-4 bg-hex-blue rounded-full mx-1' : 'h-4 w-4 bg-gray-200 rounded-full mx-1'}></button>
              </div>

              <form onSubmit={(e)=>submit(e)}>
              {!show ? (<>
              <h1 className='text-gray-800 text-lg'>
                 Business Details
              </h1>
              <p className='text-gray-500'>
              The following informations is used to collect your business info
              </p>

              <div className='mt-8 grid grid-cols-2 gap-24'>
                  <div>
                    <label className='font-bold'>Business Name<span className='text-red-700'>*</span></label>
                    <input type={'text'} className='register-text-box' name='name' value={query.name} onChange={(e)=>handleInput(e)} />
                  </div>

                  <div>
                    <label className='font-bold'>Business Email Address<span className='text-red-700'>*</span></label>
                    <input type={'text'} className='register-text-box' />
                  </div>
              </div>

              <div className='mt-8 grid grid-cols-2 gap-24'>
                  <div>
                    <label className='font-bold'>Business Phone Number<span className='text-red-700'>*</span></label>
                    <input type={'number'} className='register-text-box' />
                  </div>

                  <div>
                    <label className='font-bold'>Business Address<span className='text-red-700'>*</span></label>
                    <input type={'text'} className='register-text-box' name='address' value={query.address} onChange={(e)=>handleInput(e)}/>
                  </div>
              </div>

              <div className='mt-8 grid grid-cols-2 gap-24'>
                  <div>
                    <label className='font-bold'>City<span className='text-red-700'>*</span></label>
                    <input type={'text'} className='register-text-box' name='city' value={query.city} onChange={(e)=>handleInput(e)} />
                  </div>

                  <div>
                    <label className='font-bold'>RC Number<span className='text-red-700'>*</span></label>
                    <input type={'number'} className='register-text-box'  name='rc_number' value={query.rc_number} onChange={(e)=>handleInput(e)} />
                  </div>
              </div>

              <div className='mt-8 grid grid-cols-2 gap-24'>
                  <div>
                    <label className='font-bold'>Upload Business Logo<span className='text-red-700'>*</span></label>
                    <input type={'file'} className='file-btn' name='logo_url' onChange={(e)=>handleChange(e)} />
                  </div>

              </div>

              <button type='button' onClick={(e)=>{setShow(!show);e.preventDefault()}} className='mt-16 w-full bg-hex-blue text-white py-4 rounded-sm font-bold flex items-center justify-center'>Continue</button>
              </>): 
              (<>
              <h1 className='text-gray-800 text-lg'>
              Contact Details
           </h1>
           <p className='text-gray-500'>
           The details of the contact person
           </p>

           <div className='mt-8 grid grid-cols-2 gap-24'>
               <div>
                 <label className='font-bold'>First Name<span className='text-red-700'>*</span></label>
                 <input type={'text'} className='register-text-box' name='first_name' value={query.first_name} onChange={(e)=>handleInput(e)}/>
               </div>

               <div>
                 <label className='font-bold'>Last Name<span className='text-red-700'>*</span></label>
                 <input type={'text'} className='register-text-box' name='last_name' value={query.last_name} onChange={(e)=>handleInput(e)} />
               </div>
           </div>

           <div className='mt-8 grid grid-cols-2 gap-24'>
               <div>
                 <label className='font-bold'>Phone Number<span className='text-red-700'>*</span></label>
                 <input type={'text'} className='register-text-box' name='phone_number' value={query.phone_number} onChange={(e)=>handleInput(e)}/>
               </div>

               <div>
                 <label className='font-bold'>Email Address<span className='text-red-700'>*</span></label>
                 <input type={'text'} className='register-text-box' name='email' value={query.email} onChange={(e)=>handleInput(e)}/>
               </div>
           </div>

           <div className='mt-8 grid grid-cols-2 gap-24'>
               <div>
                 <label className='font-bold'>Address<span className='text-red-700'>*</span></label>
                 <input type={'text'} className='register-text-box' name='address' value={query.address} onChange={(e)=>handleInput(e)} />
               </div>

               <div>
                 <label className='font-bold'>Identity Type</label>
                 <input type={'text'} className='register-text-box' name='identity_type' value={query.identity_type} onChange={(e)=>handleInput(e)}/>
               </div>
           </div>

           <div className='mt-8 grid grid-cols-2 gap-24'>
               <div>
                 <label className='font-bold'>NIN Number</label>
                 <input type={'text'} className='register-text-box' name='identity_no' value={query.identity_no} onChange={(e)=>handleInput(e)} />
               </div>

               <div>
                 <label className='font-bold'>Upload Image</label>
                 <input type={'file'} className='file-btn' />
               </div>
           </div>
           {/* {disabled ? 'cursor-not-allowed opacity-70 bg-gray-300 mt-12 w-full text-white py-4 rounded-sm font-bold justify-center' :  */}
    
           <button type='submit' className='mt-12 w-full bg-hex-blue text-white py-4 rounded-sm font-bold flex items-center justify-center'>
                        Submit {loading &&  <Loader />}
                </button>
           </>)
              }
              </form>

            </div>
        </div>
    )
}

export default RegisterBusiness
