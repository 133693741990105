import { paymentDeliveryTypes } from "../types"


export function distance (state = {}, action) {
  switch (action.type) {
    case paymentDeliveryTypes.GET_PAYMENT_DELIVERY_REQUEST:
      return {
        ...state
      }
    case paymentDeliveryTypes.GET_PAYMENT_DELIVERY_SUCCESS:
      return {
        ...state,
        distance: action.distance
      }
    case paymentDeliveryTypes.GET_PAYMENT_DELIVERY_FAILURE:
      return {
        ...state,
        error: action.error
      }
      case paymentDeliveryTypes.PUT_PAYMENT_DELIVERY_REQUEST:
        return {
          ...state
        }
      case paymentDeliveryTypes.PUT_PAYMENT_DELIVERY_SUCCESS:
        return {
          ...state,
        }
      case paymentDeliveryTypes.PUT_PAYMENT_DELIVERY_FAILURE:
        return {
          ...state,
          error: action.error
        }
    default:
      return state
  }
}
