import React, { Fragment, useState,useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { profileActions } from '../../../../actions';
import Loader from '../../../../components/Loader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const PasswordSecurity = () => {
    const dispatch = useDispatch()
    const [loading,setLoading] = useState(false)
    const [show,showPassword] = useState(false)
    const [show2,showPassword2] = useState(false)
    const [show3,showPassword3] = useState(false)
    const [query, setQuery] = useState({
        current_password: '',
        password: '',
        password_confirmation: ''
      })

      const [error,setError] = useState({
        current_password: '',
        password: ''
      })

      const disabled = useMemo(
        () =>  !query.current_password || !query.password || !query.password_confirmation,
        [ query.current_password, query.password , query.password_confirmation , ]
        )

        const handleInput = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            setQuery((prevState) => ({
                ...prevState,
                [name]: value
            }));
            };

        const submit = async (e) => {
            e.preventDefault();
            setLoading(true) 
    
            const formdata = new FormData();
              Object.entries(query).forEach(([key, value]) => {
              formdata.append(key, value);
                });

            console.log(...formdata)
            
            const response =  await dispatch(profileActions.changePassword(formdata))
            console.log(response)
            if(response.status === 200) {
                setLoading(false) 
              toast.success(response?.data?.message,{
                autoClose: 2000,
                hideProgressBar: true})
            }
            else {
                toast.error(response?.data?.message,{
                  autoClose: 2000,
                  hideProgressBar: true })

                  setError(x=>({
                    ...x,
                    current_password: response?.data?.current_password,
                    password: response?.data?.password
                })) 
          
                  setLoading(false) 
                }
    
         }
    return (
        <Fragment>
            <form onSubmit={(e)=>submit(e)} className='mt-8'>
                
                <div className='flex flex-col'>
                    <label>Current Password</label>
                    <div className='mt-2 w-72 bg-white focus:outline-none h-12 px-4 flex flex-row items-center focus:outline-none'>                   
                            <input type={show ? 'text' : 'password'} className='flex-1 focus:outline-none' name='current_password' value={query.current_password} onChange={(e)=>handleInput(e)} />
                            <button type='button' className='focus:outline-none' onClick={()=>showPassword(!show)}>
                                {show ? <FontAwesomeIcon icon={faEyeSlash} color="#ADADAD" size="lg" /> : <FontAwesomeIcon icon={faEye} color="#ADADAD" size="lg" />}
                            </button>
                    </div>
              <div className='text-red-500 float-right text-xs mt-1'>{error?.current_password}</div>

                </div>

                <div className='mt-6 flex flex-col'>
                    <label>New Password</label>
                    <div className='mt-2 w-72 bg-white focus:outline-none h-12 px-4 flex flex-row items-center focus:outline-none'>                   
                            <input type={show2 ? 'text' : 'password'} className='flex-1 focus:outline-none' name='password' value={query.password} onChange={(e)=>handleInput(e)} />
                            <button type='button' className='focus:outline-none' onClick={()=>showPassword2(!show2)}>
                                {show2 ? <FontAwesomeIcon icon={faEyeSlash} color="#ADADAD" size="lg" /> : <FontAwesomeIcon icon={faEye} color="#ADADAD" size="lg" />}
                            </button>
                    </div>
              <div className='text-red-500 float-right text-xs mt-1'>{error?.password}</div>

                </div>

                <div className='mt-6 flex flex-col'>
                    <label>Confirm New Password</label>
                    <div className='mt-2 w-72 bg-white focus:outline-none h-12 px-4 flex flex-row items-center focus:outline-none'>                   
                            <input type={show3 ? 'text' : 'password'} className='flex-1 focus:outline-none' name='password_confirmation' value={query.password_confirmation} onChange={(e)=>handleInput(e)} />
                            <button type='button' className='focus:outline-none' onClick={()=>showPassword3(!show3)}>
                                {show3 ? <FontAwesomeIcon icon={faEyeSlash} color="#ADADAD" size="lg" /> : <FontAwesomeIcon icon={faEye} color="#ADADAD" size="lg" />}
                            </button>
                    </div>

                </div>


                <button type='submit' disabled={disabled} onClick={(e)=>submit(e)} className={!disabled ? 'w-auto py-4 px-8 mt-8 bg-hex-blue focus:outline-none border-none flex flex-row items-center justify-between text-white rounded-md text-sm' : 'w-auto py-4 px-8 mt-8 bg-gray-200 focus:outline-none border-none flex flex-row items-center justify-between text-black rounded-md text-sm'}>Save {loading && <Loader />}</button>

            </form>
        </Fragment>
    )
}

export default PasswordSecurity
