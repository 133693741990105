import { locationOptionsTypes, locationTypes } from "../types"


export function location (state = {}, action) {
  switch (action.type) {
    case locationTypes.GET_LOCATION_REQUEST:
      return {
        ...state
      }
    case locationTypes.GET_LOCATION_SUCCESS:
      return {
        ...state,
        location: action.location
      }
    case locationTypes.GET_LOCATION_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case locationTypes.ADD_LOCATION_REQUEST:
      return {
        ...state
      }
    case locationTypes.ADD_LOCATION_SUCCESS:
      return {
        ...state,
      }
    case locationTypes.ADD_LOCATION_FAILURE:
      return {
        ...state,
        error: action.error
      }
        case locationTypes.DELETE_LOCATION_REQUEST:
      return {
        ...state
      }
    case locationTypes.DELETE_LOCATION_SUCCESS:
      return {
        ...state,
      }
    case locationTypes.DELETE_LOCATION_FAILURE:
      return {
        ...state,
        error: action.error
      }
      
    default:
      return state
  }
}



export function locationOption (state = {}, action) {
  switch (action.type) {
    case locationOptionsTypes.GET_SINGLE_LOCATION_REQUEST:
      return {
        ...state
      }
    case locationOptionsTypes.GET_SINGLE_LOCATION_SUCCESS:
      return {
        ...state,
        locationOption: action.locationOption
      }
    case locationOptionsTypes.GET_SINGLE_LOCATION_FAILURE:
      return {
        ...state,
        error: action.error
      }
      
    default:
      return state
  }
}
