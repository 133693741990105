import axios from "axios"
import { config } from '../config'
import { authHeader } from "../helpers"

export const authService = {
    login,
    logout,
    sendPassword,
    updatePassword,
    addBusiness
}

async function login (formdata) {
    const requestOptions = {
        headers: {"Accept":"application/json"}
    }
    // console.log(config.testUrl);
    // console.log({config});
    const response = await axios.post(`${config.testUrl}/login`, formdata, requestOptions)
    const auth = response.data

    if(auth.status === true){
        sessionStorage.setItem('token', JSON.stringify(auth?.data?.token))
        sessionStorage.setItem('user',JSON.stringify(auth?.data))
        sessionStorage.setItem('isAuthenticated',true)
    }
    else {
        
    }

    return response;
}

// async function forgotPassword (formdata) {
//     const requestOptions = {
//         headers: {'Content-Type': 'application/json' }
//     }
//     const response = await axios.post(`${config.testUrl}/password/reset-request`, formdata ,requestOptions)
//     return response;
// }

async function sendPassword (formdata) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/password/reset-request`, formdata ,requestOptions)
    return response;
}

async function updatePassword (formdata) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/password/reset`, formdata ,requestOptions)
    return response;
}

async function addBusiness (formdata) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/add-business-details`, formdata ,requestOptions)
    return response;
}

async function logout () {
    
    sessionStorage.removeItem('user')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('isAuthenticated')
  
    console.log('Logged out Successfully');
  }