import React from 'react';
import { Table, Tag} from 'antd';
import DropDown from './DropDown'
import Mobilecard from './mobileCard';
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from '../../../actions'
import Emptyuser from './EmptyUser';
import Utils from '../../../helpers/utils';


const UsersTable = () => {
  
  const dispatch = useDispatch()
  const user = useSelector(state=>state.user)
  const userList = user?.usersList 
  console.log(userList)
  const filteredList = user?.filteredList
 
const handleTableChange = (pagination) =>{
  dispatch(userActions.getUser(pagination))
}
const dataSource = (filteredList?.status === 'success' && filteredList?.data) || userList?.data
const pagination = ( filteredList?.status === 'success' && filteredList?.pagination) || userList?.pagination
    const columns = [
      {
        title: 'S/N',
        dataIndex: 'id',
        key: 'id',
        render:(item, record, index)=>{
          return <p>{Utils.getSerialNumber(pagination.current, index, pagination.pageSize)}</p>
        }
      },
      {
        title: 'Name',
        dataIndex: 'full_name',
        key: 'full_name',
        render:(full_name)=>{
          return <p className='capitalize'>{full_name || "-"}</p>
        }
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        render:(location)=>{
          return <p className='capitalize'>{location || "-"}</p>
        }
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: status => {
            let color = status === "active" ? '#7AAC52' : status === "inactive" ?  '#E3000F' : "#ffc107";
             return(
                <Tag color={color} className="userTags capitalize">
                {status}
              </Tag>
             )
        },
      },
      {
        title: 'Phone Number',
        dataIndex: 'phone',
        key: 'phone',
        render:(phone)=>{
          return <p>{phone || "-"}</p>
        }
      },
      {
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email',
        render:(email)=>{
          return <p>{email || "-"}</p>
        }
      },
      {
        title: 'Action',
        key: 'row',
        render: (row) => {
          return( <DropDown data={row}/>)
        },
      },
    ];
    return (
    <>
    {userList?.data?.length > 0 ?
    <>
    <div className="d-sm-none">
      <Table dataSource={dataSource} columns={columns} className="userTable" 
      pagination={ filteredList?.pagination || userList?.pagination} onChange={handleTableChange}/>
      </div>
      <div className="d-lg-none">
      <Mobilecard user={userList}/>
      </div>
      </>
      : 
      <Emptyuser/>
    }
      </>
    );
}
    export default UsersTable;
