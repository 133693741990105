import { mappingsService } from "../services"
import { mappingsTypes } from "../types"

export const mappingsActions = {
    getMappings,
    addMappings,
    putMappings,
    deleteMappings
}

function getMappings (value) {
    return async dispatch => {
      dispatch(request(value))
  
      try {
        const mappings = await mappingsService.getMappings(value)
        dispatch(success(mappings))
        return mappings

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (value) { return { type: mappingsTypes.GET_MAPPINGS_REQUEST ,value} }
    function success (mappings) { return { type: mappingsTypes.GET_MAPPINGS_SUCCESS, mappings } }
    function failure (error) { return { type: mappingsTypes.GET_MAPPINGS_FAILURE, error } }
    
  }

  function addMappings (value) {
    return async dispatch => {
      dispatch(request(value))
  
      try {
        const mappings = await mappingsService.addMappings(value)
        dispatch(success(mappings))
        return mappings

      } catch (error) {
        dispatch(failure(error.toString(value)))
        return error?.response
      }

    }
  
    function request (value) { return { type: mappingsTypes.ADD_MAPPINGS_REQUEST,value } }
    function success (mappings) { return { type: mappingsTypes.ADD_MAPPINGS_SUCCESS, mappings } }
    function failure (error) { return { type: mappingsTypes.ADD_MAPPINGS_FAILURE, error } }
    
  }

  function putMappings (id,formdata) {
    return async dispatch => {
      dispatch(request(id,formdata))
  
      try {
        const mappings = await mappingsService.putMappings(id,formdata)
        dispatch(success(mappings))
        return mappings

      } catch (error) {
        dispatch(failure(error.toString(id)))
        return error?.response
      }

    }
  
    function request (id,formdata) { return { type: mappingsTypes.UPDATE_MAPPINGS_REQUEST,id,formdata } }
    function success (mappings) { return { type: mappingsTypes.UPDATE_MAPPINGS_SUCCESS, mappings } }
    function failure (error) { return { type: mappingsTypes.UPDATE_MAPPINGS_FAILURE, error } }
    
  }

  function deleteMappings (id) {
    return async dispatch => {
      dispatch(request(id))
  
      try {
        const mappings = await mappingsService.deleteMappings(id)
        dispatch(success(mappings))
        return mappings

      } catch (error) {
        dispatch(failure(error.toString(id)))
        return error?.response
      }

    }
  
    function request (id) { return { type: mappingsTypes.DELETE_MAPPINGS_REQUEST,id } }
    function success (mappings) { return { type: mappingsTypes.DELETE_MAPPINGS_SUCCESS, mappings } }
    function failure (error) { return { type: mappingsTypes.DELETE_MAPPINGS_FAILURE, error } }
    
}
