import axios from "axios"
import { config } from '../config'
import { authHeader } from "../helpers"

export const profileService = {
    getUserProfile,
    postUserProfile,
    updateNotification,
    changePassword,
    updateBusiness,
    getBusiness
}

async function getUserProfile () {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/profile`, requestOptions)
    return response;
}

async function postUserProfile (formdata) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/profile`,formdata,requestOptions)
    return response;
}

async function updateNotification (formdata) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/notification`,formdata,requestOptions)
    return response;
}

async function changePassword (formdata) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/change-password`,formdata,requestOptions)
    return response;
}

async function updateBusiness (id,formdata) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.post(`${config.testUrl}/update-business-details/${id}`,formdata,requestOptions)
    return response;
}

async function getBusiness () {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/get-business-details`,requestOptions)
    return response;
}
