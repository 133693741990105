import { locationService } from "../services"
import { locationOptionsTypes, locationTypes } from "../types"

export const locationActions = {
  getLocations,
  addLocationToCategory,
  deleteLocation
}

export const locationOptionActions = {
    getSingleLocation
}

function getLocations (category_id) {
    return async dispatch => {
      dispatch(request(category_id))
  
      try {
        const location = await locationService.getLocations(category_id)
        dispatch(success(location))
        return location

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (category_id) { return { type: locationTypes.GET_LOCATION_REQUEST ,category_id} }
    function success (location) { return { type: locationTypes.GET_LOCATION_SUCCESS, location } }
    function failure (error) { return { type: locationTypes.GET_LOCATION_FAILURE, error } }
    
  }

  function addLocationToCategory (value,query) {
    return async dispatch => {
      dispatch(request(value,query))
  
      try {
        const location = await locationService.addLocationToCategory(value,query)
        dispatch(success(location))
        return location

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (value,query) { return { type: locationTypes.ADD_LOCATION_REQUEST,value,query } }
    function success (location) { return { type: locationTypes.ADD_LOCATION_SUCCESS, location } }
    function failure (error) { return { type: locationTypes.ADD_LOCATION_FAILURE, error } }
    
  }

  function deleteLocation (category_id,location_id) {
    return async dispatch => {
      dispatch(request(category_id,location_id))
  
      try {
        const location = await locationService.deleteLocation(category_id,location_id)
        dispatch(success(location))
        return location

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (category_id,location_id) { return { type: locationTypes.DELETE_LOCATION_REQUEST,category_id,location_id } }
    function success (location) { return { type: locationTypes.DELETE_LOCATION_SUCCESS, location } }
    function failure (error) { return { type: locationTypes.DELETE_LOCATION_FAILURE, error } }
    
}

function getSingleLocation (category_id,location_id) {
    return async dispatch => {
      dispatch(request(category_id,location_id))
  
      try {
        const location = await locationService.getSingleLocation(category_id,location_id)
        dispatch(success(location))
        return location

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (category_id,location_id) { return { type: locationOptionsTypes.GET_SINGLE_LOCATION_REQUEST ,category_id,location_id} }
    function success (location) { return { type: locationOptionsTypes.GET_SINGLE_LOCATION_SUCCESS, location } }
    function failure (error) { return { type: locationOptionsTypes.GET_SINGLE_LOCATION_FAILURE, error } }
    
  }
