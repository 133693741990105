import React from 'react';
import { Progress } from 'antd';

const ProgressBar = (props) => {
  const {sender_info, status} = props.data
    return (
        <div className="card">
         <div className="pb4">
            {/* <b> {trackingDetails.delivery_time.name} - </b> */}
            <b> Express - </b>
            <span className="grytxt">Your package with tracking ID:</span> <b>
                 {/* {order.tracking_id} */}
                {props.trackingId}
                 </b>
          <span className="grytxt">  to </span> 
          <span className="grytxt">
              {/* {order.receiver_info.receiver_name}  */}
              {`${sender_info.sender_name} `} 
              is 
              </span>
            <span style={{ color: "#65E151", textTransform: "capitalize" }}> {status}</span>
          </div>
      <div className="prg pad">
        <Progress percent={20} showInfo={false} trailColor="#ffffffc2" />
      </div>
      </div>
    );
}

export default ProgressBar;
