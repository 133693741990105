import axios from "axios"
import { config } from '../config'
import { authHeader } from "../helpers"

export const settingsService = {
    getPaymentOptions,
    updateMultipleStatus,
    getRiderSettings,
    updateRiderSettings
}

export const brandColorService = {
    getBrandColor,
    putBrandColor
}

async function getPaymentOptions () {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/payment-options`, requestOptions)
    return response;
}

async function updateMultipleStatus (payment_options) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.put(`${config.testUrl}/settings/payment-options/status`, {payment_options} ,requestOptions)
    return response;
}

async function getRiderSettings () {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/riders` ,requestOptions)
    return response;
}

async function updateRiderSettings (body) {
    console.log(body)
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.put(`${config.testUrl}/settings/riders`, body ,requestOptions)
    return response;
}


async function getBrandColor () {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios(`${config.testUrl}/settings/brand-color`, requestOptions)
    return response;
}

async function putBrandColor (formdata) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const response = await axios.put(`${config.testUrl}/settings/brand-color`,formdata, requestOptions)
    return response;
}