import React,{Fragment} from 'react'
import LoginImage from '../loginImage'
import { Link,useParams } from 'react-router-dom'


const CheckEmail = () => {
    let {email} = useParams()
    return (
        <Fragment>
            <div className='flex flex-row'>
                <div className='background hidden md:flex md:flex-col md:w-1/2 h-screen px-12 lg:px-32'>


                <LoginImage />

                </div>
        
            <section className='w-full md:w-1/2'>


        <div className='min-w-full px-8 sm:px-12 xl:px-32 py-12 xl:py-20 h-screen'>
            
            <div>
                <img src={`${process.env.REACT_APP_IMAGE}/healthLogo.png`} alt='' className='w-12 lg:w-20' />
            </div>


            <div className='mt-32 flex justify-center'>
                <img src={`${process.env.REACT_APP_IMAGE}/paper+plane.png`} alt='' className='w-28' />
            </div>


            <h1 className='mt-20 text-xl lg:text-3xl font-bold text-center'>
            Password Reset Instruction Sent
            </h1>

            <p className='text-gray-400 text-center text-md'>
                We have sent a password recovery instructions to your email
            </p>

                <Link to={`/update-password/${email}`}>
                <button type='submit' style={{backgroundColor:'#006FC1'}} className='mt-16 w-full text-white py-4 rounded-sm font-bold'>
                    I have checked my mail box
                </button>
                </Link> 

             {/* <h1 className='mt-12 text-xl lg:text-3xl font-bold'>
                 Recover Password
            </h1>

            <p className='text-gray-400'>
                    Fill the details below to recover your password 
            </p>


            <form className='mt-12'>

                <div>
                     <input type='text' placeholder = 'Email Address' className='login-text-box' />
                </div>
                    

                <button type='submit' className='mt-56 w-full bg-gray-300 text-white py-4 rounded-sm font-bold'>
                    Send Intructions
                </button>

            </form>  */}



        </div>

        </section>
            </div>
        </Fragment>
    )
}

export default CheckEmail