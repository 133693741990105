import React, { useEffect,useState,useRef } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard'
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faChevronDown,
  // faBell,
  // faCaretUp,
  faClipboardList,
  faCopy,
  // faCog,
  faHome,
  faMapMarkerAlt,
  faThLarge,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
// import { ReactComponent as Logo } from "../../assets/prihmex-logo2.svg";
// import Calendar from "react-calendar";
// import OverView from "../OverView/OverView";
import { Link, NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import Users from "../users/users";
import Wallet from "../wallet/wallet";
import Tracking from "../tracking/tracking";
import Settings from "../settings/settings";
import Bikes from "../bikes/bikes";
import Orders from "../orders/orders";
import Home from "../home/home";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Pie } from 'react-chartjs-2'
import { DeliverySummaryActions } from "../../actions/delivery.actions";
import { assignOrderActions, orderDashboardActions } from "../../actions";
import { Modal } from "antd";
// eslint-disable-next-line
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../components/Loader";

const Dashboard = () => {

  const dispatch = useDispatch()
  const textRef = useRef(null)
  const user = JSON.parse(sessionStorage.getItem('user'))
  const summary = useSelector(state=>state.summary)
  const order = useSelector(state=>state.order)
  const assignOrders = useSelector(state=>state.assignOrders)
  const [activeOrder,setActiveOrder] = useState('') 
  const [activeSearch,setActiveSearch] = useState('')
  const [copied, setCopied] = useState(false)
  // const [tracking, setTracking] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [loading,setLoading] = useState({
    id: ''
  })

  const showModal = (e,id) => {
    setIsModalVisible(true);

  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setActiveSearch('')
    setIsModalVisible(false);
  };

  const showModal2 = () => {
    setIsModalVisible2(true);
  };

  const handleOk2 = () => {
    setIsModalVisible2(false);
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
    setCopied(false)
  };

  const [graph, setGraph] = useState({
    labels: [],
    data: [],
  })

  let summaryD = summary?.summary?.data

  

  useEffect(() => {
   dispatch(DeliverySummaryActions.getDeliverySummary())
  }, [dispatch])

  useEffect(() => {
    dispatch(orderDashboardActions.getPendingOrdersDashboard(5,1))
   }, [dispatch])

   useEffect(() => {
    dispatch(assignOrderActions.getNearbyRiders(activeOrder?.id,activeSearch))
   }, [dispatch,activeOrder?.id,activeSearch])


   let orderList = order?.order?.data?.data
   let riderList = assignOrders?.assignOrders?.data
   console.log(activeSearch)



     useEffect(() => {
      let day = []
      let values = []

      summaryD?.stat?.forEach(element => {
        day.push(element.day)
        values.push(element.value)
      })

      setGraph({
        labels: day,
        data: values,
      });

     }, [summaryD?.stat])


  const data = {
    labels: graph.labels,
    datasets: [
      {
        label: '# of Votes',
        data: graph.data,
        backgroundColor: [
          '#94d120',
          '#94d1fb',
          '#5eb5cb',
          '#526e58',
          '#8bc2cd',
          '#a1e3ea',
          '#526f8e',
        ],
        borderColor: [
          '#94d120',
          '#94d1fb',
          '#5eb5cb',
          '#526e58',
          '#8bc2cd',
          '#a1e3ea',
          '#526f8e',
        ],
        borderWidth: 1,
      }
    ]
  }

  const config = {
    type: 'line',
    data: data,
    maintainAspectRatio:false,
    responsive: true,
    plugins:{
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 10,
          boxHeight: 10
        }
      },
    },
    };

    const matchOrder = async (e,id) => {
      e.preventDefault()

      const formdata = ({
        order_id: activeOrder?.id,
        rider_id: id
      })

      const response = await dispatch(assignOrderActions.assignOrders(formdata))
      setLoading({id:''})
      if(response?.data?.message === 'Action successful'){
        handleCancel()
        setTimeout(() => {
          showModal2()
        }, 500);

        toast.success('Action successful',{
          autoClose: 2000,
          hideProgressBar: true
      })
      }
      else{
        console.log(response)

        toast.error(response?.data?.message,{
          autoClose: 2000,
          hideProgressBar: true
      })
      }
    } 


  const NavIcon = ({ icon, text, link }) => {
    return (
      <NavLink to={link} className="icon-item">
        <div className="icon-badge active">
          <FontAwesomeIcon icon={icon} size="1x" />
        </div>
        <p className="mt-10 text-xs text-gray-500">{text}</p>
      </NavLink>
    );
  };
  let { path, url } = useRouteMatch();



  return (
    <div id="dashboard">
      <div className="left-bar">
        
        <nav>
          <img src={`${process.env.REACT_APP_IMAGE}/healthLogo.png`} alt="" />
          <NavIcon link={`${url}`} icon={faHome} text="Dashboard"/>
          <NavIcon link={`${url}/wallet`} icon={faThLarge} text="Wallet" />
          <NavIcon link={`${url}/users`} icon={faUsers} text="Users" />
          <NavIcon link={`${url}/tracking`} icon={faMapMarkerAlt} text="Tracking" />
          <NavIcon link={`${url}/setting/customize-application`} icon={faThLarge} text="Settings" />
          <NavIcon link={`${url}/bikes`} icon={faClipboardList} text="Bikes" />
          <NavIcon link={`${url}/orders`} icon={faMapMarkerAlt} text="Orders" />
        </nav>
      </div>

      <div className="middle-bar p-0 lg:p-0">
        <Switch>
          <Route path={`${path}/wallet`}>
            <Wallet />
          </Route>
          <Route path={`${path}/users`}>
            <Users />
          </Route>
          <Route path={`${path}/tracking`}>
            <Tracking />
          </Route>
          <Route path={`${path}/setting`}>
            <Settings />
          </Route>
          <Route path={`${path}/bikes`}>
            <Bikes />
          </Route>
          <Route path={`${path}/orders`}>
            <Orders />
          </Route>
          <Route exact path={path}>
            <Home />
          </Route>
        </Switch>
      </div>
      <div className="right-bar">

          <img
          src={`${process.env.REACT_APP_IMAGE}/avatar.png`}
          className='pic-avatar'
          alt=''
          />
          <div className="text-center mb-50 flex flex-col">
            <b className="mt-10 capitalize">{user?.full_name}</b>
            <small className="capitalize">{user?.role?.name?.replace('-',' ')}</small>
          </div>


          <div className="mt-8 flex flex-row justify-between items-center">
              <b>RIDERS DELIVERY SUMMARY</b>
              <a href='#!' className="text-gray-500 hover:text-gray-500 underline text-xs">View All</a>
          </div>

         {summaryD?.total === 0 ? (
          <div>
                <img src={`${process.env.REACT_APP_IMAGE}/box2.png`} alt='' className="w-24 mx-auto mt-8" />
                <p className="text-center mt-4">No Active Delivery</p>
          </div>) : (
          <div className='w-full flex flex-col justify-between'>
              <div className="w-auto mt-2">
                <Pie
                    data={data} options={config}
                  />
              </div>
              <div>
            </div>
          </div>)}
          <div className="mt-8 flex flex-row justify-between items-center">
              <b>RECENT REQUEST</b>
              <Link to='/merchant-admin/orders' className="text-gray-500 hover:text-gray-500 underline text-xs">View All</Link>
          </div>

         {orderList?.length > 0 ? (<div>
           {orderList?.map((x,i)=>(
            <div key={i} className='mt-6 w-full flex flex-row items-center'>
              <div className='h-8 w-8 rounded-full flex justify-center items-center' style={{backgroundColor:'#e4f1f9'}}>
                <img src={`${process.env.REACT_APP_IMAGE}/side-arrow.png`} className='w-4' alt='' />
              </div>
              <div className='flex flex-col ml-4'>
                    <span className='font-bold text-gray-500'>{x?.sender_info?.sender_name}</span>
                    <span className='text-xs text-gray-400'>{x?.created_at?.split('T')[0]} {'    '} {x?.created_at?.split('T')[1]?.slice(0,8)}</span>
              </div>

              <button onClick={(e)=>{setActiveOrder(x);showModal(e,x?.id)}} className='hover:border-gray-300 hover:text-gray-300 ml-auto text-xs focus:outline-none border border-gray-400 px-3 py-1 rounded-sm'>Accept</button>
              {/* <div></div> */}
                
            </div>
          ))}
        </div>) : 
        (<div>
              <img src={`${process.env.REACT_APP_IMAGE}/folder2.png`} alt='' className="w-24 mx-auto mt-8" />
              <p className="text-center mt-4">No Active Request</p>
          </div>
        )}


         
        
      </div>
      
      <Modal title="" closable={false} footer={null} width={1000} centered={true} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
      <h1 className='text-md font-bold uppercase px-4'>Recent Request</h1>

        <div className='w-full h-full p-2 flex flex-row'>

          <div className='mx-2'>
            
            <div className='mt-0 shadow-sm border border-gray-200 w-80 p-4 rounded-md flex flex-col'>
              <img src={`${process.env.REACT_APP_IMAGE}/figure2.png`} alt='' className='w-12' />
              <h1 className='mt-2'>Sender Profile</h1>
              <span className='text-gray-400'>{activeOrder?.sender_info?.sender_name}</span>
              <span className='text-gray-400'>{activeOrder?.sender_info?.sender_phone_number}</span>

              <h1 className='mt-2 text-xs'>{activeOrder?.sender_info?.pickup_formatted_address}</h1>

            </div>

            <div className='mt-4 shadow-sm border border-gray-200 w-80 p-4 rounded-md flex flex-col'>
              <img src={`${process.env.REACT_APP_IMAGE}/figure.png`} alt='' className='w-12' />
              <h1 className='mt-2'>Receiver Profile</h1>
              <span className='text-gray-400'>{activeOrder?.receiver_info?.receiver_name}</span>
              <span className='text-gray-400'>{activeOrder?.receiver_info?.receiver_phone_number}</span>

              <h1 className='mt-2 text-xs'>{activeOrder?.receiver_info?.destination_formatted_address}</h1>

            </div>

            <div className='mt-4 shadow-sm border border-gray-200 w-80 p-4 rounded-md flex flex-col'>
              <h1 className='mt-2'>Package Info</h1>
              <span className='text-gray-400'>{activeOrder?.delivery_time?.name}</span>
              <span className='text-gray-400'>Description: {activeOrder?.item_description}</span>
              <span className='text-gray-400'>{activeOrder?.delivery_time?.description}</span>
            </div>

            <div className='mt-4 shadow-sm border border-gray-200 w-80 p-2 rounded-md'>
              <h1 className='mt-2'>Pickup Time: {activeOrder?.pickup_type}</h1>
            </div>
          </div>

          <div className='flex-1 mx-2'>
              <div className='w-full border border-gray-200 rounded-md'>
                  
                  <div className='w-full flex flex-row p-2 justify-between border-b border-gray-200'>
                     <h1>Select a rider to match the request</h1>
                     <span>
                         <FontAwesomeIcon icon={faChevronDown} size="1x" />
                     </span>
                  </div>


                  <div className='p-1'>
                    <input type='text' name='search' value={activeSearch} onChange={(e)=>setActiveSearch(e.target.value)} className='w-full focus:outline-none border border-gray-200 p-2 rounded-sm' />
                  </div>

                  <div className='mt-8 p-4'>
                        { riderList?.length > 0 ? (<div>{riderList?.map((x,i)=> (
                          <div key={i} className='mt-2 w-full flex flex-row items-center bg-white hover:bg-gray-50 rounded-md'>
                              {x?.profile_image_url === null ? (<div className='h-12 w-12 rounded-md bg-gray-200'></div>) : (<img src={x?.profile_image_url} alt=''  className='h-12 w-12 rounded-md ' />)}

                              <span className='font-bold text-gray-600 ml-2'>{x?.full_name}</span>

                              <button  onClick={(e)=>{matchOrder(e,x?.rider_id);setLoading({id:x?.rider_id})}} className='px-2 py-2 bg-blue-100 text-blue-800 font-bold rounded-md ml-auto flex flex-row justify-between'>Match {loading?.id === x?.rider_id && <Loader />}</button>
                          </div>
                        ))}</div>) : (<div>{riderList?.data?.map((x,i)=> (
                          <div key={i} className='mt-2 w-full flex flex-row items-center bg-white hover:bg-gray-50 rounded-md'>
                              {x?.user?.profile_image === null ? (<div className='h-12 w-12 rounded-md bg-gray-200'></div>) : (<img src={x?.user?.profile_image} alt=''  className='h-12 w-12 rounded-md ' />)}

                              <span className='font-bold text-gray-600 ml-2'>{x?.user?.name}</span>

                              <button onClick={(e)=>{matchOrder(e,x?.id);setLoading({id:x?.id})}} className='px-2 py-2 bg-blue-100 text-blue-800 font-bold rounded-md ml-auto flex flex-row justify-between'>Match {loading?.id === x?.id && <Loader />}</button>
                          </div>
                        ))}</div>)}
                  </div>

                  {/* <div>kokoko</div> */}
              </div>
          </div>

        </div>
      </Modal>
      <Modal title="" closable={false} footer={null} width={500} centered={true} visible={isModalVisible2} onOk={handleOk2} onCancel={handleCancel2}>
                          <div className='w-full flex flex-col items-center py-8'>
                            <FontAwesomeIcon icon={faCheckCircle} color='#52ad86' size='6x' />

                            <span className='font-bold mt-4'>Request Created</span>

                            <p className='font-bold text-gray-400 mt-4'>Your request has been created successfully!!!</p>

                            <div className='bg-gray-100 flex flex-col items-center w-1/2 p-2'>
                              <span className='text-gray-400 font-bold'>Tracking code</span>
                              <span className='text-gray-700 font-bold' ref={textRef}>{activeOrder?.tracking_id}</span>
                            </div>

                            {!copied ? 
                            (<CopyToClipboard text={activeOrder?.tracking_id} onCopy={() => setCopied(true)}>
                            <div className='flex flex-row items-center mt-4 p-2 hover:bg-gray-100 rounded-md'>
                                <FontAwesomeIcon icon={faCopy} color='#006fc1' size='' />
                                <span className='ml-2'>Copy</span>
                            </div>
                            </CopyToClipboard>): (<div className='flex flex-row items-center mt-4 p-2 hover:bg-gray-100 rounded-md'>
                                <FontAwesomeIcon icon={faCopy} color='#006fc1' size='' />
                                <span className='ml-2'>Copied!!!</span>
                            </div>)}

                            <button type='button' onClick={()=>handleCancel2()}  className='mt-12 w-auto bg-hex-blue text-white py-3 px-6 rounded-sm font-bold flex items-center justify-center'>
                                    Close
                                </button>
                          </div>
      </Modal>
    </div>
  );
}



export default Dashboard