export const profileTypes = {
   
    GET_USER_PROFILE_REQUEST: 'GET_USER_PROFILE_REQUEST',
    GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
    GET_USER_PROFILE_FAILURE: 'GET_USER_PROFILE_FAILURE',

    POST_USER_PROFILE_REQUEST: 'POST_USER_PROFILE_REQUEST',
    POST_USER_PROFILE_SUCCESS: 'POST_USER_PROFILE_SUCCESS',
    POST_USER_PROFILE_FAILURE: 'POST_USER_PROFILE_FAILURE',
    

    UPDATE_NOTIFICATION_REQUEST: 'UPDATE_NOTIFICATION_REQUEST',
    UPDATE_NOTIFICATION_SUCCESS: 'UPDATE_NOTIFICATION_SUCCESS',
    UPDATE_NOTIFICATION_FAILURE: 'UPDATE_NOTIFICATION_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

    UPDATE_BUSINESS_REQUEST: 'UPDATE_BUSINESS_REQUEST',
    UPDATE_BUSINESS_SUCCESS: 'UPDATE_BUSINESS_SUCCESS',
    UPDATE_BUSINESS_FAILURE: 'UPDATE_BUSINESS_FAILURE',

    GET_BUSINESS_REQUEST: 'GET_BUSINESS_REQUEST',
    GET_BUSINESS_SUCCESS: 'GET_BUSINESS_SUCCESS',
    GET_BUSINESS_FAILURE: 'GET_BUSINESS_FAILURE',
  
}