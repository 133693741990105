import { profileTypes } from "../types"
import { profileService } from "../services"

export const profileActions = {
    getUserProfile,
    postUserProfile,
    changePassword,
    updateBusiness,
    updateNotification,
    getBusiness
}

function getUserProfile () {
    return async dispatch => {
      dispatch(request())
  
      try {
        const profile = await profileService.getUserProfile()
        dispatch(success(profile))
        return profile

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request () { return { type: profileTypes.GET_USER_PROFILE_REQUEST } }
    function success (profile) { return { type: profileTypes.GET_USER_PROFILE_SUCCESS, profile } }
    function failure (error) { return { type: profileTypes.GET_USER_PROFILE_FAILURE, error } }
    
  }

  function postUserProfile (formdata) {
    return async dispatch => {
      dispatch(request(formdata))
  
      try {
        const profile = await profileService.postUserProfile(formdata)
        dispatch(success(profile))
        return profile

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (formdata) { return { type: profileTypes.POST_USER_PROFILE_REQUEST,formdata } }
    function success (profile) { return { type: profileTypes.POST_USER_PROFILE_SUCCESS, profile } }
    function failure (error) { return { type: profileTypes.POST_USER_PROFILE_FAILURE, error } }
    
  }


  function updateNotification (formdata) {
    return async dispatch => {
      dispatch(request(formdata))
  
      try {
        const profile = await profileService.updateNotification()
        dispatch(success(profile))
        return profile

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (formdata) { return { type: profileTypes.UPDATE_NOTIFICATION_REQUEST,formdata } }
    function success (profile) { return { type: profileTypes.UPDATE_NOTIFICATION_SUCCESS, profile } }
    function failure (error) { return { type: profileTypes.UPDATE_NOTIFICATION_FAILURE, error } }
    
  }


  function changePassword (formdata) {
    return async dispatch => {
      dispatch(request(formdata))
  
      try {
        const profile = await profileService.changePassword(formdata)
        dispatch(success(profile))
        return profile

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (formdata) { return { type: profileTypes.CHANGE_PASSWORD_REQUEST,formdata } }
    function success (profile) { return { type: profileTypes.CHANGE_PASSWORD_SUCCESS, profile } }
    function failure (error) { return { type: profileTypes.CHANGE_PASSWORD_FAILURE, error } }
    
  }


  function updateBusiness (id,formdata) {
    return async dispatch => {
      dispatch(request(id,formdata))
  
      try {
        const profile = await profileService.updateBusiness(id,formdata)
        dispatch(success(profile))
        return profile

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (id,formdata) { return { type: profileTypes.UPDATE_BUSINESS_REQUEST,id,formdata } }
    function success (profile) { return { type: profileTypes.UPDATE_BUSINESS_SUCCESS, profile } }
    function failure (error) { return { type: profileTypes.UPDATE_BUSINESS_FAILURE, error } }
    
  }

  function getBusiness () {
    return async dispatch => {
      dispatch(request())
  
      try {
        const profile = await profileService.getBusiness()
        dispatch(success(profile))
        return profile

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request () { return { type: profileTypes.GET_BUSINESS_REQUEST } }
    function success (profile) { return { type: profileTypes.GET_BUSINESS_SUCCESS, profile } }
    function failure (error) { return { type: profileTypes.GET_BUSINESS_FAILURE, error } }
    
  }
