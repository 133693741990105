import { authTypes } from "../types";

export function auth (state = {isAuthenticated:null}, action ) {
    switch(action.type) {
        case authTypes.LOGIN_REQUEST:
            return {
                loading: true
            }
        case authTypes.LOGIN_SUCCESS:
            return {
                auth: action.auth,
                isAuthenticated: true
            }
        case authTypes.LOGIN_ERROR:
            return {
                error: action.error
            }
        case authTypes.LOGOUT:
            return {
                isAuthenticated: false
            }
        case authTypes.SEND_PASSWORD_REQUEST:
        return {
            ...state
        }
        case authTypes.SEND_PASSWORD_SUCCESS:
        return {
            ...state,
            auth: action.auth
        }
        case authTypes.SEND_PASSWORD_FAILURE:
        return {
            ...state,
            error: action.error
        }
        case authTypes.UPDATE_PASSWORD_REQUEST:
        return {
            ...state
        }
        case authTypes.UPDATE_PASSWORD_SUCCESS:
        return {
            ...state,
            auth: action.auth
        }
        case authTypes.UPDATE_PASSWORD_FAILURE:
        return {
            ...state,
            error: action.error
        }
        case authTypes.ADD_BUSINESS_REQUEST:
        return {
            ...state
        }
        case authTypes.ADD_BUSINESS_SUCCESS:
        return {
            ...state,
            auth: action.auth
        }
        case authTypes.ADD_BUSINESS_FAILURE:
        return {
            ...state,
            error: action.error
        }
        default:
            return state
    }
}