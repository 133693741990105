import { Modal, Table } from 'antd'
import React, { Fragment, useEffect ,useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { categoryActions, locationActions } from '../../../../../../actions'
import Loader from '../../../../../../components/Loader'
import { columns2 } from '../tabledata'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { authHeader } from '../../../../../../helpers';
import { BASE_URL } from '../../../../../../constants'

const InterLocation = (props) => {
// eslint-disable-next-line
    const {interCategory,InterLocation} = props
    const dispatch = useDispatch()
// eslint-disable-next-line
    const location = useSelector(state=>state.location)
    const [active,setActive] = useState(interCategory[0]?.id)
    const [loading,setLoading] = useState(false)
    const [states, setStates] = useState(null)
    const [activeId, setActiveId] = useState(null)
    const [ID, setID] = useState(null)


    useEffect(() => {
        const fetchRoles = async () => {
        const response = await axios.get(`${BASE_URL}/settings/countries/1157/states`,{
            headers:{
                ...authHeader()
            }
        })
        const stateList = await response.data
        setStates(stateList)
        }
        fetchRoles()
}, [])

let locationList = location?.location?.data
console.log(locationList)

const handleClick = () => {
    setActiveId(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const showModal2 = () => {
      setIsModalVisible2(true);
  };

  const handleCancel2 = () => {
      setIsModalVisible2(false);
  };


    

    const [query, setQuery] = useState({
        category_id: '',
        name:'',
        type: 'inter'
      })

    

      useEffect(() => {
       dispatch(locationActions.getLocations(active))
    }, [dispatch,active])

    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setQuery((prevState) => ({
            ...prevState,
            [name]: value
        }));
        };

       
// eslint-disable-next-line
        const handleChange = (e) => {
            const name = e.target.name;
            const value = parseInt(e.target.value);
            setActive(value)
            setQuery((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }

        const submit = async (e) => {
            e.preventDefault();
            setLoading(true) 

            console.log(active,query)
    
            const response =  await dispatch(locationActions.addLocationToCategory(active,query))
            if(response.status === 200) {
                setLoading(false) 
                // setStates('')
                setQuery({
                    category_id: '',
                    name: '',
                    type:'inter'
                })
              toast.success(response?.data?.message,{
                autoClose: 2000,
                hideProgressBar: true})
            }
            else {
                toast.error(response?.data?.message,{
                  autoClose: 2000,
                  hideProgressBar: true })
                  setLoading(false) 
                }
           dispatch(categoryActions.getCategories('inter'))
           dispatch(locationActions.getLocations(active))
         }

         

         const deleteCategory = async (e,id) => {
            e.preventDefault();
            setLoading(true) 
    
            const response =  await dispatch(locationActions.deleteLocation(active,id))
    
            if(response.status === 200) {
                setLoading(false) 
                handleCancel2()
              toast.success(response?.data?.message,{
                autoClose: 2000,
                hideProgressBar: true})
            }
            else {
                toast.error(response?.data?.message,{
                  autoClose: 2000,
                  hideProgressBar: true })
                  setLoading(false) 
                }
                dispatch(categoryActions.getCategories('inter'))
                dispatch(locationActions.getLocations(active))
         }

    const data = locationList !== '' ?  locationList?.map((item,i) => ({
        id: item.id,
        key: item?.id,
        SN: ++i,
        Locations: item?.name,
    })) : [] 
    
    return (
        <Fragment>
        <Modal title={null} closable={true} centered={true} onCancel={handleCancel2} visible={isModalVisible2} footer={null}>
                        <div className='text-center text-md'>Are you sure you want to delete this location?</div>
                        <div className='mt-8 flex flex-row justify-center items-center'>
                            <button onClick={handleCancel2} className='border-none bg-gray-300 text-white rounded-sm mx-4 p-2 px-4'>Cancel</button>
                            <button onClick={(e)=>deleteCategory(e,ID)} className='border-none bg-hex-blue text-white rounded-sm mx-4 p-2 px-4 flex items-center justify-center'>
                                        Submit {loading &&  <Loader />}</button>
                        </div>
        </Modal>
            <form onSubmit={(e)=>submit(e)} className='grid grid-cols-3 gap-8 items-end'>
                        <div>
                            <label className='font-bold text-gray-500'>Locations</label>
                            {/* <input type={'text'} className='register-text-box' name='name' value={query.name} onChange={(e)=>handleInput(e)}  /> */}
                            <select className='regular-form' name='state_id' value={query.state_id} onChange={(e)=>handleInput(e)}>
                                    <option value=''>*Select State</option>
                                    {states?.map((x)=>(
                                        <option key={x.id} value={x.id}>{x.name}</option>
                                    ))}
                                </select>
                        </div>

                        <div>
                            <label className='font-bold text-gray-500'>Category</label>
                            {/* value={query.category_id} */}
                            <select name='category_id'  onChange={(e)=>setActive(e.target.value)} className='register-text-box'>
                                <option value={'0'}>* Select</option>
                                {interCategory?.map((x)=>(
                                    <option value={x.id} key={x.id}>{x?.name}</option>
                                ))}
                            </select>
                        </div>

                        <div>
                        </div>

                        <button type='submit' className='mt-12 w-full bg-hex-blue text-white py-4 rounded-sm font-bold flex items-center justify-center'>
                                Submit {loading &&  <Loader />}
                            </button>

            </form>

            <div className='mt-8'>
                <Table dataSource={data} {...{columns:columns2({activeId, 
                    setActiveId,
                    handleDelete: async (row) => {
                    showModal2()
                    setID(row.id)
                    },
                    })  }} />
            </div>
        </Fragment>
    )
}

export default InterLocation
