import React from 'react';
import { Card, Divider, Rate } from 'antd';
import DropDown from './DropDown'

const Mobilecard = ({riders}) => {
    const cards = riders.data.map((data) => { return (
        <Card key={data.id} className="my4 bikeCard">
          <div className="flex space-btw">
            <p style={{color: "#006FC1"}}><b>{data.user.name || "-"}</b></p>
          </div>
          <div>
            <span>{data.name}</span>
            <Divider type="vertical" />
            <span className="greyText"><Rate disabled value={data.ratings}/></span>
          </div>
          <div className="flex space-btw">
          <p>{data.user.address || "-"}</p>
          <DropDown data={data}/>
          </div>
        </Card>
      );
    });
    return (
        <div>
            {cards}
        </div>
    );
}

export default Mobilecard;