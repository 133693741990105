import React, { Fragment , useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { profileActions } from '../../../../actions';
import Loader from '../../../../components/Loader';

const Notifications = () => {
    const dispatch = useDispatch()
    const [loading,setLoading] = useState(false)
    const [query, setQuery] = useState({
        push_notification: false,
        email_notification: false,
      })

// eslint-disable-next-line
        const handleInput = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            setQuery((prevState) => ({
                ...prevState,
                [name]: value
            }));
            };

        const changeBox1 = (e) => {
            // const name = e.target.name;
            // const value = e.target.value;
            setQuery((prevState) => ({
              ...prevState,
              push_notification: !query.push_notification
            }));
          };

          const changeBox2 = (e) => {
            // const name = e.target.name;
            // const value = e.target.value;
            setQuery((prevState) => ({
              ...prevState,
              email_notification: !query.email_notification
            }));
          };


        const submit = async (e) => {
            e.preventDefault();
            setLoading(true) 
    
            const formdata = new FormData();
              Object.entries(query).forEach(([key, value]) => {
              formdata.append(key, value);
                });

                console.log(...formdata)
            
            const response =  await dispatch(profileActions.updateNotification(formdata))
            if(response.data.status === true) {
                setLoading(false) 
              toast.success(response?.data?.message,{
                autoClose: 2000,
                hideProgressBar: true})
            }
            else {
                toast.error(response?.data?.message,{
                  autoClose: 2000,
                  hideProgressBar: true })
                  setLoading(false) 
                }
    
         }
    return (
        <Fragment>
            <form onSubmit={(e)=>submit(e)} className='mt-8'>

                <div className='notification-card'>
                    <span>New Update Notification</span>
                    <input type={'checkbox'} name='update_notification' value={query.update_notification}/>
                </div>
                <div className='mt-6 notification-card'>
                    <span>Push Notification</span>
                    <input type={'checkbox'} name='push_notification' value={query.push_notification} onChange={(e)=>changeBox1(e)} />
                </div>
                <div className='mt-6 notification-card'>
                    <span>Email Notification</span>
                    <input type={'checkbox'} name='email_notification' value={query.email_notification} onChange={(e)=>changeBox2(e)} />
                </div>

                <button type='submit' className='w-auto py-4 px-8 mt-8 bg-hex-blue focus:outline-none border-none flex flex-row items-center justify-between text-white rounded-md text-sm'>Save {loading && <Loader />}</button>

            </form>
        </Fragment>
    )
}

export default Notifications
