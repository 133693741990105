import React,{Fragment,useState} from 'react'
// eslint-disable-next-line
import Price from '../../../../assets/price-tag.png'
import Create from './Create'
import Edit from './Edit'



const PaymentCategory = () => {
    const [show,setShow] = useState(false)

    return (
        <Fragment>
        <div className='h-full w-full flex flex-row justify-between items-start'>
            <div className='h-full flex w-full'>
                {!show ?  (<Create />) : (<Edit />)}
            </div>


            <div className='mr-auto w-auto flex flex-row items-center '>
                <button onClick={()=>setShow(false)} className={'h-4 w-4 bg-hex-blue rounded-full'}></button>
                <hr className='w-20 '></hr>
                <button onClick={()=>setShow(true)} className={show ? 'h-4 w-4 bg-hex-blue rounded-full' : 'h-4 w-4 bg-gray-200 rounded-full'}></button>
              </div>
        </div>


        

        </Fragment>
    )
}

export default PaymentCategory
