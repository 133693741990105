import React, { useEffect, useState } from "react";
import { Button } from "antd";
import WalletTable from "./components/walletTable";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import Headers from "../../components/Header";
import Spinner from "../../components/Spinner";
import { walletActions } from "../../actions";
import WalletGraph from "./components/Chart";

const Wallet = (props) => {
  const dispatch = useDispatch();
  const [isChartVisible, setChartVisibility] = useState(false);
  const wallet = useSelector((state) => state.wallet);
  const walletList = wallet?.walletList;
  useEffect(() => {
    dispatch(walletActions.getWallet());
  }, [dispatch]);

  const showChart = () => {
    setChartVisibility(!isChartVisible);
  };

  return (
    <div className="containerWrapper lg:h-screen flex flex-col px-0 lg:p-8">
      <Headers />
      {!walletList ? (
        <Spinner />
      ) : (
        <div className="container my4 wallet">
          {isChartVisible ? (
            <WalletGraph />
          ) : (
            <div>
              <h3>Wallet</h3>
              <WalletTable />
            </div>
          )}
          <div className="absolute abs">
            <Button
              className="btnChart"
              type="text"
              onClick={showChart}
              style={{ backgroundColor: isChartVisible ? "#E3000F" : "#006FC1", color: "white" }}
            >
              {isChartVisible ? "Hide chart" : "View Chart"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Wallet;
