import React,{Fragment,useState,useEffect,useMemo} from 'react'
import LoginImage from '../loginImage'
import { Link,useParams,useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authActions } from '../../../actions';
import Loader from '../../../components/Loader';

const Update = () => {
    let {email} = useParams()
  // const [passwords,showPassword] = useState(false)
  const [loading,setLoading] = useState(false)
  const [passwords,showPassword] = useState(false)
  const [password,setPassword] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch();

  useEffect(() => {
    setQuery(x=>({
      ...x,
      email: email ? email : '',
    }))
  }, [email])
  
  const [query, setQuery] = useState({
      email: email ? email : '',
      otp: '',
      password: '',
      password_confirmation: '',
    })

  const disabled = useMemo(
    () =>  !query.email || !query.password || !query.otp || !query.password_confirmation,
    [ query.email, query.password , query.otp , query.password_confirmation]
    );

    const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
        ...prevState,
        [name]: value
    }));
    };



  const submit = async (e) => {
    e.preventDefault()
    await setLoading(true)
    // console.log("Success:", value);

    const formdata = new FormData();
        Object.entries(query).forEach(([key, value]) => {
        formdata.append(key, value);
          });
      

    const response = await dispatch(authActions.updatePassword(formdata))
    console.log(response)
    if(response.data.status === true) {
      setLoading(false) 
      history.push(`/rider`)
    toast.success('Welcome...',{
      autoClose: 2000,
      hideProgressBar: true})
  }
  else {
      toast.error(response?.data?.message,{
        autoClose: 2000,
        hideProgressBar: true })
        setLoading(false) 
      }


  }
    return (
        <Fragment>
        <div className='flex flex-row'>
                <div className='background hidden md:flex md:flex-col md:w-1/2 h-screen px-12 lg:px-32'>


                <LoginImage />
                
            </div>
        <section className='w-full md:w-1/2'>

        <div className='min-w-full px-8 sm:px-12 xl:px-32 py-12 xl:py-20 h-screen'>
            
            <div>
                <img src={`${process.env.REACT_APP_IMAGE}/healthLogo.png`} alt='' className='w-12 lg:w-20' />
            </div>

            <h1 className='mt-20 lg:mt-20 text-xl lg:text-3xl font-bold'>
                Update Password
            </h1>

            <p className='text-gray-400'>
                Fill in your details to update your password
            </p>


           
            <form onSubmit={(e)=>submit(e)} className='mt-8'>

                <div>
                <input type='text' placeholder = 'Email Address' disabled={true} className='login-text-box' value={query.email} name='email' onChange={(e)=>handleInput(e)} />
                </div>


                <div className='mt-2'>
                <input type='text' placeholder = 'Enter OTP' className='login-text-box' value={query.otp} name='otp' onChange={(e)=>handleInput(e)} />
                </div>

                <div className='mt-12 login-text-box flex bg-white flex-row item-center justify-between'>
                <input type={passwords ? 'text' : 'password'} placeholder = 'New Password' className='flex-1 outline-none' value={query.password} name='password' onChange={(e)=>handleInput(e)}/>
                <button type='button' className='focus:outline-none' onClick={()=>showPassword(!passwords)}>
                    {passwords ? <FontAwesomeIcon icon={faEyeSlash} color="#ADADAD" size="lg" /> : <FontAwesomeIcon icon={faEye} color="#ADADAD" size="lg" />}
                </button>
                </div>

                <div className='mt-12 login-text-box flex bg-white flex-row item-center justify-between'>
                <input type={password ? 'text' : 'password'} placeholder = 'Confirm New Password' className='flex-1 outline-none' value={query.password_confirmation} name='password_confirmation' onChange={(e)=>handleInput(e)}/>
                <button type='button' className='focus:outline-none' onClick={()=>setPassword(!password)}>
                    {password ? <FontAwesomeIcon icon={faEyeSlash} color="#ADADAD" size="lg" /> : <FontAwesomeIcon icon={faEye} color="#ADADAD" size="lg" />}
                </button>
                </div>

                <Link className='mt-4' to='/merchant-admin/recover-password'>Incorrect Token?? Resend Email </Link>
                



                <button type='submit' disabled={disabled} className={disabled ? 'cursor-not-allowed opacity-70 bg-gray-300 mt-12 w-full text-white py-1 rounded-sm font-bold justify-center' : 'mt-12 w-full bg-hex-blue text-white rounded-sm font-bold flex items-center justify-center py-1'}>
                    Login into Account {loading &&  <Loader />}
                </button>

                </form>


        </div>

        </section>
            </div>
        </Fragment>
    )
}

export default Update
