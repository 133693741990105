import React, { Component } from 'react';
import { mountScript, unmountScript } from './mapFunction';
import { Card } from 'antd';
import "./styles.less"


class Maps extends Component {
  componentDidMount(){
      mountScript(this.initMap)
  }
  componentWillUnmount(){
      unmountScript()
  }
  initMap = () =>{
  const {data} = this.props
  console.log(data, "data");
    if(data && data.center){
      let mapContainer = document.getElementById("map"),
      center = new window.google.maps.LatLng(data.center.lat, data.center.lng),
      pointA = data.pickup_address,
      pointB = data.destination_address,
      mapOptions = {
          zoom: 7,
          center,
        },
      map = new window.google.maps.Map(mapContainer, mapOptions),
      directionsRenderer = new window.google.maps.DirectionsRenderer({map: map}),
      directionsService = new window.google.maps.DirectionsService();
      this.calcRoute(directionsRenderer, directionsService, pointA, pointB)
    }       
    }
    
    calcRoute =(directionsRenderer, directionsService, pointA, pointB)=> {
      let request = {
        origin: pointA,
        destination: pointB,
        avoidTolls: true,
        avoidHighways: false,
        travelMode: 'DRIVING',
      };
      directionsService.route(request, function(result, status) {
        if (status === 'OK') {
          directionsRenderer.setDirections(result);
        } else{

        }
      });
      
      
  }
  render() {
      return (
          <Card id="map" className="gMap cardWrapper">
          </Card>
      );
  }
}

export default Maps;

