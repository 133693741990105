import { Modal, Table } from 'antd'
import React, { Fragment, useEffect,useState } from 'react'
import { useDispatch } from 'react-redux'
import { categoryActions, mappingsActions } from '../../../../../../actions'
import { columns } from '../tabledata'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../../../../components/Loader'

const IntraPricing = (props) => {
    const {intraCategory,intraMapping} = props
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()

    const [activeId, setActiveId] = useState(null)
    const [ID, setID] = useState(null)
    const [row, setRow] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const [isModalVisible2, setIsModalVisible2] = useState(false);

    const showModal2 = () => {
        setIsModalVisible2(true);
    };

    const handleCancel2 = () => {
        setIsModalVisible2(false);
    };


    const handleClick = () => {
        setActiveId(false)
      }
    
      useEffect(() => {
        document.addEventListener('click', handleClick)
        return () => {
          document.removeEventListener('click', handleClick)
        }
      }, [])


    useEffect(() => {
        setList({
        title: row?.Category ? row?.Category : '',
        price: row?.Price ? row?.Price : '',
        type: 'intra'
        })
     }, [row])

    const [list,setList] = useState({
        title: row?.Category ? row?.Category : '',
        price: row?.Price ? row?.Price : '',
        type: 'intra'
    })



     const [query, setQuery] = useState({
        pickup_category_id: '',
        dest_category_id: '',
        price: '',
        type: 'intra'
      })

    const handleInput = (e) => {
    const name = e.target.name;
    const value = parseInt(e.target.value);
    setQuery((prevState) => ({
        ...prevState,
        [name]: value
    }));
    };

    const editInput = (e) => {
        const name = e.target.name;
        const value = parseInt(e.target.value);
        setList((prevState) => ({
            ...prevState,
            [name]: value
        }));
        };

    const submit = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const response =  await dispatch(mappingsActions.addMappings(query))
        if(response.status === 200) {
            setLoading(false) 
            setQuery({
                pickup_category_id: '',
                dest_category_id: '',
                price: '',
                type:'intra'
            })
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }
       dispatch(categoryActions.getCategories('intra'))
       dispatch(mappingsActions.getMappings('intra'))
     }

     const editList = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const response =  await dispatch(mappingsActions.putMappings(row.id,list))
        if(response.status === 200) {
            setLoading(false) 
            handleCancel()
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }
       dispatch(categoryActions.getCategories('intra'))
       dispatch(mappingsActions.getMappings('intra'))

     }

     const deleteCategory = async (e) => {
        e.preventDefault();
        setLoading(true) 

        const response =  await dispatch(mappingsActions.deleteMappings(ID))

        if(response.status === 200) {
            setLoading(false) 
            handleCancel2()
          toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
        }
        else {
            toast.error(response?.data?.message,{
              autoClose: 2000,
              hideProgressBar: true })
              setLoading(false) 
            }
            dispatch(categoryActions.getCategories('intra'))
        dispatch(mappingsActions.getMappings('intra'))
     }

    const data = intraMapping !== '' ?  intraMapping?.map((item,i) => ({
        id: item?.mapping_id,
        key: item?.mapping_id,
        SN: ++i,
        Category: item?.title,
        Price: item?.price,
    })) : [] 
    console.log(intraMapping)
    return (
        <Fragment>
             <Modal title={'Edit Ride Type Size'} closable={true} centered={true} onCancel={handleCancel} visible={isModalVisible} footer={null}>

                    <form onSubmit={(e)=>editList(e)}>

                    <div className='flex-1'>
                            <label className='font-bold text-xs'>Category</label>
                            <input type={'text'} className='regular-form' name='title' value={list.title} onChange={(e)=>editInput(e)}  />
                    </div>

                    <div className='flex-1 mt-8'>
                            <label className='font-bold text-xs'>Price</label>
                            <input type={'number'} className='regular-form' name='price' value={list.price} onChange={(e)=>editInput(e)}  />
                    </div>

                        <div>
                            <button type='submit' className='mt-12 w-full bg-hex-blue text-white py-4 rounded-sm font-bold flex items-center justify-center'>
                                Submit {loading &&  <Loader />}
                            </button>
                                </div>
                    </form>

                    </Modal>
                    <Modal title={null} closable={true} centered={true} onCancel={handleCancel2} visible={isModalVisible2} footer={null}>
                    <div className='text-center text-md'>Are you sure you want to delete this category type?</div>
                    <div className='mt-8 flex flex-row justify-center items-center'>
                        <button onClick={handleCancel2} className='border-none bg-gray-300 text-white rounded-sm mx-4 p-2 px-4'>Cancel</button>
                        <button onClick={(e)=>deleteCategory(e,ID)} className='border-none bg-hex-blue text-white rounded-sm mx-4 p-2 px-4 flex items-center justify-center'>
                                    Submit {loading &&  <Loader />}</button>
                    </div>
                    </Modal>
            <form onSubmit={(e)=>submit(e)} className='grid grid-cols-3 gap-8 items-end'>
                        <div>
                            <label className='font-bold text-gray-500'>Category</label>
                            <select name='pickup_category_id' value={query.pickup_category_id} onChange={(e)=>handleInput(e)} className='register-text-box'>
                                <option value={'0'}>* Select</option>
                                {intraCategory?.map((x)=> (
                                    <option value={x.id} key={x.id}>{x?.name}</option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label className='font-bold text-gray-500'>Category</label>
                            <select name='dest_category_id' value={query.dest_category_id} onChange={(e)=>handleInput(e)} className='register-text-box'>
                                <option value={'0'}>* Select</option>
                                {intraCategory?.map((x)=> (
                                    <option value={x.id} key={x.id}>{x?.name}</option>
                                ))}
                            </select>
                        </div>
            

                        <div>
                            <label className='font-bold text-gray-500'>Price</label>
                            <div className='w-full px-0 py-0 rounded-sm focus:outline-none bg-light-gray border border-space-gray flex flex-row items-center'>
                                <label className='bg-blue-200 py-3 px-1 text-hex-blue font-bold'>N</label>
                                <input type={'number'} className='px-1 flex-1 bg-transparent focus:outline-none' name='price' value={query.price} onChange={(e)=>handleInput(e)} />
                            </div>
                        </div>

                        <button type='submit' className='mt-12 w-full bg-hex-blue text-white py-4 rounded-sm font-bold flex items-center justify-center'>
                                    Submit {loading &&  <Loader />}</button>
            </form>

            <div className='mt-8'>
                <Table dataSource={data} pagination={{defaultPageSize: '5'}}  {...{columns:columns({activeId, 
                    setActiveId,
                    handleEdit: async (row) => {
                         await setRow(row)
                        console.log(row)
                         if (row !== null)   showModal()

                      },
                    handleDelete: async (row) => {
                    showModal2()
                    setID(row.id)
                    },
                    })  }}/>
            </div>
        </Fragment>
    )
}

export default IntraPricing
