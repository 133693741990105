import React, { useState } from 'react';
import {Button, Modal } from 'antd';
import { Icon } from '@iconify/react';

const ViewUserModal = ({data}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Button type="text" onClick={showModal} className="flex align-center">
                <Icon icon="ant-design:eye-outlined" className="icons" /> <small>View</small>
            </Button>
            <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false}>
                <h2>View Order</h2>
                <p className="greyText">Please view all the details in the order list below carefully and confirm
                    all inputs</p>
                <div>
                <div className="flex space-btw left bord userInfo">
                    <p>Sender Name: </p>
                    <p>{data.sender_name}</p>
                    </div>
                <div className="flex space-btw left bord userInfo">
                <p>Status: </p>
                    <p>{data.status}</p>
                </div>
                <div className="flex space-btw left bord userInfo">
                <p>Receiver Name: </p>
                    <p>{data.receiver_name}</p>
                </div>
                <div className="flex space-btw left bord userInfo">
                <p>Description: </p>
                    <p>{data.item_description}</p>
                </div>
                <div className="flex space-btw left bord userInfo">
                <p>Payment Method: </p>
                    <p>{data.payment.method}</p>
                </div>
                <div className="flex space-btw left userInfo">
                <p>Price: </p>
                <p>{data.payment.price}</p>
                </div>
                <div className="flex space-btw left userInfo">
                <p>Reference Number: </p>
                <p>{data.payment.reference_number}</p>
                </div>
                </div>
                <Button className="modalBtn"  onClick={handleCancel}>Close</Button>
            </Modal>
        </>
    );
};

export default ViewUserModal


