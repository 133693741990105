import { TeamTypes,TeamOptionsTypes } from "../types"


export function teamOptions (state = {}, action) {
    switch (action.type) {
      case TeamOptionsTypes.GET_SINGLE_TEAM_REQUEST:
      return {
          ...state
      }
      case TeamOptionsTypes.GET_SINGLE_TEAM_SUCCESS:
      return {
          ...state,
          teamOptions: action.teamOptions
      }
      case TeamOptionsTypes.GET_SINGLE_TEAM_FAILURE:
      return {
          ...state,
          error: action.error
      }
      default:
        return state
    }
  }


export function team (state = {}, action) {
  switch (action.type) {
    case TeamTypes.GET_TEAM_OPTIONS_REQUEST:
      return {
        ...state
      }
    case TeamTypes.GET_TEAM_OPTIONS_SUCCESS:
      return {
        ...state,
        team: action.team
      }
    case TeamTypes.GET_TEAM_OPTIONS_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case TeamTypes.ADD_TEAM_OPTIONS_REQUEST:
    return {
        ...state
    }
    case TeamTypes.ADD_TEAM_OPTIONS_SUCCESS:
    return {
        ...state,
        // team: action.team
    }
    case TeamTypes.ADD_TEAM_OPTIONS_FAILURE:
    return {
        ...state,
        error: action.error
    }
    case TeamTypes.DELETE_TEAM_OPTIONS_REQUEST:
    return {
        ...state
    }
    case TeamTypes.DELETE_TEAM_OPTIONS_SUCCESS:
    return {
        ...state,
        // team: action.team
    }
    case TeamTypes.DELETE_TEAM_OPTIONS_FAILURE:
    return {
        ...state,
        error: action.error
    }
    case TeamTypes.UPDATE_TEAM_OPTIONS_REQUEST:
    return {
        ...state
    }
    case TeamTypes.UPDATE_TEAM_OPTIONS_SUCCESS:
    return {
        ...state,
        // team: action.team
    }
    case TeamTypes.UPDATE_TEAM_OPTIONS_FAILURE:
    return {
        ...state,
        error: action.error
    }
    default:
      return state
  }
}
