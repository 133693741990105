export const orderTypes = {
   
    GET_PENDING_ORDERS_REQUEST: 'GET_PENDING_ORDERS_REQUEST',
    GET_PENDING_ORDERS_SUCCESS: 'GET_PENDING_ORDERS_SUCCESS',
    GET_PENDING_ORDERS_FAILURE: 'GET_PENDING_ORDERS_FAILURE',

    GET_PENDING_ORDER: 'GET_PENDING_ORDER',
    GET_COMPLETED_ORDER: 'GET_COMPLETED_ORDER',
    FILTER_PENDING_ORDER: 'FILTER_PENDING_ORDER',
    VIEW_PENDING_ORDER: "VIEW_PENDING_ORDER",
    DELETE_PENDING_ORDER: "DELETE_PENDING_ORDER",
    LOADING: "LOADING",

   
}

export const assignOrderTypes = {

    GET_NEARBY_RIDERS_REQUEST: 'GET_NEARBY_RIDERS_REQUEST',
    GET_NEARBY_RIDERS_SUCCESS: 'GET_NEARBY_RIDERS_SUCCESS',
    GET_NEARBY_RIDERS_FAILURE: 'GET_NEARBY_RIDERS_FAILURE',

    ASSIGN_ORDERS_REQUEST: 'ASSIGN_ORDERS_REQUEST',
    ASSIGN_ORDERS_SUCCESS: 'ASSIGN_ORDERS_SUCCESS',
    ASSIGN_ORDERS_FAILURE: 'ASSIGN_ORDERS_FAILURE',

 
}