import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { subscriptionActions } from "../../../../actions";
import Spinner from "../../../../components/Spinner";

const Cards = () => {
  const dispatch = useDispatch();
  const subscription = useSelector((state) => state.subscription);

  useEffect(() => {
    dispatch(subscriptionActions.getSubscription());
  }, [dispatch]);

  let subList = subscription?.subscription?.data;
  console.log(subList);

  return (
    <Fragment>
      {subList && subList?.data?.length > 0 ? (
        <div className="w-11/12 mt-8 grid grid-cols-2 gap-8">
          {subList?.data?.map((x) => (
            <div key={x?.id} className="card w-full flex flex-col bg-white p-8">
              <div className="bg-light-gray w-12 flex justify-center h-12 items-center rounded-full">
                <img src={`${process.env.REACT_APP_IMAGE}/subtag.png`} alt="" className="h-4" />
              </div>

              <h1 className="font-bold text-lg mt-8">{x.name}</h1>
              <p className="text-gray-500">{x.description}</p>

              <span className="font-bold flex flex-row mt-12 text-gray-600">
                N{x.amount} / <p className="text-gray-400">annum</p>
              </span>

              <Link
                to={`/merchant-admin/setting/subscription/${x.uuid}`}
                className="w-full mt-4 bg-hex-blue p-2 text-white rounded-md text-xs flex justify-center hover:text-white hover:opacity-90"
              >
                {/* <button className=''> */}
                Choose {x.name}
                {/* </button> */}
              </Link>

              {x?.features?.map((y) => (
                <div className="mt-6 flex flex-row items-center text-xs">
                  <img src={`${process.env.REACT_APP_IMAGE}/checkbox.svg`} className="w-3 mr-4" alt="" />
                  <span className="text-black">{y.name}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};

export default Cards;
