import { authService } from "../services";
import { authTypes } from "../types";

export const authActions = {
    login,
    logout,
    sendPassword,
    updatePassword,
    addBusiness
}
function login (formdata) {
    return async dispatch => {
        dispatch(request(formdata))

        try {
          const auth = await authService.login(formdata)
          dispatch(success(auth))
          return auth;
            
      } catch (error) {
          dispatch(failure(error.toString()))
          console.log(error?.response)
          return error?.response;
          
      }
    }

  function request (formdata) { return { type: authTypes.LOGIN_REQUEST, formdata } }
  function success (auth) { return { type: authTypes.LOGIN_SUCCESS, auth } }
  function failure (error) { return { type: authTypes.LOGIN_FAILURE, error } }
}

function sendPassword (formdata) {
  return async dispatch => {
      dispatch(request(formdata))

      try {
        const auth = await authService.sendPassword(formdata)
        dispatch(success(auth))
        return auth;
          
    } catch (error) {
        dispatch(failure(error.toString()))
        console.log(error?.response)
        return error?.response;
        
    }
  }

function request (formdata) { return { type: authTypes.SEND_PASSWORD_REQUEST, formdata } }
function success (auth) { return { type: authTypes.SEND_PASSWORD_SUCCESS, auth } }
function failure (error) { return { type: authTypes.SEND_PASSWORD_FAILURE, error } }
}

function addBusiness (formdata) {
  return async dispatch => {
      dispatch(request(formdata))

      try {
        const auth = await authService.addBusiness(formdata)
        dispatch(success(auth))
        return auth;
          
    } catch (error) {
        dispatch(failure(error.toString()))
        console.log(error?.response)
        return error?.response;
        
    }
  }

function request (formdata) { return { type: authTypes.ADD_BUSINESS_REQUEST, formdata } }
function success (auth) { return { type: authTypes.ADD_BUSINESS_SUCCESS, auth } }
function failure (error) { return { type: authTypes.ADD_BUSINESS_FAILURE, error } }
}

function updatePassword (formdata) {
  return async dispatch => {
      dispatch(request(formdata))

      try {
        const auth = await authService.updatePassword(formdata)
        dispatch(success(auth))
        return auth;
          
    } catch (error) {
        dispatch(failure(error.toString()))
        console.log(error?.response)
        return error?.response;
        
    }
  }

function request (formdata) { return { type: authTypes.UPDATE_PASSWORD_REQUEST, formdata } }
function success (auth) { return { type: authTypes.UPDATE_PASSWORD_SUCCESS, auth } }
function failure (error) { return { type: authTypes.UPDATE_PASSWORD_FAILURE, error } }
}


function logout(){
    return dispatch => {
  
      dispatch(success())
      authService.logout().then(
      document.location.href = "/merchant-admin/login"
      )
    }
  
    function success() { return { type: authTypes.LOGOUT} }
  }