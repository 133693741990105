import { roleTypes, priviledgeTypes, assignPriviledgeTypes } from "../types";

export function role(state = {}, action) {
  switch (action.type) {
    case roleTypes.GET_ROLES_REQUEST:
      return {
        ...state,
      };
    case roleTypes.GET_ROLES_SUCCESS:
      return {
        ...state,
        role: action.role,
      };
    case roleTypes.GET_ROLES_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case roleTypes.CREATE_ROLES_REQUEST:
      return {
        ...state,
      };
    case roleTypes.CREATE_ROLES_SUCCESS:
      return {
        ...state,
        // role: action.role
      };
    case roleTypes.CREATE_ROLES_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export function priviledge(state = {}, action) {
  switch (action.type) {
    case priviledgeTypes.GET_ALL_PRIVILEDGES_REQUEST:
      return {
        ...state,
      };
    case priviledgeTypes.GET_ALL_PRIVILEDGES_SUCCESS:
      return {
        ...state,
        priviledge: action.priviledge,
      };
    case priviledgeTypes.GET_ALL_PRIVILEDGES_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case priviledgeTypes.PUT_PRIVILEDGES_REQUEST:
      return {
        ...state,
      };
    case priviledgeTypes.PUT_PRIVILEDGES_SUCCESS:
      return {
        ...state,
        // priviledge: action.priviledge
      };
    case priviledgeTypes.PUT_PRIVILEDGES_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export function assignPriviledge(state = {}, action) {
  switch (action.type) {
    case assignPriviledgeTypes.GET_ASSIGNED_PRIVILEDGES_REQUEST:
      return {
        ...state,
      };
    case assignPriviledgeTypes.GET_ASSIGNED_PRIVILEDGES_SUCCESS:
      return {
        ...state,
        assignPriviledge: action.assignPriviledge,
      };
    case assignPriviledgeTypes.GET_ASSIGNED_PRIVILEDGES_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
