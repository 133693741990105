export const locationTypes = {
   
    GET_LOCATION_REQUEST: 'GET_LOCATION_REQUEST',
    GET_LOCATION_SUCCESS: 'GET_LOCATION_SUCCESS',
    GET_LOCATION_FAILURE: 'GET_LOCATION_FAILURE',

    ADD_LOCATION_REQUEST: 'ADD_LOCATION_REQUEST',
    ADD_LOCATION_SUCCESS: 'ADD_LOCATION_SUCCESS',
    ADD_LOCATION_FAILURE: 'ADD_LOCATION_FAILURE',

    DELETE_LOCATION_REQUEST: 'DELETE_LOCATION_REQUEST',
    DELETE_LOCATION_SUCCESS: 'DELETE_LOCATION_SUCCESS',
    DELETE_LOCATION_FAILURE: 'DELETE_LOCATION_FAILURE',

}

export const locationOptionsTypes = {
    
    GET_SINGLE_LOCATION_REQUEST: 'GET_SINGLE_LOCATION_REQUEST',
    GET_SINGLE_LOCATION_SUCCESS: 'GET_SINGLE_LOCATION_SUCCESS',
    GET_SINGLE_LOCATION_FAILURE: 'GET_SINGLE_LOCATION_FAILURE',
}