import React, { Fragment, useEffect , useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { profileActions } from '../../../actions'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../components/Loader';
import Spinner from '../../../components/Spinner';

const BusinessProfileSetting = () => {
  const dispatch = useDispatch()
  const profile = useSelector(state=>state.profile)

  useEffect(() => {
   dispatch(profileActions.getBusiness())
  }, [dispatch])

  let profileDetails = profile?.profile?.data?.data

  useEffect(() => {
      setQuery({
        name: profileDetails?.name ? profileDetails?.name : '',
        address: profileDetails?.address ? profileDetails?.address : '',
        country: profileDetails?.country ? profileDetails?.country : '',
        state: profileDetails?.state ? profileDetails?.state : '',
        city: profileDetails?.city ? profileDetails?.city : '',
        email: profileDetails?.email ? profileDetails?.email : '',
        rc_number: profileDetails?.rc_number ? profileDetails?.rc_number : '',
        first_name: profileDetails?.first_name ? profileDetails?.first_name : '',
        last_name: profileDetails?.last_name ? profileDetails?.last_name : '',
        phone_number: profileDetails?.phone_number ? profileDetails?.phone_number : '',
        identity_no: profileDetails?.identity_no ? profileDetails?.identity_no : '',
        identity_type: profileDetails?.identity_type ? profileDetails?.identity_type : '',
        // logo: profileDetails?.logo_url ? profileDetails?.logo_url : '',
        // contact_person_image: profileDetails?.contact_person_image_url ? profileDetails?.contact_person_image_url : ''
    })
  }, [profileDetails])


  const [loading,setLoading] = useState(false)
  const [query, setQuery] = useState({
     name: profileDetails?.name ? profileDetails?.name : '',
     address: profileDetails?.address ? profileDetails?.address : '',
     country: profileDetails?.country ? profileDetails?.country : '',
     state: profileDetails?.state ? profileDetails?.state : '',
     city: profileDetails?.city ? profileDetails?.city : '',
     email: profileDetails?.email ? profileDetails?.email : '',
     rc_number: profileDetails?.rc_number ? profileDetails?.rc_number : '',
     first_name: profileDetails?.first_name ? profileDetails?.first_name : '',
     last_name: profileDetails?.last_name ? profileDetails?.last_name : '',
     phone_number: profileDetails?.phone_number ? profileDetails?.phone_number : '',
     identity_no: profileDetails?.identity_no ? profileDetails?.identity_no : '',
     identity_type: profileDetails?.identity_type ? profileDetails?.identity_type : '',
    //  logo: profileDetails?.logo_url ? profileDetails?.logo_url : '',
    //  contact_person_image: profileDetails?.contact_person_image_url ? profileDetails?.contact_person_image_url : ''
    })

  const handle = (e) => {
  const name = e.target.name;
  const value = e.target.value;
  setQuery((prevState) => ({
      ...prevState,
      [name]: value
  }));
  };

  const handleChange = async (e) => {
    const file = e.target.files[0]
    const name = e.target.name;
    setQuery((prevState) => ({
        ...prevState,
        [name]: file
      }));
  }



  const submit = async (e) => {
      e.preventDefault();
      setLoading(true) 

      const formdata = new FormData();
      Object.entries(query).forEach(([key, value]) => {
      formdata.append(key, value);
        });

        console.log(...formdata)

      const response =  await dispatch(profileActions.updateBusiness(profileDetails?.id,formdata))
      if(response.data.status === true) {
          setLoading(false) 
        toast.success(response?.data?.message,{
          autoClose: 2000,
          hideProgressBar: true})
      }
      else {
          toast.error(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true })
            setLoading(false) 
          }
      dispatch(profileActions.getBusiness())
   }

  

    return (
        <Fragment>
            {profileDetails && profileDetails !== '' ? 
            (<form onSubmit={(e)=>submit(e)} className='h-full overflow-y-scroll'>
            <div className='grid grid-cols-3 gap-8'>
                 <div>
                    <label className='font-bold'>Business Name</label>
                    <input type={'text'} className='register-text-box' name='name' value={query.name} onChange={(e)=>handle(e)} />
                  </div>

                  <div>
                    <label className='font-bold'>Business Location</label>
                    <input type={'text'} className='register-text-box'  name='address' value={query.address} onChange={(e)=>handle(e)} />
                  </div>

                  <div>
                    <label className='font-bold'>Country</label>
                    <input type={'text'} className='register-text-box' name='country' value={query.country} onChange={(e)=>handle(e)} />
                  </div>

                  <div>
                    <label className='font-bold'>City</label>
                    <input type={'text'} className='register-text-box' name='city' value={query.city} onChange={(e)=>handle(e)} />
                  </div>

                  <div>
                    <label className='font-bold'>State</label>
                    <input type={'text'} className='register-text-box' name='state' value={query.state} onChange={(e)=>handle(e)} />
                  </div>

                  <div>
                    <label className='font-bold'>Business Email Address</label>
                    <input type={'text'} className='register-text-box' />
                  </div>

                  <div>
                    <label className='font-bold'>Logo</label>
                    <img src={profileDetails?.logo_url} alt='' className='w-12 h-12 rounded-md' />
                    <input type={'file'} className='file-btn mt-4' name='logo' onChange={(e)=>handleChange(e)}  />
                  </div>

                  <div>
                    <label className='font-bold'>RC Number</label>
                    <input type={'number'} className='register-text-box'  name='rc_number' value={query.rc_number} onChange={(e)=>handle(e)} />
                  </div>

            </div>


            <h1 className='mt-8 text-lg'>Contact Person Details</h1>

            <div className='grid grid-cols-3 gap-8 mt-2'>
                 <div>
                    <label className='font-bold'>First Name</label>
                    <input type={'text'} className='register-text-box'  name='first_name' value={query.first_name} onChange={(e)=>handle(e)} />
                  </div>

                  <div>
                    <label className='font-bold'>Last Name</label>
                    <input type={'text'} className='register-text-box'  name='last_name' value={query.last_name} onChange={(e)=>handle(e)} />
                  </div>

                  <div>
                    <label className='font-bold'>Position</label>
                    <input type={'text'} className='register-text-box' name='position' value={query.position} onChange={(e)=>handle(e)} />
                  </div>

                  <div>
                    <label className='font-bold'>Email Address</label>
                    <input type={'text'} className='register-text-box' name='email' value={query.email} onChange={(e)=>handle(e)}/>
                  </div>

                  <div>
                    <label className='font-bold'>Phone Number</label>
                    <input type={'number'} className='register-text-box'  name='phone_number' value={query.phone_number} onChange={(e)=>handle(e)} />
                  </div>

                  <div>
                    <label className='font-bold'>Identity Type</label>
                    <input type={'text'} className='register-text-box' name='identity_type' value={query.identity_type} onChange={(e)=>handle(e)} />
                  </div>

                  <div>
                    <label className='font-bold'>NIN Number</label>
                    <input type={'text'} className='register-text-box' name='identity_no' value={query.identity_no} onChange={(e)=>handle(e)} />
                  </div>

                  <div>
                    <label className='font-bold'>Image</label>
                    <img src={profileDetails?.contact_person_image_url} alt='' className='w-12 h-12 rounded-md mb-4' />
                    <input type={'file'} className='file-btn' name='contact_person_image' onChange={(e)=>handleChange(e)} />
                  </div>

            </div>

            <button type='submit' className='w-auto py-4 px-8 mt-8 bg-hex-blue focus:outline-none border-none flex flex-row items-center justify-between text-white rounded-md text-sm'>Save Settings {loading && <Loader />}</button>
            </form>) : (<Spinner />)}
        </Fragment>
    )
}

export default BusinessProfileSetting
