import React from "react";
import { Card } from "antd";

const SenderCards = ({sender_info}) => {
  const {sender_name, sender_phone_number, pickup_address} = sender_info
  return (
    <Card className="card">
      <div>
        <img src={`${process.env.REACT_APP_IMAGE}/sender.png`} alt="sender" className="profileImg"/>
      </div>
      <h4>Sender Profile</h4>
      <p className="profileName">{sender_name}</p>
      <p className="grytxt">{sender_phone_number}</p>
      <p>{pickup_address}</p>
    </Card>
  );
};
const ReceiverCards = ({receiver_info}) => {
  const {receiver_name, recciever_phone_number, destination_address} = receiver_info

  return (
    <Card className="card">
      <div>
        <img src={`${process.env.REACT_APP_IMAGE}/receiver.png`} alt="receiver" className="profileImg"/>
      </div>
      <h4>Receiver Profile</h4>
      <p className="profileName">{receiver_name}</p>
      <p className="grytxt">{recciever_phone_number}</p>
      <p>{destination_address}</p>
    </Card>
  );
};
const DispatchCards = () => {
  return (
    <Card className="card">
      <div>
        <img src={`${process.env.REACT_APP_IMAGE}/dispatchRider.png`} alt="dispatcher" className="profileImg"/>
      </div>
      <h4>Dispact Rider Profile</h4>
      <p className="profileName">Mattew Sowemimo (4.5 Rating)</p>
      <p className="grytxt">07068576214</p>
      <p>No 23, Lilly Avenue, VGC, Lagos</p>
    </Card>
  );
};
const DeliveryInfoCards = ({data}) => {
  const {payment, eta, status } = data
  return (
    <Card className="card">
      <h4>Delivery Info</h4>
      <p>Amount Paid : {`NGN ${payment.price}`}</p>
      <p>{`${data.delivery_type.name} Delivery`}</p>
      <p className="grytxt">Payment with {payment.method}</p>
      <p className="grytxt">{status === "complete" && `Delivered on ${eta}`}</p>
    </Card>
  );
};
const PackageInfoCards = ({data}) => {
  return (
    <Card className="card">
      <h4>Package Info</h4>
      <p className="grytxt">Description: {data.item_description}</p>
      <p className="grytxt">{`${data.delivery_time.name} (${data.delivery_time.description})`}</p>
    </Card>
  );
};
const AllCards = ({data}) => {
  const {sender_info, receiver_info} = data
  return (
    <div className="cardWrapper">
      <SenderCards sender_info={sender_info}/>
      <ReceiverCards receiver_info={receiver_info}/>
      <DispatchCards />
      <PackageInfoCards  data={data}/>
      <DeliveryInfoCards data={data}/>
    </div>
  );
};

export default AllCards;
