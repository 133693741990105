import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

 const successNotification = (message) =>{
toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    })
}
 const getSerialNumber = (pageNumber, index, size) =>{
    const i = Number(index) + 1
    const z = Number(size)
    let  num = Number(pageNumber) * z
    return (num + i) - z;
}

const warningNotification = (message) =>{
    toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
}
const currencyFormater = (amount) =>{
    console.log(amount);
    let value = parseFloat(amount) 
    return ((value).toLocaleString('en-US', {
        style: 'currency',
        currency: 'NGN',
      }))
}
 const Utils = {
    warningNotification,
    getSerialNumber,
    successNotification,
    currencyFormater
}
export default Utils