export const settingsTypes = {
   
    GET_PAYMENT_OPTIONS_REQUEST: 'GET_PAYMENT_OPTIONS_REQUEST',
    GET_PAYMENT_OPTIONS_SUCCESS: 'GET_PAYMENT_OPTIONS_SUCCESS',
    GET_PAYMENT_OPTIONS_FAILURE: 'GET_PAYMENT_OPTIONS_FAILURE',

    UPDATE_MULTIPLE_STATUS_REQUEST: 'UPDATE_MULTIPLE_STATUS_REQUEST',
    UPDATE_MULTIPLE_STATUS_SUCCESS: 'UPDATE_MULTIPLE_STATUS_SUCCESS',
    UPDATE_MULTIPLE_STATUS_FAILURE: 'UPDATE_MULTIPLE_STATUS_FAILURE',

    GET_RIDER_SETTINGS_REQUEST: 'GET_RIDER_SETTINGS_REQUEST',
    GET_RIDER_SETTINGS_SUCCESS: 'GET_RIDER_SETTINGS_SUCCESS',
    GET_RIDER_SETTINGS_FAILURE: 'GET_RIDER_SETTINGS_FAILURE',

    UPDATE_RIDER_REQUEST: 'UPDATE_RIDER_REQUEST',
    UPDATE_RIDER_SUCCESS: 'UPDATE_RIDER_SUCCESS',
    UPDATE_RIDER_FAILURE: 'UPDATE_RIDER_FAILURE',

}

export const brandColorTypes = {
    
    GET_BRAND_COLOR_REQUEST: 'GET_BRAND_COLOR_REQUEST',
    GET_BRAND_COLOR_SUCCESS: 'GET_BRAND_COLOR_SUCCESS',
    GET_BRAND_COLOR_FAILURE: 'GET_BRAND_COLOR_FAILURE',

    UPDATE_BRAND_COLOR_REQUEST: 'UPDATE_BRAND_COLOR_REQUEST',
    UPDATE_BRAND_COLOR_SUCCESS: 'UPDATE_BRAND_COLOR_SUCCESS',
    UPDATE_BRAND_COLOR_FAILURE: 'UPDATE_BRAND_COLOR_FAILURE',

}