
import { walletTypes } from "../types";
import axios from "axios";
import { axiosConfig, requestStatus } from "../constants";
import { config } from '../config'
import Utils from "../helpers/utils";


const WALLET_BASE_URL = `${config.testUrl}/wallets`
// dispatchers
const getWallet = (pageRequest) => async (dispatch) =>{
    let reqPagination = pageRequest ? pageRequest : {current: 1, pageSize: 10}
    let url = `${WALLET_BASE_URL}?rows=${reqPagination.pageSize}&page=${reqPagination.current}`
    let header = axiosConfig()
    
    try {
        const response = await axios.get(url, header);
        const data = response.data;
        if(data && data.pagination){
            let payload = dataConstructor(data)
            dispatch(get_wallet(payload))
        }   

    } catch (error) {
        dispatch(get_wallet({status: requestStatus.failed}))
        return Utils.warningNotification('An error occured');
    }
}

const deleteWallet = (id) => async(dispatch) =>{
    let url = `${config.testUrl}/wallet/${id}`
    let header = axiosConfig()

    try {
        const response = await axios.delete(url, header);
        const data = response.data;
        if(data){
            Utils.successNotification('Action Successful')
            dispatch(getWallet())
        }   
    } catch (error) {
        return error && error.response
    }
}
const getChart = (pageRequest) => async (dispatch) =>{
    let url = `${config.testUrl}/wallet/chart?by=${pageRequest}`
    let header = axiosConfig()

    try {
        const response = await axios.get(url, header);
        const data = response.data;
        if(data ){
            dispatch(get_chart(data))
        }   

    } catch (error) {
        return Utils.warningNotification('An error occured');
    }
}
// actions
const get_wallet = payload => {
    return{
        type: walletTypes.GET_WALLET_LIST,
        payload
    }
}
const get_chart = payload => {
    return{
        type: walletTypes.GET_WALLET_CHART,
        payload
    }
}

// helper function
const dataConstructor = (data) =>{
    let pagination = {
        current: data.pagination.current_page,
        total: data.pagination.total,
        pageSize: data.pagination.per_page,
      }
    let payload = {
        data: data.pagination.data,
        pagination,
        status: requestStatus.success
    }
    return payload
}

export const walletActions = {
    getWallet,
    deleteWallet,
    getChart
}