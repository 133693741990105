import { trackOrderTypes } from "../types"

const initState = {}
export const trackOrder =  (state = initState, action) => {
  switch (action.type) {
    case trackOrderTypes.TRACK_ORDER:
      return {
        ...state,
        trackingDetails: action.payload
      }
      case trackOrderTypes.LOADING:
        return {
          ...state,
          isLoading: action.payload
        }
    default:
      return state
  }
}
