import React, { Fragment,useEffect,useState } from 'react'
import { Modal, Switch } from 'antd'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { brandColorActions, settingsActions } from '../../../actions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../components/Loader';
import { SketchPicker } from 'react-color'
import Spinner from '../../../components/Spinner';

const CustomizeApplication = () => {
    const dispatch = useDispatch()
    const settings = useSelector(state=>state.settings)
    const color = useSelector(state=>state.color)
    const [toggle, setToggle] = useState({})
    const [options, setOption] = useState([])
    const [loading,setLoading] = useState(false)

    useEffect(() => {
       dispatch(settingsActions.getPaymentOptions())
    }, [dispatch])

    useEffect(() => {
        dispatch(brandColorActions.getBrandColor())
     }, [dispatch])

    let paymentOptions
    let colorRange = color?.color?.data?.data
    paymentOptions = settings?.settings?.data?.data

    useEffect(()=> {
        let slug = []
        paymentOptions?.forEach(element => {
            slug.push([element.slug,element.status])
        });
        setOption(slug)
    },[paymentOptions,toggle])


  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const showModal2 = () => {
    setIsModalVisible2(true);
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
  };

  const [isModalVisible3, setIsModalVisible3] = useState(false);

  const showModal3 = () => {
    setIsModalVisible3(true);
  };

  const handleCancel3 = () => {
    setIsModalVisible3(false);
  };

 
  const [main, setMain] = useState(colorRange?.['0']?.value ? colorRange?.['0']?.value : '#ffffff')
  const [complementary, setComplementary] = useState(colorRange?.['1']?.value ? colorRange?.['1']?.value : '#ffffff')


  useEffect(() => {
    setMain(colorRange?.['0']?.value ? colorRange?.['0']?.value  : '')
    setComplementary(colorRange?.['1']?.value ? colorRange?.['1']?.value  : '')
 }, [colorRange])

  const handleColor = (e) => {
    setMain(e.hex)
  }
  
  const handleColors = (e) => {
    setComplementary(e.hex)
  }

  const setColor = async () => {
    setLoading(true)

    const query = ({
      'main_brand_color': main,
      'complementary_brand_color' : complementary
    })

    // const formdata = new FormData();
    // Object.entries(query).forEach(([key, value]) => {
    // formdata.append(key, value);
    //   });

    console.log(query)

    const response = await dispatch(brandColorActions.putBrandColor(query))
    if(response.data.status === true) {
    setLoading(false)
        toast.success(response?.data?.message,{
            autoClose: 2000,
            hideProgressBar: true})
    dispatch(brandColorActions.getBrandColor())

    handleCancel2() 
    handleCancel3()
    setLoading(false)
    }
}

// eslint-disable-next-line
const [checked, setChecked] = useState(true)
const handleChange = (checked,i,x) => {
    if(x.status === 1){
        x.status = 0
    setToggle({...checked,x})
    }
    else{
        x.status = 1
    setToggle({...checked,x})

    }
};



const submit = async (e) => {
    e.preventDefault();
    setLoading(true) 

    console.log(options)
    
    const response =  await dispatch(settingsActions.updateMultipleStatus(options))
    if(response.data.status === true) {
        setLoading(false) 
        setTimeout(() => {
        handleCancel()
        }, 1000);
      toast.success(response?.data?.message,{
        autoClose: 2000,
        hideProgressBar: true})
    }
    else {
        toast.error(response?.data?.message,{
          autoClose: 2000,
          hideProgressBar: true })
          setLoading(false) 
        }
    dispatch(settingsActions.getPaymentOptions())
 }
    return (
        <>
         <Modal title={null} closable={true} centered={true} onCancel={handleCancel} visible={isModalVisible} footer={null}>
             <h2 className='font-bold'>Payment Option</h2>
             <p className='text-gray-500'>Please re-view all the details below carefully and confirm all inputs</p>


              {paymentOptions !== '' ? 
              (paymentOptions?.map((x,i)=>(
                  <div key={x?.id} className='mt-8 border border-gray-300 px-4 py-3 text-sm flex flex-row justify-between items-center rounded-md'>
                  <span>{x?.name}</span>
                  <Switch name='flutterwave' key={i} checked={x?.status === 1 ? checked : !checked} onChange={(e)=>handleChange(checked,i,x)} />
              </div>
              ))) : ''} 
                
              

                    <button type='submit' onClick={(e)=>submit(e)} className='w-auto py-4 px-8 mt-8 bg-hex-blue focus:outline-none border-none flex flex-row items-center justify-between text-white rounded-md text-sm'>Confirm {loading && <Loader />}</button>
      </Modal>
      <Modal title={'Edit Main Color'} closable={false} width={400} centered={true} onCancel={handleCancel2} visible={isModalVisible2} footer={null}>
                 <div className='w-full flex flex-col items-center justify-center mx-auto'>
                  
                        <SketchPicker color={main} onChangeComplete={(e)=>handleColor(e)} />

                        <button type='submit' onClick={(e)=>setColor(e)} className='float-right w-auto py-4 px-8 mt-8 bg-hex-blue focus:outline-none border-none flex flex-row items-center justify-between text-white rounded-md text-sm'>Save Color {loading && <Loader />}</button>
                        
                </div>
               
        </Modal>

        <Modal title={'Edit Complementary Color'} closable={false} width={400} centered={true} onCancel={handleCancel3} visible={isModalVisible3} footer={null}>
             <div className='w-full flex flex-col items-center justify-center mx-auto'>

                        <SketchPicker color={complementary} onChangeComplete={(e)=>handleColors(e)} />
                

                        <button type='submit' onClick={(e)=>setColor(e)} className='float-right w-auto py-4 px-8 mt-8 bg-hex-blue focus:outline-none border-none flex flex-row items-center justify-between text-white rounded-md text-sm'>Save Color {loading && <Loader />}</button>
              </div>
        </Modal>
        <Fragment>
            { paymentOptions && colorRange && colorRange?.['0']?.value ? (<div>
                <div className='w-4/6 border border-gray-300 px-4 py-3 text-sm flex flex-row justify-between items-center rounded-md'>
                    <div className='flex flex-row items-center'>
                            <span>Select Main Brand Color</span>
                            <label className='mx-4 p-2 text-xs text-white rounded-md' style={{backgroundColor:main}}>{main}</label>

                    </div>
                        <button onClick={showModal2} className='text-hex-blue'>Edit</button>
                </div>
              
                
                <div>
                </div>
                <div className='mt-6 w-4/6 border border-gray-300 px-4 py-3 text-sm flex flex-row justify-between items-center rounded-md'>
                    <div className='flex flex-row items-center'>
                             <span>Select Complementary Brand Color</span>
                            <label className='mx-4 p-2 text-xs text-white rounded-md' style={{backgroundColor: complementary}}>{complementary}</label>

                    </div>
                    <button onClick={showModal3} className='text-hex-blue'>Edit</button>
                </div>
                <div className='mt-6 w-4/6 border border-gray-300 px-4 py-3 text-sm flex flex-row justify-between items-center rounded-md'>
                    <span>Payment Options</span>
                    <button className='text-hex-blue' onClick={showModal}>Edit</button>
                </div>
            </div>): (<Spinner />)}
        </Fragment>
        </>
    )
}

export default CustomizeApplication
