import React, { Fragment, useEffect,useState } from 'react'
// import { faDownload } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams } from 'react-router'
import { subscriptionActions, subscriptionHistoryActions, subscriptionPayActions } from '../../../../actions'
import { useDispatch,useSelector } from 'react-redux'
import Spinner from '../../../../components/Spinner'
import { toast } from 'react-toastify';
import { PaystackButton } from "react-paystack"
import 'react-toastify/dist/ReactToastify.css';
import { Empty } from 'antd'
import { Link } from 'react-router-dom'


const Plan = () => {
    let {id} = useParams()
    const dispatch = useDispatch()
    const subscription = useSelector(state=>state.subscription)
    const publicKey = process.env.REACT_APP_PAY_KEY
    const subscriptionPay = useSelector(state=>state.subscriptionPay)
    const subscriptionHistory = useSelector(state=>state.subscriptionHistory)
    // eslint-disable-next-line
    const [email, setEmail] = useState(JSON.parse(sessionStorage.getItem('user'))?.email)
    const [name, setName] = useState(JSON.parse(sessionStorage.getItem('user'))?.full_name)
    const [phone, setPhone] = useState(JSON.parse(sessionStorage.getItem('user'))?.phone_no)
    let referenceNumber = subscriptionPay?.subscriptionPay?.data?.data?.reference_number
    let merchantCode = subscriptionPay?.subscriptionPay?.data?.data?.merchant_code
    let subscriptionPlanID = subscriptionPay?.subscriptionPay?.data?.data?.subscription_plan_id
    // const [paymentMethod,setPaymentMethod] = useState('')
    // const [status,setStatus] = useState('')




    useEffect(() => {
        dispatch(subscriptionActions.getSingleSubscription(id))
    }, [dispatch,id])

    useEffect(() => {
        dispatch(subscriptionHistoryActions.getSubscriptionHistory(id))
    }, [dispatch,id])

//   console.log(subscriptionHistory)

    let subDetails = subscription?.subscription?.data
    let subHistory = subscriptionHistory?.subscriptionHistory?.data 

    useEffect(() => {
        dispatch(subscriptionPayActions.postSubscription(id))
    // eslint-disable-next-line
    }, [dispatch,id])

    useEffect(() => {
        setAmount(parseInt(subscriptionPay?.subscriptionPay?.data?.data?.amount) * 100)
    }, [subscriptionPay])

    const [amount, setAmount] = useState(parseInt(subscriptionPay?.subscriptionPay?.data?.data?.amount) * 100)



    const componentProps = {
        email,
        amount,
        metadata: {
          name,
          phone,
        //   "merchant_code": merchantCode,
        //   "subscription_plan_id": subscriptionPlanID,
          "custom_fields": [
            {
              "display_name":"merchant_code",
              "variable_name": "merchant_code",
              "value": merchantCode
            },
            {
                "display_name":"subscription_plan_id",
                "variable_name": "subscription_plan_id",
                "value": subscriptionPlanID
              }
          ]

        },
        publicKey,
        text: "Subscribe",
        onSuccess: () =>  {
            setName("")
            setPhone("")

            const query = ({
                'status': 'success',
                'payment_method': 'paystack',
                'reference_number': referenceNumber,
                'amount': amount/100,
                'subscription_plan_id': subscriptionPlanID,
                
            })

        // eslint-disable-next-line
        const response = dispatch(subscriptionActions.putSubscription(id,query))

        toast.success('Transaction successful',{
            autoClose: 2000,
            hideProgressBar: true
        })

        dispatch(subscriptionHistoryActions.getSubscriptionHistory(id))

        }
          




        
        // onClose: () => alert("Wait! You need this oil, don't go!!!!"),
      }
    return (
        <Fragment>
            {subDetails?.uuid && subscriptionPay?.subscriptionPay?.data?.data?.amount !== undefined ? (<div className='mt-8 w-11/12'>
                
                <div className='bg-white w-full p-8 rounded-md'>

                    <h1 className='font-bold text-lg'>{subDetails?.name}</h1>
                    <span className='font-bold flex flex-row text-black'>N{subDetails?.amount} /<p className='text-gray-400'> annum</p></span>

                        <div className='text-hex-blue text-xs mt-16 font-bold'>View Features List</div>

                        {subDetails?.features?.map((y)=>
                        (<div className='my-6 flex flex-row items-center text-xs'>
                            <img src={`${process.env.REACT_APP_IMAGE}/checkbox.svg`} className='w-3 mr-4' alt='' />
                            <span className='text-black'>{y.name}</span>
                        </div>))}
                        <div>
                              <Link to='/merchant-admin/setting/subscription' className='bg-blue-100 mt-4 px-8 py-4 focus:outline-none border-none text-hex-blue rounded-md text-xs font-bold hover:text-white'>Upgrade Plan</Link>

                              {/* <span className='text-hex-blue text-xs font-bold ml-4'>Cancel Subscription</span> */}

                             
                            
                        </div>

                        

                </div>

                <PaystackButton  className='bg-blue-100 mt-4 px-8 py-4 focus:outline-none border-none text-hex-blue rounded-md text-xs font-bold' {...componentProps} />

                    {/* <div className='mt-4' onClick={(e)=>handlePay(e)}>
                              <button style={{backgroundColor:'#cddfec'}} className='mt-4 px-8 py-4 focus:outline-none border-none text-hex-blue rounded-md text-xs font-bold'>Make Payment</button>
                        </div> */}


                {/* <div className='bg-white w-full p-8 mt-8 rounded-md'>

                  <div className='w-full flex flex-row justify-between items-center'>
                        <h1 className='font-bold text-lg'>Payment Method </h1>
                        <button>
                                <FontAwesomeIcon icon={faPlusCircle} size='2x' color='#006fc1' />
                        </button>
                    </div>

                    <div className='mt-8 flex flex-row'>
                        <div className='border border-gray-100 flex justify-center items-center h-8 w-8 rounded-full'>
                            <img src={Cards} alt='' className='w-6' />
                        </div>

                        <div className='flex flex-col ml-8'>
                            <span className='text-gray-500'>Debit Card</span>
                            <span className='mt-4'>****   ****    ****   8764</span>
                            <span className='mt-4'>John C. Wayne</span>
                        </div>
                    </div>

                        <div className='text-hex-blue text-xs mt-16 font-bold'>Change payment method</div>


                </div> */}


                <div className='mt-8 bg-white w-full p-8 rounded-md'>
                    {subHistory?.message !== 'No Result found' ?  (<div>
                    
                    {/* <h1 className='font-bold text-lg'>Invoices</h1>
                    <span className='font-bold text-gray-400'>Latest Invoice</span>

                        <div className='mt-4 bg-light-gray flex flex-row justify-between items-center p-4 font-bold rounded-md'>
                            <span className='font-bold'>03 March 2021</span>
                            <span className='font-bold text-gray-500'>Advanced Plan - Monthly</span>
                            <span className='font-bold'>N500.00</span>
                            <button className='norder-none px-2 py-1 rounded-sm text-xs' style={{color:'#FFB000',backgroundColor:'#faeac7'}}>PAID</button>
                            <button>
                                <FontAwesomeIcon icon={faDownload} size='sm' color='#777777' />
                            </button>
                        </div> */}

                        <div className='font-bold text-gray-400 mt-4'>History</div>

                        {subHistory?.data?.map((x,i)=>(<div key={i} className='mt-2 bg-light-gray flex flex-row justify-between items-center p-4 font-bold rounded-md'>
                            <span className='font-bold'>{x?.created_at?.split('T')['0']}</span>
                            <span className='font-bold text-gray-500'>{x?.subscription_plan?.name} - Monthly</span>
                            <span className='font-bold'>{(parseFloat(x?.amount)).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'NGN',
                                        })}</span>
                            {x?.status === 'successful' ? <button className='norder-none px-2 py-1 rounded-sm text-xs' style={{color:'#035D9F',backgroundColor:'#c8dae7'}}>PAID</button> : <button className='norder-none px-2 py-1 rounded-sm text-xs bg-yellow-300' style={{color:'#035D9F'}}>UNPAID</button>}
                            
                        </div>))}

                        </div>) : (<Empty />)}

                    </div>


            </div>) : (<Spinner />)}
        </Fragment>
    )
}

export default Plan
