import { deliveryService, deliveryTimeService, interStateDeliveryService, intraStateDeliveryService ,paymentDistanceService,rideTypeService, summaryService } from "../services"
import { DeliveryTypes, interStateDeliveryTypes, intraStateDeliveryTypes, paymentDeliveryTypes, RideOptionsTypes, RideTypes } from "../types"
import { DeliveryOptionsTypes, DeliverySummaryTypes, DeliveryTimeTypes,DeliveryTimeOptionsTypes } from "../types/delivery.types"


export const interStateDeliveryActions = {
    getInterStateDelivery,
    putInterStateDelivery
}

export const intraStateDeliveryActions = {
    getIntraStateDelivery,
    putIntraStateDelivery
}

export const paymentDistanceActions = {
    getDistancePayment,
    putDistancePayment
}
export const deliveryActions = {
  getPackageSize,
  updatePackageSize,
  addPackageSize,
  deletePackageSize
}

export const deliveryOptionsActions ={
  getSinglePackageSize
}

export const rideActions = {
  getRideType,
  updateRideType,
  addRideType,
  deleteRideType
}

export const rideOptionsActions ={
  getSingleRideType
}

export const deliveryTimeActions = {
  getDeliveryTime,
  updateDeliveryTime,
  addDeliveryTime,
  deleteDeliveryTime
}

export const deliveryTimeOptionsActions ={
  getSingleDeliveryTime
}

export const DeliverySummaryActions = {
  getDeliverySummary
}

function getInterStateDelivery () {
    return async dispatch => {
      dispatch(request())
  
      try {
        const interState = await interStateDeliveryService.getInterStateDelivery()
        dispatch(success(interState))
        return interState

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request () { return { type: interStateDeliveryTypes.GET_INTER_STATE_REQUEST } }
    function success (interState) { return { type: interStateDeliveryTypes.GET_INTER_STATE_SUCCESS, interState} }
    function failure (error) { return { type: interStateDeliveryTypes.GET_INTER_STATE_FAILURE, error } }
    
  }


  function putInterStateDelivery (value) {
    return async dispatch => {
      dispatch(request(value))
  
      try {
        const interState = await interStateDeliveryService.putInterStateDelivery(value)
        dispatch(success(interState))
        return interState

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (value) { return { type: interStateDeliveryTypes.PUT_INTER_STATE_REQUEST ,value} }
    function success (interState) { return { type: interStateDeliveryTypes.PUT_INTER_STATE_SUCCESS, interState} }
    function failure (error) { return { type: interStateDeliveryTypes.PUT_INTER_STATE_FAILURE, error } }
    
  }

  function getIntraStateDelivery () {
    return async dispatch => {
      dispatch(request())
  
      try {
        const intraState = await intraStateDeliveryService.getIntraStateDelivery()
        dispatch(success(intraState))
        return intraState

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request () { return { type: intraStateDeliveryTypes.GET_INTRA_STATE_REQUEST } }
    function success (intraState) { return { type: intraStateDeliveryTypes.GET_INTRA_STATE_SUCCESS, intraState} }
    function failure (error) { return { type: intraStateDeliveryTypes.GET_INTRA_STATE_FAILURE, error } }
    
  }

  function putIntraStateDelivery (value) {
    return async dispatch => {
      dispatch(request(value))
  
      try {
        const intraState = await intraStateDeliveryService.putIntraStateDelivery(value)
        dispatch(success(intraState))
        return intraState

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (value) { return { type: intraStateDeliveryTypes.PUT_INTRA_STATE_REQUEST,value } }
    function success (intraState) { return { type: intraStateDeliveryTypes.PUT_INTRA_STATE_SUCCESS, intraState} }
    function failure (error) { return { type: intraStateDeliveryTypes.PUT_INTRA_STATE_FAILURE, error } }
    
  }


  function getDistancePayment () {
    return async dispatch => {
      dispatch(request())
  
      try {
        const distance = await paymentDistanceService.getDistancePayment()
        dispatch(success(distance))
        return distance

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request () { return { type: paymentDeliveryTypes.GET_PAYMENT_DELIVERY_REQUEST } }
    function success (distance) { return { type: paymentDeliveryTypes.GET_PAYMENT_DELIVERY_SUCCESS, distance} }
    function failure (error) { return { type: paymentDeliveryTypes.GET_PAYMENT_DELIVERY_FAILURE, error } }
    
  }

  function putDistancePayment (value) {
    return async dispatch => {
      dispatch(request(value))
  
      try {
        const distance = await paymentDistanceService.putDistancePayment(value)
        dispatch(success(distance))
        return distance

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (value) { return { type: paymentDeliveryTypes.PUT_PAYMENT_DELIVERY_REQUEST , value } }
    function success (distance) { return { type: paymentDeliveryTypes.PUT_PAYMENT_DELIVERY_SUCCESS, distance} }
    function failure (error) { return { type: paymentDeliveryTypes.PUT_PAYMENT_DELIVERY_FAILURE, error } }
    
  }


  function getPackageSize () {
    return async dispatch => {
      dispatch(request())
  
      try {
        const delivery = await deliveryService.getPackageSize()
        dispatch(success(delivery))
        return delivery

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request () { return { type: DeliveryTypes.GET_PACKAGE_SIZE_REQUEST } }
    function success (delivery) { return { type: DeliveryTypes.GET_PACKAGE_SIZE_SUCCESS, delivery} }
    function failure (error) { return { type: DeliveryTypes.GET_PACKAGE_SIZE_FAILURE, error } }
    
  }

  function getSinglePackageSize (id) {
    return async dispatch => {
      dispatch(request(id))
  
      try {
        const deliveryOptions = await deliveryService.getSinglePackageSize(id)
        dispatch(success(deliveryOptions))
        return deliveryOptions

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (id) { return { type: DeliveryOptionsTypes.GET_SINGLE_PACKAGE_REQUEST , id} }
    function success (deliveryOptions) { return { type: DeliveryOptionsTypes.GET_SINGLE_PACKAGE_SUCCESS, deliveryOptions} }
    function failure (error) { return { type: DeliveryOptionsTypes.GET_SINGLE_PACKAGE_FAILURE, error } }
    
  }

  function deletePackageSize (id) {
    return async dispatch => {
      dispatch(request(id))
  
      try {
        const delivery = await deliveryService.deletePackageSize(id)
        dispatch(success(delivery))
        return delivery

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (id) { return { type: DeliveryTypes.DELETE_PACKAGE_SIZE_REQUEST , id} }
    function success (delivery) { return { type: DeliveryTypes.DELETE_PACKAGE_SIZE_SUCCESS, delivery} }
    function failure (error) { return { type: DeliveryTypes.DELETE_PACKAGE_SIZE_FAILURE, error } }
    
  }

  function addPackageSize (formdata) {
    return async dispatch => {
      dispatch(request(formdata))
  
      try {
        const delivery = await deliveryService.addPackageSize(formdata)
        dispatch(success(delivery))
        return delivery

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (formdata) { return { type: DeliveryTypes.ADD_PACKAGE_SIZE_REQUEST , formdata } }
    function success (delivery) { return { type: DeliveryTypes.ADD_PACKAGE_SIZE_SUCCESS, delivery} }
    function failure (error) { return { type: DeliveryTypes.ADD_PACKAGE_SIZE_FAILURE, error } }
    
  }

  function updatePackageSize (id,formdata) {
    return async dispatch => {
      dispatch(request(id,formdata))
  
      try {
        const delivery = await deliveryService.updatePackageSize(id,formdata)
        dispatch(success(delivery))
        return delivery

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (id,formdata) { return { type: DeliveryTypes.UPDATE_PACKAGE_SIZE_REQUEST, id, formdata } }
    function success (delivery) { return { type: DeliveryTypes.ADD_PACKAGE_SIZE_SUCCESS, delivery} }
    function failure (error) { return { type: DeliveryTypes.UPDATE_PACKAGE_SIZE_FAILURE, error } }
    
  }

  function getRideType () {
    return async dispatch => {
      dispatch(request())
  
      try {
        const ride = await rideTypeService.getRideType()
        dispatch(success(ride))
        return ride

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request () { return { type: RideTypes.GET_RIDE_TYPES_REQUEST } }
    function success (ride) { return { type: RideTypes.GET_RIDE_TYPES_SUCCESS, ride} }
    function failure (error) { return { type: RideTypes.GET_RIDE_TYPES_FAILURE, error } }
    
  }

  function addRideType (formdata) {
    return async dispatch => {
      dispatch(request(formdata))
  
      try {
        const ride = await rideTypeService.addRideType(formdata)
        dispatch(success(ride))
        return ride

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (formdata) { return { type: RideTypes.ADD_RIDE_TYPES_REQUEST,formdata } }
    function success (ride) { return { type: RideTypes.ADD_RIDE_TYPES_SUCCESS, ride} }
    function failure (error) { return { type: RideTypes.ADD_RIDE_TYPES_FAILURE, error } }
    
  }

  function updateRideType (id,formdata) {
    return async dispatch => {
      dispatch(request(id,formdata))
  
      try {
        const ride = await rideTypeService.updateRideType(id,formdata)
        dispatch(success(ride))
        return ride

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (id,formdata) { return { type: RideTypes.UPDATE_RIDE_TYPES_REQUEST,id,formdata } }
    function success (ride) { return { type: RideTypes.UPDATE_RIDE_TYPES_SUCCESS, ride} }
    function failure (error) { return { type: RideTypes.UPDATE_RIDE_TYPES_FAILURE, error } }
    
  }

  function deleteRideType (id) {
    return async dispatch => {
      dispatch(request(id))
  
      try {
        const ride = await rideTypeService.deleteRideType(id)
        dispatch(success(ride))
        return ride

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (id) { return { type: RideTypes.DELETE_RIDE_TYPES_REQUEST,id } }
    function success (ride) { return { type: RideTypes.DELETE_RIDE_TYPES_SUCCESS, ride} }
    function failure (error) { return { type: RideTypes.DELETE_RIDE_TYPES_FAILURE, error } }
    
  }

  function getSingleRideType (id) {
    return async dispatch => {
      dispatch(request(id))
  
      try {
        const rideOptions = await rideTypeService.getSingleRideType(id)
        dispatch(success(rideOptions))
        return rideOptions

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (id) { return { type: RideOptionsTypes.GET_SINGLE_RIDE_REQUEST , id} }
    function success (rideOptions) { return { type: RideOptionsTypes.GET_SINGLE_RIDE_SUCCESS, rideOptions} }
    function failure (error) { return { type: RideOptionsTypes.GET_SINGLE_RIDE_FAILURE, error } }
    
  }


  function getDeliveryTime () {
    return async dispatch => {
      dispatch(request())
  
      try {
        const deliveryTime = await deliveryTimeService.getDeliveryTime()
        dispatch(success(deliveryTime))
        return deliveryTime

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request () { return { type: DeliveryTimeTypes.GET_DELIVERY_TIMES_REQUEST } }
    function success (deliveryTime) { return { type: DeliveryTimeTypes.GET_DELIVERY_TIMES_SUCCESS, deliveryTime} }
    function failure (error) { return { type: DeliveryTimeTypes.GET_DELIVERY_TIMES_FAILURE, error } }
    
  }

  function addDeliveryTime (formdata) {
    return async dispatch => {
      dispatch(request(formdata))
  
      try {
        const deliveryTime = await deliveryTimeService.addDeliveryTime(formdata)
        dispatch(success(deliveryTime))
        return deliveryTime

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (formdata) { return { type: DeliveryTimeTypes.ADD_DELIVERY_TIMES_REQUEST,formdata } }
    function success (deliveryTime) { return { type: DeliveryTimeTypes.ADD_DELIVERY_TIMES_SUCCESS, deliveryTime} }
    function failure (error) { return { type: DeliveryTimeTypes.ADD_DELIVERY_TIMES_FAILURE, error } }
    
  }

  function updateDeliveryTime (id,formdata) {
    return async dispatch => {
      dispatch(request(id,formdata))
  
      try {
        const deliveryTime = await deliveryTimeService.updateDeliveryTime(id,formdata)
        dispatch(success(deliveryTime))
        return deliveryTime

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (id,formdata) { return { type: DeliveryTimeTypes.UPDATE_DELIVERY_TIMES_REQUEST,id,formdata } }
    function success (deliveryTime) { return { type: DeliveryTimeTypes.UPDATE_DELIVERY_TIMES_SUCCESS, deliveryTime} }
    function failure (error) { return { type: DeliveryTimeTypes.UPDATE_DELIVERY_TIMES_FAILURE, error } }
    
  }

  function deleteDeliveryTime (id) {
    return async dispatch => {
      dispatch(request(id))
  
      try {
        const deliveryTime = await deliveryTimeService.deleteDeliveryTime(id)
        dispatch(success(deliveryTime))
        return deliveryTime

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (id) { return { type: DeliveryTimeTypes.DELETE_DELIVERY_TIMES_REQUEST,id } }
    function success (deliveryTime) { return { type: DeliveryTimeTypes.DELETE_DELIVERY_TIMES_SUCCESS, deliveryTime} }
    function failure (error) { return { type: DeliveryTimeTypes.DELETE_DELIVERY_TIMES_FAILURE, error } }
    
  }


  function getSingleDeliveryTime (id) {
    return async dispatch => {
      dispatch(request(id))
  
      try {
        const deliveryTimeOptions = await deliveryTimeService.getSingleDeliveryTime(id)
        dispatch(success(deliveryTimeOptions))
        return deliveryTimeOptions

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (id) { return { type: DeliveryTimeOptionsTypes.GET_SINGLE_DELIVERY_REQUEST,id } }
    function success (deliveryTimeOptions) { return { type: DeliveryTimeOptionsTypes.GET_SINGLE_DELIVERY_SUCCESS, deliveryTimeOptions} }
    function failure (error) { return { type: DeliveryTimeOptionsTypes.GET_SINGLE_DELIVERY_FAILURE, error } }
    
  }

  function getDeliverySummary () {
    return async dispatch => {
      dispatch(request())
  
      try {
        const summary = await summaryService.getDeliverySummary()
        dispatch(success(summary))
        return summary

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request () { return { type: DeliverySummaryTypes.GET_DELIVERY_SUMMARY_REQUEST } }
    function success (summary) { return { type: DeliverySummaryTypes.GET_DELIVERY_SUMMARY_SUCCESS, summary} }
    function failure (error) { return { type: DeliverySummaryTypes.GET_DELIVERY_SUMMARY_FAILURE, error } }
    
  }





