import React from 'react';
import { Card, Divider } from 'antd';
import DropDown from './DropDown'

const Mobilecard = ({user}) => {
    const cards = user.data.map((data) => {
        let color = data.status === "active" ? '#7AAC52' :  '#E3000F';
      return (
        <Card key={data.id} className="my4 userCard">
          <div className="flex space-btw">
            <p style={{color: "#006FC1"}}><b>{data.full_name}</b></p>
            <p style={{color}}>{data.status}</p>
          </div>
          <div>
            <span>{data.email}</span>
            <Divider type="vertical" />
            <span className="greyText">{data.phone}</span>
          </div>
          <div className="flex space-btw">
          <p>{data.location}</p>
          <DropDown data={data}/>
          </div>
        </Card>
      );
    });
    return (
        <div>
            {cards}
        </div>
    );
}

export default Mobilecard;
