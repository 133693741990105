import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  roleActions,
  priviledgeActions,
  assignPriviledgeActions,
} from "../../../actions";
import Spinner from "../../../components/Spinner";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PriviledgeRoles = () => {
  const dispatch = useDispatch();
  const role = useSelector((state) => state.role);
  const priviledge = useSelector((state) => state.priviledge);
  // eslint-disable-next-line
  const assignPriviledge = useSelector((state) => state.assignPriviledge);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [candidate, setCandidate] = useState([]);
  const [active, setActive] = useState(null);
  const [activePrivileges, setActivePrivileges] = useState(null);

  useEffect(() => {
    dispatch(roleActions.getRoles());
  }, [dispatch]);

  useEffect(() => {
    dispatch(priviledgeActions.getAllPriviledges());
  }, [dispatch]);

  let roles = role?.role?.data?.data;
  let priviledgeList = priviledge?.priviledge?.data?.data;
  // console.log(priviledgeList);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setName("");
    setCandidate([]);
  };

  const showModal2 = async () => {
    setIsModalVisible2(true);
    setCandidate([]);
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
    dispatch(priviledgeActions.getAllPriviledges());
  };

  const openModal = async (e, x) => {
    // console.log(x);

    const response = await dispatch(
      assignPriviledgeActions.getAssignedPriviledges(x?.id)
    );
    setActivePrivileges(response?.data?.privileges);
    setActive(x);
    // console.log(activePrivileges, priviledgeList);
    showModal2();
  };

  const [name, setName] = useState("");

  const handleToggle = (x, i) => {
    if (!candidate.find((id) => id === x)) {
      setCandidate([...candidate, x]);
    } else {
      setCandidate(candidate.filter((id) => id !== x));
    }
  };

  const handleToggleAct = (x, i, all) => {
    if (!!activePrivileges?.find((y) => y?.id === x)) {
      setActivePrivileges(activePrivileges.filter((y) => y?.id !== x));
    } else {
      setActivePrivileges((z) => [...z, all]);
    }
  };
  console.log(activePrivileges);

  const trySubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formdata = {
      name: name,
      privilege_ids: candidate,
    };

    const response = await dispatch(roleActions.createRoles(formdata));
    if (response.data.success === true) {
      setLoading(false);

      setCandidate([]);
      toast.success("Added successfully", {
        autoClose: 2000,
        hideProgressBar: true,
      });

      handleCancel();
    } else {
      toast.error(response?.data?.message, {
        autoClose: 2000,
        hideProgressBar: true,
      });
      setLoading(false);
    }
    dispatch(roleActions.getRoles());
  };

  const trySubmitAct = async (e) => {
    e.preventDefault();
    setLoading(true);

    let angle = [];
    activePrivileges?.forEach((element) => {
      angle?.push(element?.id);
    });

    console.log(angle);

    const formdata = {
      privilege_ids: angle,
    };

    console.log(active?.id, formdata);

    const response = await dispatch(
      priviledgeActions.putPriviledges(active?.id, formdata)
    );
    if (response.data.status_code === 201) {
      setLoading(false);

      // setCandidate([]);
      toast.success("Added successfully", {
        autoClose: 2000,
        hideProgressBar: true,
      });

      handleCancel2();
    } else {
      toast.error(response?.data?.message, {
        autoClose: 2000,
        hideProgressBar: true,
      });
      setLoading(false);
    }
    dispatch(roleActions.getRoles());
  };

  return (
    <Fragment>
      <Modal
        title={null}
        closable={true}
        centered={true}
        onCancel={handleCancel}
        visible={isModalVisible}
        footer={null}
      >
        <h1 className="font-bold text-lg">Add New Role</h1>
        <p className="text-gray-500">
          Please view all the informations in the details below
        </p>

        <form className="mt-4" onSubmit={(e) => trySubmit(e)}>
          <div>
            <label className="font-bold text-xs">Role Name</label>
            <input
              type={"text"}
              className="regular-form"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <p className="mt-4 font-bold">Features List</p>

          {priviledgeList?.map((x, i) => (
            <div className="flex flex-row items-center">
              <input
                type="checkbox"
                checked={priviledgeList.find(
                  (id) => id === activePrivileges?.id
                )}
                onChange={() => handleToggle(x?.id, i)}
              />
              <span className="ml-4">{x?.name}</span>
            </div>
          ))}

          <div>
            <button
              type="submit"
              className="mt-12 w-full bg-hex-blue text-white py-4 rounded-sm font-bold flex items-center justify-center"
            >
              Submit {loading && <Loader />}
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        title={null}
        closable={true}
        centered={true}
        onCancel={handleCancel2}
        visible={isModalVisible2}
        footer={null}
      >
        <div className="font-bold capitalize text-lg">{active?.name}</div>

        <p className="mt-2 text-gray-400 mb-8">
          Please re-view all the details below carefully and confirm all inputs
        </p>

        {priviledgeList?.map((x, i) => (
          <div className="flex flex-row items-center">
            <input
              type="checkbox"
              checked={!!activePrivileges?.find((y) => y?.id === x?.id)}
              // checked={priviledgeList?.find(
              //   (id) => id === activePrivileges?.id
              // )}
              onChange={() => {
                handleToggleAct(
                  x?.id,
                  i,
                  priviledgeList?.find((y) => y?.id === x?.id)
                );
              }}
            />
            <span className="ml-4">{x?.name}</span>
          </div>
        ))}

        <div>
          <button
            onClick={(e) => trySubmitAct(e)}
            type="submit"
            className="mt-12 w-full bg-hex-blue text-white py-4 rounded-sm font-bold flex items-center justify-center"
          >
            Submit {loading && <Loader />}
          </button>
        </div>
      </Modal>

      <div className="w-full flex flex-row justify-between items-center">
        <h1 className="font-bold text-hex-blue">Roles</h1>
        <button onClick={() => showModal()}>
          <FontAwesomeIcon icon={faPlusCircle} size="2x" color="#006fc1" />
        </button>
      </div>

      {roles ? (
        <div>
          {roles?.map((x, i) => (
            <div className="mt-4 w-4/6 border border-gray-300 px-4 py-3 text-sm flex flex-row justify-between items-center rounded-md">
              <span className="uppercase">{x?.name} Level</span>
              <button
                onClick={(e) => openModal(e, x)}
                className="text-hex-blue"
              >
                Edit
              </button>
            </div>
          ))}
        </div>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};

export default PriviledgeRoles;
