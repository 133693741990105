import axios from "axios";
import { config } from "../config";
import { authHeader } from "../helpers";

export const roleService = {
  getRoles,
  createRole,
  getAllPriviledges,
  getAssignedPriviledges,
  putPriviledges,
};

async function getRoles() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  const response = await axios(`${config.testUrl}/roles`, requestOptions);
  return response;
}

async function createRole(formdata) {
  const requestOptions = {
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  const response = await axios.post(
    `${config.testUrl}/role/create`,
    formdata,
    requestOptions
  );
  return response;
}

async function getAllPriviledges() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  const response = await axios(`${config.testUrl}/privileges`, requestOptions);
  return response;
}

async function getAssignedPriviledges(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  const response = await axios(
    `${config.testUrl}/role/${id}/privileges`,
    requestOptions
  );
  return response;
}

async function putPriviledges(id, formdata) {
  const requestOptions = {
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  const response = await axios.put(
    `${config.testUrl}/role/${id}/privileges`,
    formdata,
    requestOptions
  );
  return response;
}
