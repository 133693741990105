import React from "react";
import { Table, Tag } from "antd";
import DropDown from "./DropDown";
import MobileCardWallet from "./MobileCardWallet";
import EmptyData from "./EmptyData"
import { walletActions } from '../../../actions'
import { useDispatch, useSelector } from "react-redux";
import Utils from '../../../helpers/utils';

const WalletTable = () => {
  const columns = [
    {
      title: 'S/N',
      key: 'id',
      render:(item, record, index)=>{
        return <p>{Utils.getSerialNumber(pagination.current, index, pagination.pageSize)}</p>
      }
    },
    {
      title: "Sender Name",
      dataIndex: "user",
      key: "sender_name",
      render:(user)=>{
        return <p>{user.full_name}</p>
      }
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => {
        let val = Utils.currencyFormater(amount)
        return (<p>{val}</p>);
      }
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: status => {
        let stat = status || "pending"
        let color = stat === "successful" ? '#7AAC52' : stat === "pending" ? "#ffc107" : '#E3000F';
         return(
            <Tag color={color} className="capitalize">
            {stat}
          </Tag>
         )
    },
    },
    {
      title: "Payment Channel",
      dataIndex: "payment_channel",
      key: "payment_channel",
      render: (payment_channel) => {
        let channel = payment_channel || "-"
        return (<p className="capitalize">{channel}</p>);
      }
    },
    {
      title: "Descripton",
      dataIndex: "description",
      key: "description"
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date"
    },
    {
      title: "Action",
      key: "row",
      render: (row) => {
        return <DropDown data={row} />;
      }
    }
  ];

  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const walletList = wallet?.walletList;

  const handleTableChange = (pagination) => {
    dispatch(walletActions.getWallet(pagination));
  };
  const pagination =  walletList?.pagination
  return (
    <>
      {
      walletList?.data?.length > 0 ? (
        <div className="my4">
          <h3 className="greyText">RECENT PAYMENT</h3>

          <div className="d-sm-none">
            <Table
              dataSource={walletList?.data}
              columns={columns}
              className="walletTable"
              pagination={walletList?.pagination}
              onChange={handleTableChange}
            />
          </div>
          <div className="d-lg-none">
            <MobileCardWallet data={walletList?.data} />
          </div>
        </div>
      ) : (
        <EmptyData/>
      )}
    </>
  );
};
export default WalletTable;
