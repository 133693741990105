import axios from "axios"
import { config } from '../config'
import { authHeader } from "../helpers"

export const chartService = {
    getChartsInfo
}

async function getChartsInfo (params,month,year) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    // if(params !== ''){
    const response = params === '' ? await axios(`${config.testUrl}/dashboard/chart?month=${month}&&year=${year}`, requestOptions) :  await axios(`${config.testUrl}/dashboard/chart?by=${params}`, requestOptions)
    return response;
    
}
