import React, { Fragment, useState ,useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { profileActions } from '../../../../actions'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../../components/Loader';
import Spinner from '../../../../components/Spinner';

const EditProfile = () => {
  const dispatch = useDispatch()
    const [loading,setLoading] = useState(false)
    const profile = useSelector(state=>state.profile)

    useEffect(() => {
     dispatch(profileActions.getUserProfile())
    }, [dispatch])

    let profileList = profile?.profile?.data?.data

    useEffect(() => {
      setQuery({
        email: profileList?.email ? profileList?.email : '',
        full_name: profileList?.full_name ? profileList?.full_name :'',
        phone_no: profileList?.phone_no ? profileList?.phone_no :'',
        address: profileList?.address ? profileList?.address :'',
        city: profileList?.city ? profileList?.city : '',
        state: profileList?.state ? profileList?.state :'',
        country: profileList?.country ? profileList?.country :'',
        zip_code: profileList?.zip_code ? profileList?.zip_code :''
    })
  }, [profileList])

    const [query, setQuery] = useState({
        email: profileList?.email ? profileList?.email : '',
        full_name: profileList?.full_name ? profileList?.full_name :'',
        phone_no: profileList?.phone_no ? profileList?.phone_no :'',
        address: profileList?.address ? profileList?.address :'',
        city: profileList?.city ? profileList?.city : '',
        state: profileList?.state ? profileList?.state :'',
        country: profileList?.country ? profileList?.country :'',
        zip_code: profileList?.zip_code ? profileList?.zip_code :''
      })

        const handleInput = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            setQuery((prevState) => ({
                ...prevState,
                [name]: value
            }));
            };

        const submit = async (e) => {
            e.preventDefault();
            setLoading(true) 
    
            const formdata = new FormData();
              Object.entries(query).forEach(([key, value]) => {
              formdata.append(key, value);
                });

            
            const response =  await dispatch(profileActions.postUserProfile(formdata))
            if(response.data.status === true) {
                setLoading(false) 
              toast.success(response?.data?.message,{
                autoClose: 2000,
                hideProgressBar: true})
            }
            else {
                toast.error(response?.data?.message,{
                  autoClose: 2000,
                  hideProgressBar: true })
                  setLoading(false) 
                }
                dispatch(profileActions.getUserProfile())
         }
    return (
        <Fragment>
           {profileList && profileList !== '' ? 
           (<form onSubmit={(e)=>submit(e)} className='mt-8'>

            <div className='grid grid-cols-3 gap-8'>
                 <div>
                    <label className='font-bold'>Full Name</label>
                    <input type={'text'} className='register-text-box' name='full_name' value={query.full_name} onChange={(e)=>handleInput(e)} />
                  </div>

                  <div>
                    <label className='font-bold'>Email Address</label>
                    <input type={'text'} className='register-text-box' name='email' value={query.email} onChange={(e)=>handleInput(e)} />
                  </div>

                  <div></div>

                  <div>
                    <label className='font-bold'>Phone Number (234...)</label>
                    <input type={'number'} className='register-text-box' name='phone_no' value={query.phone_no} onChange={(e)=>handleInput(e)} />
                  </div>

                  <div>
                    <label className='font-bold'>Address</label>
                    <input type={'text'} className='register-text-box' name='address' value={query.address} onChange={(e)=>handleInput(e)} />
                  </div>

                  <div></div>

                  
                  <div>
                    <label className='font-bold'>City</label>
                    <input type={'text'} className='register-text-box' name='city' value={query.city} onChange={(e)=>handleInput(e)} />
                  </div>

                  <div>
                    <label className='font-bold'>State</label>
                    <input type={'text'} className='register-text-box' name='state' value={query.state} onChange={(e)=>handleInput(e)} />
                  </div>

                  <div></div>

                  <div>
                    <label className='font-bold'>Country</label>
                    <input type={'text'} className='register-text-box' name='country' value={query.country} onChange={(e)=>handleInput(e)} />
                  </div>

                  <div>
                    <label className='font-bold'>Zip Code</label>
                    <input type={'text'} className='register-text-box' name='zip_code' value={query.zip_code} onChange={(e)=>handleInput(e)} />
                  </div>

                  <div></div>

                  {/* <div>
                    <label className='font-bold'>Password</label>
                    <input type={'text'} className='register-text-box' name='current_password' value={query.current_password} onChange={(e)=>handleInput(e)} />
                  </div> */}

            </div>

            <button type='submit' className='w-auto py-4 px-8 mt-8 bg-hex-blue focus:outline-none border-none flex flex-row items-center justify-between text-white rounded-md text-sm'>Save Settings {loading && <Loader />}</button>



            </form>): (<Spinner />)} 
        </Fragment>
    )
}

export default EditProfile
