import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import Cards from './Cards'
import DeliveryTime from './DeliveryTime'
import PackageSize from './PackageSize'
import PaymentCategory from './PaymentCategory'
import PaymentDistance from './PaymentDistance'
import RideType from './RideType'

const ManageOrders = () => {
    return (
        <Fragment>
            <section>
            <Switch>
                    <Route exact path={'/merchant-admin/setting/manage-orders'} component={Cards} />
                    <Route path={'/merchant-admin/setting/manage-orders/payment-distance'} component={PaymentDistance} />
                    <Route path={'/merchant-admin/setting/manage-orders/payment-category'} component={PaymentCategory} />
                    <Route path={'/merchant-admin/setting/manage-orders/delivery-time'} component={DeliveryTime} />
                    <Route path={'/merchant-admin/setting/manage-orders/ride-type'} component={RideType} />
                    <Route path={'/merchant-admin/setting/manage-orders/package-size'} component={PackageSize} />
                </Switch>
            </section>
        </Fragment>
    )
}

export default ManageOrders