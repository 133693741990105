
import { riderTypes } from "../types";
import axios from "axios";
import { axiosConfig, requestStatus } from "../constants";
import { config } from '../config'
import Utils from "../helpers/utils";

const BIKE_BASE_URL = `${config.testUrl}/riders`
// dispatchers
const getRider = (pageRequest) => async (dispatch) =>{
    let reqPagination = pageRequest ? pageRequest : {current: 1, pageSize: 10}
    let url = `${BIKE_BASE_URL}?rows=${reqPagination.pageSize}&page=${reqPagination.current}`
    let header = axiosConfig()

    try {
        dispatch(loading(true))
        const response = await axios.get(url, header);
        const data = response.data;
        if(data && data.pagination){
            let payload = dataConstructor(data)
            dispatch(get_rider(payload))
        }   
        dispatch(loading(false))

    } catch (error) {
        dispatch(loading(false))
        dispatch(get_rider({status: requestStatus.failed}))
        return Utils.warningNotification('An error occured');
    }
}

const deleteRider = (id) => async(dispatch) =>{
    let url = `${config.testUrl}/rider/${id}`
    let header = axiosConfig()

    try {
        const response = await axios.delete(url, header);
        const data = response.data;
        if(data){
            Utils.successNotification('Action Successful')
            dispatch(getRider())
        }   
    } catch (error) {
        return Utils.warningNotification('An error occured');
    }
}
const disableRider = (id) => async(dispatch) =>{
    let url = `${config.testUrl}/rider/${id}`
    let header = axiosConfig()
    
    try {
        const response = await axios.post(url, {status: 'disabled'}, header);
        const data = response.data;
        if(data){
            Utils.successNotification('Action Successful')
            dispatch(getRider())
        }   
    } catch (error) {
        return Utils.warningNotification('An error occured');
    }
}

// actions
const get_rider = payload => {
    return{
        type: riderTypes.GET_RIDER_LIST,
        payload
    }
}
const loading = payload => {
    return{
        type: riderTypes.LOADING,
        payload
    }
}

// helper function
const dataConstructor = (data) =>{
    let pagination = {
        current: data.pagination.current_page,
        total: data.pagination.total,
        pageSize: data.pagination.per_page,
      }
    let payload = {
        data: data.pagination.data,
        pagination,
        status: requestStatus.success
    }
    return payload
}

export const riderActions = {
    getRider,
    deleteRider,
    disableRider
}