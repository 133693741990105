import { subscriptionTypes,subscriptionPayTypes,subscriptionHistoryTypes } from "../types"
import { subscriptionService } from "../services"

export const subscriptionActions = {
    getSubscription,
    getSingleSubscription,
    getSubscriptionStatus,
     putSubscription,
}

export const subscriptionPayActions = {
  postSubscription
}

export const subscriptionHistoryActions = {
  getSubscriptionHistory
}



function getSubscription () {
    return async dispatch => {
      dispatch(request())
  
      try {
        const subscription = await subscriptionService.getSubscription()
        dispatch(success(subscription))
        return subscription

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request () { return { type: subscriptionTypes.GET_SUBSCRIPTION_REQUEST } }
    function success (subscription) { return { type: subscriptionTypes.GET_SUBSCRIPTION_SUCCESS, subscription } }
    function failure (error) { return { type: subscriptionTypes.GET_SUBSCRIPTION_FAILURE, error } }
    
  }

  function postSubscription (id) {
    return async dispatch => {
      dispatch(request(id))
  
      try {
        const subscriptionPay = await subscriptionService.postSubscription(id)
        dispatch(success(subscriptionPay))
        return subscriptionPay

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (id) { return { type: subscriptionPayTypes.POST_SUBSCRIPTION_REQUEST,id } }
    function success (subscriptionPay) { return { type: subscriptionPayTypes.POST_SUBSCRIPTION_SUCCESS, subscriptionPay } }
    function failure (error) { return { type: subscriptionPayTypes.POST_SUBSCRIPTION_FAILURE, error } }
    
  }

  function putSubscription (id,formdata) {
    return async dispatch => {
      dispatch(request(id,formdata))
  
      try {
        const subscription = await subscriptionService.putSubscription(id,formdata)
        dispatch(success(subscription))
        return subscription

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (id,formdata) { return { type: subscriptionTypes.PUT_SUBSCRIPTION_REQUEST,id,formdata } }
    function success (subscription) { return { type: subscriptionTypes.PUT_SUBSCRIPTION_SUCCESS, subscription } }
    function failure (error) { return { type: subscriptionTypes.PUT_SUBSCRIPTION_FAILURE, error } }
    
  }

  function getSingleSubscription (id) {
    return async dispatch => {
      dispatch(request(id))
  
      try {
        const subscription = await subscriptionService.getSingleSubscription(id)
        dispatch(success(subscription))
        return subscription

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (id) { return { type: subscriptionTypes.GET_SINGLE_SUBSCRIPTION_REQUEST,id } }
    function success (subscription) { return { type: subscriptionTypes.GET_SINGLE_SUBSCRIPTION_SUCCESS, subscription } }
    function failure (error) { return { type: subscriptionTypes.GET_SINGLE_SUBSCRIPTION_FAILURE, error } }
    
  }

  function getSubscriptionStatus () {
    return async dispatch => {
      dispatch(request())
  
      try {
        const subscription = await subscriptionService.getSubscriptionStatus()
        dispatch(success(subscription))
        return subscription

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request () { return { type: subscriptionTypes.GET_SUBSCRIPTION_STATUS_REQUEST } }
    function success (subscription) { return { type: subscriptionTypes.GET_SUBSCRIPTION_STATUS_SUCCESS, subscription } }
    function failure (error) { return { type: subscriptionTypes.GET_SUBSCRIPTION_STATUS_FAILURE, error } }
    
  }
  function getSubscriptionHistory () {
    return async dispatch => {
      dispatch(request())
  
      try {
        const subscriptionHistory = await subscriptionService.getSubscriptionHistory()
        dispatch(success(subscriptionHistory))
        return subscriptionHistory

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request () { return { type: subscriptionHistoryTypes.GET_SUBSCRIPTION_HISTORY_REQUEST } }
    function success (subscriptionHistory) { return { type: subscriptionHistoryTypes.GET_SUBSCRIPTION_HISTORY_SUCCESS, subscriptionHistory } }
    function failure (error) { return { type: subscriptionHistoryTypes.GET_SUBSCRIPTION_HISTORY_FAILURE, error } }
    
  }
