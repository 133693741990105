import React from 'react';
import { Card, Divider } from 'antd';
import DropDown from './DropDown'

const Mobilecard = ({bikes}) => {
    const cards = bikes.data.map((data) => {
      let color = data.status === "Functional" ? '#7AAC52' : data.status === "Faulty" ? '#FFB000' :  '#E3000F';
      return (
        <Card key={data.id} className="my4 bikeCard">
          <div className="flex space-btw">
            <p style={{color: "#006FC1"}}><b>{data.name || "-"}</b></p>
            <p style={{color: color}}>{data.status}</p >

          </div>
          <div>
            <small>{data.user.name || "-"}</small>
            <Divider type="vertical"/>
            <small className="greyText">{data.plate_no || "-"}</small>
          </div>
          <div className="flex space-btw">
          <p>{data.user.address || "-"}</p>
          <DropDown data={data}/>
          </div>
        </Card>
      );
    });
    return (
        <div>
            {cards}
        </div>
    );
}

export default Mobilecard;
