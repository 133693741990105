import React from "react";
import { Button, Menu, Dropdown, Popconfirm } from "antd";
import WalletModal from "./viewWalletTransaction";
import { Icon } from "@iconify/react";
import { walletActions } from "../../../actions";
import { useDispatch } from "react-redux";

const Viewbike = (props) => {
  const dispatch = useDispatch();
  const deleteWallet = () => {
    const id = props.data.id;
    dispatch(walletActions.deleteWallet(id));
  };
  const menu = (
    <Menu className="bikeMenu">
      <Menu.Item className="bord" key={1}>
        <WalletModal data={props.data} />
      </Menu.Item>
      <Menu.Item key={2}>
        <Popconfirm
          title="Are you sure to disable?"
          onConfirm={deleteWallet}
          okText="Yes"
          cancelText="No"
        >
          <Button className="flex align-center" type="text">
            <Icon icon="fluent:delete-48-filled" className="icons" /> Delete
          </Button>
        </Popconfirm>
        {/* <DeleteBtn id ={id}/> */}
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} placement="bottomLeft" arrow>
      <Button size="middle" type="text">
        <Icon icon="bi:three-dots-vertical" />
      </Button>
    </Dropdown>
  );
};

export default Viewbike;
