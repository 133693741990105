import { intraStateDeliveryTypes } from "../types"


export function intraState (state = {}, action) {
  switch (action.type) {
    case intraStateDeliveryTypes.GET_INTRA_STATE_REQUEST:
      return {
        ...state
      }
    case intraStateDeliveryTypes.GET_INTRA_STATE_SUCCESS:
      return {
        ...state,
        intraState: action.intraState
      }
    case intraStateDeliveryTypes.GET_INTRA_STATE_FAILURE:
      return {
        ...state,
        error: action.error
      }
      case intraStateDeliveryTypes.PUT_INTRA_STATE_REQUEST:
        return {
          ...state
        }
      case intraStateDeliveryTypes.PUT_INTRA_STATE_SUCCESS:
        return {
          ...state,
        }
      case intraStateDeliveryTypes.PUT_INTRA_STATE_FAILURE:
        return {
          ...state,
          error: action.error
        }
    default:
      return state
  }
}
