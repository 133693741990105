import { teamOptionsService, teamService } from "../services"
import { TeamOptionsTypes, TeamTypes } from "../types"

export const teamActions = {
    getTeamMembers,
    addTeamMember,
    deleteTeamMember,
    updateTeamMember
}

export const teamOptionsActions = {
    singleTeamMember
} 

function getTeamMembers (page) {
    return async dispatch => {
      dispatch(request(page))
  
      try {
        const team = await teamService.getTeamMembers(page)
        dispatch(success(team))
        return team

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (page) { return { type: TeamTypes.GET_TEAM_OPTIONS_REQUEST,page } }
    function success (team) { return { type: TeamTypes.GET_TEAM_OPTIONS_SUCCESS, team } }
    function failure (error) { return { type: TeamTypes.GET_TEAM_OPTIONS_FAILURE, error } }
    
  }

  function addTeamMember (formdata) {
    return async dispatch => {
      dispatch(request(formdata))
  
      try {
        const team = await teamService.addTeamMember(formdata)
        dispatch(success(team))
        return team

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (formdata) { return { type: TeamTypes.ADD_TEAM_OPTIONS_REQUEST, formdata } }
    function success (team) { return { type: TeamTypes.ADD_TEAM_OPTIONS_SUCCESS, team } }
    function failure (error) { return { type: TeamTypes.ADD_TEAM_OPTIONS_FAILURE, error } }
    
  }

  function updateTeamMember (id,formdata) {
    return async dispatch => {
      dispatch(request(id,formdata))
  
      try {
        const team = await teamService.updateTeamMember(id,formdata)
        dispatch(success(team))
        return team

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (id,formdata) { return { type: TeamTypes.UPDATE_TEAM_OPTIONS_REQUEST,id,formdata } }
    function success (team) { return { type: TeamTypes.UPDATE_TEAM_OPTIONS_SUCCESS, team } }
    function failure (error) { return { type: TeamTypes.UPDATE_TEAM_OPTIONS_FAILURE, error } }
    
  }

  function deleteTeamMember (id) {
    return async dispatch => {
      dispatch(request(id))
  
      try {
        const team = await teamService.deleteTeamMember(id)
        dispatch(success(team))
        return team

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (id) { return { type: TeamTypes.DELETE_TEAM_OPTIONS_REQUEST, id } }
    function success (team) { return { type: TeamTypes.DELETE_TEAM_OPTIONS_SUCCESS, team } }
    function failure (error) { return { type: TeamTypes.DELETE_TEAM_OPTIONS_FAILURE, error } }
    
  }


  function singleTeamMember (id) {
    return async dispatch => {
      dispatch(request(id))
  
      try {
        const teamOptions = await teamOptionsService.singleTeamMember(id)
        dispatch(success(teamOptions))
        return teamOptions

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (id) { return { type: TeamOptionsTypes.GET_SINGLE_TEAM_REQUEST, id } }
    function success (teamOptions) { return { type: TeamOptionsTypes.GET_SINGLE_TEAM_SUCCESS, teamOptions } }
    function failure (error) { return { type: TeamOptionsTypes.GET_SINGLE_TEAM_FAILURE, error } }
    
  }
