export const interStateDeliveryTypes = {
   
    GET_INTER_STATE_REQUEST: 'GET_INTER_STATE_REQUEST',
    GET_INTER_STATE_SUCCESS: 'GET_INTER_STATE_SUCCESS',
    GET_INTER_STATE_FAILURE: 'GET_INTER_STATE_FAILURE',

    PUT_INTER_STATE_REQUEST: 'PUT_INTER_STATE_REQUEST',
    PUT_INTER_STATE_SUCCESS: 'PUT_INTER_STATE_SUCCESS',
    PUT_INTER_STATE_FAILURE: 'PUT_INTER_STATE_FAILURE',


}

export const intraStateDeliveryTypes = {
   
    GET_INTRA_STATE_REQUEST: 'GET_INTRA_STATE_REQUEST',
    GET_INTRA_STATE_SUCCESS: 'GET_INTRA_STATE_SUCCESS',
    GET_INTRA_STATE_FAILURE: 'GET_INTRA_STATE_FAILURE',

    PUT_INTRA_STATE_REQUEST: 'PUT_INTRA_STATE_REQUEST',
    PUT_INTRA_STATE_SUCCESS: 'PUT_INTRA_STATE_SUCCESS',
    PUT_INTRA_STATE_FAILURE: 'PUT_INTRA_STATE_FAILURE',
    
}

export const paymentDeliveryTypes = {

    GET_PAYMENT_DELIVERY_REQUEST: 'GET_PAYMENT_DELIVERY_REQUEST',
    GET_PAYMENT_DELIVERY_SUCCESS: 'GET_PAYMENT_DELIVERY_SUCCESS',
    GET_PAYMENT_DELIVERY_FAILURE: 'GET_PAYMENT_DELIVERY_FAILURE',

    PUT_PAYMENT_DELIVERY_REQUEST: 'PUT_PAYMENT_DELIVERY_REQUEST',
    PUT_PAYMENT_DELIVERY_SUCCESS: 'PUT_PAYMENT_DELIVERY_SUCCESS',
    PUT_PAYMENT_DELIVERY_FAILURE: 'PUT_PAYMENT_DELIVERY_FAILURE',

}

export const DeliveryTypes = {

    GET_PACKAGE_SIZE_REQUEST: 'GET_PACKAGE_SIZE_REQUEST',
    GET_PACKAGE_SIZE_SUCCESS: 'GET_PACKAGE_SIZE_SUCCESS',
    GET_PACKAGE_SIZE_FAILURE: 'GET_PACKAGE_SIZE_FAILURE',

    GET_SINGLE_PACKAGE_REQUEST: 'GET_SINGLE_PACKAGE_REQUEST',
    GET_SINGLE_PACKAGE_SUCCESS: 'GET_SINGLE_PACKAGE_SUCCESS',
    GET_SINGLE_PACKAGE_FAILURE: 'GET_SINGLE_PACKAGE_FAILURE',

    ADD_PACKAGE_SIZE_REQUEST: 'ADD_PACKAGE_SIZE_REQUEST',
    ADD_PACKAGE_SIZE_SUCCESS: 'ADD_PACKAGE_SIZE_SUCCESS',
    ADD_PACKAGE_SIZE_FAILURE: 'ADD_PACKAGE_SIZE_FAILURE',

    UPDATE_PACKAGE_SIZE_REQUEST: 'UPDATE_PACKAGE_SIZE_REQUEST',
    UPDATE_PACKAGE_SIZE_SUCCESS: 'UPDATE_PACKAGE_SIZE_SUCCESS',
    UPDATE_PACKAGE_SIZE_FAILURE: 'UPDATE_PACKAGE_SIZE_FAILURE',

    DELETE_PACKAGE_SIZE_REQUEST: 'DELETE_PACKAGE_SIZE_REQUEST',
    DELETE_PACKAGE_SIZE_SUCCESS: 'DELETE_PACKAGE_SIZE_SUCCESS',
    DELETE_PACKAGE_SIZE_FAILURE: 'DELETE_PACKAGE_SIZE_FAILURE',

}

export const DeliveryOptionsTypes = {

    GET_SINGLE_PACKAGE_REQUEST: 'GET_SINGLE_PACKAGE_REQUEST',
    GET_SINGLE_PACKAGE_SUCCESS: 'GET_SINGLE_PACKAGE_SUCCESS',
    GET_SINGLE_PACKAGE_FAILURE: 'GET_SINGLE_PACKAGE_FAILURE',
    
}

export const RideTypes = {

    GET_RIDE_TYPES_REQUEST: 'GET_RIDE_TYPES_REQUEST',
    GET_RIDE_TYPES_SUCCESS: 'GET_RIDE_TYPES_SUCCESS',
    GET_RIDE_TYPES_FAILURE: 'GET_RIDE_TYPES_FAILURE',

    ADD_RIDE_TYPES_REQUEST: 'ADD_RIDE_TYPES_REQUEST',
    ADD_RIDE_TYPES_SUCCESS: 'ADD_RIDE_TYPES_SUCCESS',
    ADD_RIDE_TYPES_FAILURE: 'ADD_RIDE_TYPES_FAILURE',

    UPDATE_RIDE_TYPES_REQUEST: 'UPDATE_RIDE_TYPES_REQUEST',
    UPDATE_RIDE_TYPES_SUCCESS: 'UPDATE_RIDE_TYPES_SUCCESS',
    UPDATE_RIDE_TYPES_FAILURE: 'UPDATE_RIDE_TYPES_FAILURE',

    DELETE_RIDE_TYPES_REQUEST: 'DELETE_RIDE_TYPES_REQUEST',
    DELETE_RIDE_TYPES_SUCCESS: 'DELETE_RIDE_TYPES_SUCCESS',
    DELETE_RIDE_TYPES_FAILURE: 'DELETE_RIDE_TYPES_FAILURE',

}

export const RideOptionsTypes = {

    GET_SINGLE_RIDE_REQUEST: 'GET_SINGLE_RIDE_REQUEST',
    GET_SINGLE_RIDE_SUCCESS: 'GET_SINGLE_RIDE_SUCCESS',
    GET_SINGLE_RIDE_FAILURE: 'GET_SINGLE_RIDE_FAILURE',
    
}

export const DeliveryTimeTypes = {

    GET_DELIVERY_TIMES_REQUEST: 'GET_DELIVERY_TIMES_REQUEST',
    GET_DELIVERY_TIMES_SUCCESS: 'GET_DELIVERY_TIMES_SUCCESS',
    GET_DELIVERY_TIMES_FAILURE: 'GET_DELIVERY_TIMES_FAILURE',

    ADD_DELIVERY_TIMES_REQUEST: 'ADD_DELIVERY_TIMES_REQUEST',
    ADD_DELIVERY_TIMES_SUCCESS: 'ADD_DELIVERY_TIMES_SUCCESS',
    ADD_DELIVERY_TIMES_FAILURE: 'ADD_DELIVERY_TIMES_FAILURE',

    UPDATE_DELIVERY_TIMES_REQUEST: 'UPDATE_DELIVERY_TIMES_REQUEST',
    UPDATE_DELIVERY_TIMES_SUCCESS: 'UPDATE_DELIVERY_TIMES_SUCCESS',
    UPDATE_DELIVERY_TIMES_FAILURE: 'UPDATE_DELIVERY_TIMES_FAILURE',

    DELETE_DELIVERY_TIMES_REQUEST: 'DELETE_DELIVERY_TIMES_REQUEST',
    DELETE_DELIVERY_TIMES_SUCCESS: 'DELETE_DELIVERY_TIMES_SUCCESS',
    DELETE_DELIVERY_TIMES_FAILURE: 'DELETE_DELIVERY_TIMES_FAILURE',

}

export const DeliveryTimeOptionsTypes = {

    GET_SINGLE_DELIVERY_REQUEST: 'GET_SINGLE_DELIVERY_REQUEST',
    GET_SINGLE_DELIVERY_SUCCESS: 'GET_SINGLE_DELIVERY_SUCCESS',
    GET_SINGLE_DELIVERY_FAILURE: 'GET_SINGLE_DELIVERY_FAILURE',
    
}

export const DeliverySummaryTypes = {

    GET_DELIVERY_SUMMARY_REQUEST: 'GET_DELIVERY_SUMMARY_REQUEST',
    GET_DELIVERY_SUMMARY_SUCCESS: 'GET_DELIVERY_SUMMARY_SUCCESS',
    GET_DELIVERY_SUMMARY_FAILURE: 'GET_DELIVERY_SUMMARY_FAILURE',
    
}