import { userTypes } from "../types"

const initState = {}
export const user =  (state = initState, action) => {
  switch (action.type) {
    case userTypes.GET_USER_LIST:
      return {
        ...state,
        usersList: action.payload
      }
    case userTypes.FILTER_USER_LIST:
      return {
        ...state,
        filteredList: action.payload
      }
    // case userTypes.VIEW_USER:
    //   return {
    //     ...state,
    //     userDetails: action.payload
    //   }
      case userTypes.LOADING:
        return {
          ...state,
          isLoading: !!action.payload
        }
    default:
      return state
  }
}
