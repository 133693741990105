import { categoryService } from "../services"
import { categoryTypes } from "../types"

export const categoryActions = {
    getCategories,
    addCategories,
    putCategories,
    deleteCategories
}

function getCategories (value) {
    return async dispatch => {
      dispatch(request(value))
  
      try {
        const category = await categoryService.getCategories(value)
        dispatch(success(category))
        return category

      } catch (error) {
        dispatch(failure(error.toString()))
        return error?.response
      }

    }
  
    function request (value) { return { type: categoryTypes.GET_CATEGORIES_REQUEST ,value} }
    function success (category) { return { type: categoryTypes.GET_CATEGORIES_SUCCESS, category } }
    function failure (error) { return { type: categoryTypes.GET_CATEGORIES_FAILURE, error } }
    
  }

  function addCategories (value) {
    return async dispatch => {
      dispatch(request(value))
  
      try {
        const category = await categoryService.addCategories(value)
        dispatch(success(category))
        return category

      } catch (error) {
        dispatch(failure(error.toString(value)))
        return error?.response
      }

    }
  
    function request (value) { return { type: categoryTypes.ADD_CATEGORIES_REQUEST,value } }
    function success (category) { return { type: categoryTypes.ADD_CATEGORIES_SUCCESS, category } }
    function failure (error) { return { type: categoryTypes.ADD_CATEGORIES_FAILURE, error } }
    
  }

  function putCategories (id,formdata) {
    return async dispatch => {
      dispatch(request(id,formdata))
  
      try {
        const category = await categoryService.putCategories(id,formdata)
        dispatch(success(category))
        return category

      } catch (error) {
        dispatch(failure(error.toString(id)))
        return error?.response
      }

    }
  
    function request (id,formdata) { return { type: categoryTypes.UPDATE_CATEGORIES_REQUEST,id,formdata } }
    function success (category) { return { type: categoryTypes.UPDATE_CATEGORIES_SUCCESS, category } }
    function failure (error) { return { type: categoryTypes.UPDATE_CATEGORIES_FAILURE, error } }
    
  }

  function deleteCategories (id) {
    return async dispatch => {
      dispatch(request(id))
  
      try {
        const category = await categoryService.deleteCategories(id)
        dispatch(success(category))
        return category

      } catch (error) {
        dispatch(failure(error.toString(id)))
        return error?.response
      }

    }
  
    function request (id) { return { type: categoryTypes.DELETE_CATEGORIES_REQUEST,id } }
    function success (category) { return { type: categoryTypes.DELETE_CATEGORIES_SUCCESS, category } }
    function failure (error) { return { type: categoryTypes.DELETE_CATEGORIES_FAILURE, error } }
    
}
