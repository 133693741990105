import React,{Fragment, useEffect,useState} from 'react'
import Header from '../../components/Header'
import "./style.css"
import { useDispatch, useSelector } from 'react-redux'
import { chartActions, dashboardActions } from '../../actions'
import { columns,columns2 } from './tabledata'
import { Table } from 'antd'
import {Line} from 'react-chartjs-2'
// eslint-disable-next-line
import Chart from 'chart.js/auto'
import Spinner from '../../components/Spinner'
import { Link } from 'react-router-dom'
// import { parse } from 'purgecss/node_modules/postcss'


const Home = () => {
 
  const dispatch = useDispatch()
  const dashboard = useSelector(state=>state.dashboard)
  const chart = useSelector(state=>state.chart)
  const [active,setActive] = useState('week')
  const [month,setMonth] = useState(null)
  const [year,setYear] = useState(null)

  useEffect(() => {
    dispatch(dashboardActions.getDashboard())
  }, [dispatch])

  useEffect(() => {
    dispatch(chartActions.getChartsInfo(active,month,year))
  }, [dispatch,active,month,year])

  useEffect(() => {
   if(month !== null & year!== null){
   setActive('')
   dispatch(chartActions.getChartsInfo('',month,year))
   }
   else{
    dispatch(chartActions.getChartsInfo(active,month,year))
   }
   // eslint-disable-next-line 
  }, [dispatch,month,year])

  let dashboardDetails = dashboard?.dashboard?.data
  let chartData = chart?.chart?.data
  // console.log(chartData)


  const paymentData = dashboardDetails?.recent_payment ? dashboardDetails?.recent_payment?.map((item,i) => ({
    key: item.id,
    id: item.id,
    SN: ++i,
    Name: item.user.full_name,
    Amount: (parseFloat(item.amount)).toLocaleString('en-US', {
      style: 'currency',
      currency: 'NGN',
    }),
    Status: item.status,
  })) : []


  const orderData = dashboardDetails?.recent_orders ? dashboardDetails?.recent_orders?.map((item,i) => ({
    key: ++i,
    id: item.id,
    SN: i,
    Name: item.sender_name,
    Delivery: item.item_description,
    Status: item.status
  })) : []

// eslint-disable-next-line
  const [graph, setGraph] = useState({
    payment: [],
    paymentValues: [],
    delivery:[],
    deliveryValues:[]
    // delivery: [],
  })

  useEffect(() => {
    let date = [0]
    let values = [0]
    let date2 = [0]
    let values2 = [0]

    chartData?.payment?.forEach(element => {
      date.push(element.Date)
      values.push(element.total)
    })

    chartData?.delivery?.forEach(element => {
      date2.push(element.Date)
      values2.push(element.total)
    })

    setGraph({
      payment: date,
      paymentValues: values,
      delivery: date2,
      deliveryValues: values2
    });

   }, [chartData])

  // console.log(graph)

  const LineGraph = () => {
    const dailylabels = graph?.payment?.length > 0 ? graph?.payment : [0];
    const dailyDeliverylabels = graph?.delivery?.length > 0 ? graph?.delivery : [0];
    // const dailylabels = [0, chartData?.payment?.['0']?.Date];
    // const weeklabels = [0,'Mon','Tues','Wed','Thurs','Fri','Sat','Sun'];
    // const yearlabels = [0,'Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    // const monthlabels = [0,2,4,6,8,10,12,14,16,18,20,22,24,26,28,30];
    const data = {
    labels: dailylabels?.length > 1 ? dailylabels : dailyDeliverylabels,
    datasets: [{
        label: 'Payment',
         data: graph?.paymentValues?.length > 0 ? graph?.paymentValues : [0],
        fill: false,
        borderColor: '#6DB637',
        borderWidth: 1.5,
        tension: 0.1
    },
    {
      label: 'Delivery',
      data: graph?.deliveryValues?.length > 0 ? graph?.deliveryValues : [0],
      fill: false,
      borderColor: '#006FC1',
      borderWidth: 1.5,
      tension: 0.1
  }]
    };

    const config = {
    type: 'line',
    data: data,
    maintainAspectRatio:false,
    responsive: true,
    plugins:{
      legend: {
        display: false
      },
    },
    };
    // let date = new Date
    // console.log('date')

 return (
     <Line 
     data={data} height={350} options={config}
     />
 )
}



  return (
    <Fragment>
    <div className='lg:h-screen flex flex-col px-0 lg:p-8'>
      <Header />

      {dashboardDetails && chartData  && paymentData && orderData ? (<div>


      <div className='mt-8 lg:mt-20 cards w-full px-4 lg:px-0 flex flex-col lg:flex-row justify-between lg:items-center'>
          
          
          <div className='balance  mt-8 lg:mt-0 h-48 flex-1 flex flex-col p-6 pb-2 shadow-sm justify-between rounded-xl'>

          <div className='flex flex-row justify-between items-center'>
                   <span className='p-4 rounded-2xl' style={{backgroundColor:'#187ac3'}}>
                      <img src={`${process.env.REACT_APP_IMAGE}/home-.svg`} alt='' className='h-8' />
                  </span>

                  <div>
                    <img src={`${process.env.REACT_APP_IMAGE}/white-dots.svg`} alt='' />
                  </div>

                </div>


                <div className='text-white mt-8 lg:mt-0'>
                    <b className='text-xl'>{parseFloat(dashboardDetails?.current_month?.replace(',','')).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'NGN',
                      })}</b>

                     <p className='mt-0'>
                      {dashboardDetails?.percentage_progress?.percentage} {dashboardDetails?.percentage_progress?.status === 'decrease' ? 'Decrease' : 
                        dashboardDetails?.percentage_progress?.status === 'increase' ? 'Increase' : ''
                       } this Month
                     </p>
                </div>
          </div>
         
          <div className='flex-1 h-48 rounded-xl flex flex-col bg-white p-6 pb-2 shadow-sm justify-between mt-8 lg:mt-0 lg:mx-8'>
                <div className='flex flex-row justify-between items-center'>
                   <span className='p-4 rounded-2xl' style={{backgroundColor:'#e5f1f9'}}>
                      <img src={`${process.env.REACT_APP_IMAGE}/transaction.png`} alt='' className='h-8' />
                  </span>

                  <div>
                    <img src={`${process.env.REACT_APP_IMAGE}/three-dots.svg`} alt='' />
                  </div>

                </div>


                <div className='text-gray-700 mt-8 lg:mt-0'>
                    <b className='text-xl'>{parseFloat(dashboardDetails?.today_transaction?.replace(',','')).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'NGN',
                      })}</b>

                     <p className='mt-0'>Today’s Total Transactions</p>
                </div>
          </div>


          <div className='flex-1 h-48 rounded-xl flex flex-col bg-white p-6 pb-2 shadow-sm justify-between mt-8 lg:mt-0'>
                <div className='flex flex-row justify-between items-center'>
                   <span className='p-4 rounded-2xl' style={{backgroundColor:'#f1f7eb'}}>
                      <img src={`${process.env.REACT_APP_IMAGE}/delivery-truck.svg`} alt='' className='h-8' />
                  </span>

                  <div>
                    <img src={`${process.env.REACT_APP_IMAGE}/green-dots.svg`} alt='' />
                  </div>

                </div>


                <div className='text-gray-700 mt-8 lg:mt-0'>
                    <b className='text-xl'>{dashboardDetails?.today_delivered}</b>

                     <p className='mt-0'>Today’s Total Delivery</p>
                </div>


                  
          </div>

      </div>

      {orderData?.length === 0 && paymentData?.length === 0 ? (
      // {chartData?.payment?.length < 1 && chartData?.delivery?.length < 1 ? (
      <div className='px-4 bg-white lg:flex-1 mt-8 lg:mt-8'>   
        <div className='h-full bg-white rounded-md flex justify-center items-center w-full '>

          <div className='text-center mx-auto lg:py-12'>
            <img src={`${process.env.REACT_APP_IMAGE}/package.png`} alt='' className='w-32 mx-auto mb-8' />
           
            <p>
            Information about your recent activities will appear here
            </p>

            <p>
            You do not have any activities here yet
            </p>
          </div>
       
        </div>

        </div>
          )
        :
         ( 
        <div className='mt-8'>

            <strong className='text-gray-500'>CHART INFORMATION</strong>
            <div className='w-full h-full bg-white mt-2 p-8'>
              <div className='flex flex-row my-4'>
                <button className={active === 'today' ? 'text-xs border-none flex justify-center p-2 rounded sm mr-8 bg-hex-blue text-white focus:outline-none' : 'text-xs border border-gray-200 flex justify-center p-2 rounded sm mr-8 focus:outline-none'} onClick={()=>setActive('today')}>Today</button>
                <button className={active === 'week' ? 'text-xs border-none flex justify-center p-2 rounded sm mr-8 bg-hex-blue text-white focus:outline-none' : 'text-xs border border-gray-200 flex justify-center p-2 rounded sm mr-8 focus:outline-none'} onClick={()=>setActive('week')}>This Week</button>
                <select className={active === 'month' ? 'text-xs border-none flex justify-center p-2 rounded sm mr-8 bg-hex-blue text-white focus:outline-none' : 'text-xs border border-gray-200 flex justify-center items-center p-2 rounded sm mr-8 focus:outline-none'} onChange={(e)=>setMonth(e.target.value)}>This Month
                  <option value=''>Month</option>
                  <option value='1'>January</option> 
                  <option value='2'>February</option> 
                  <option value='3'>March</option> 
                  <option value='4'>April</option> 
                  <option value='5'>May</option> 
                  <option value='6'>June</option> 
                  <option value='7'>July</option> 
                  <option value='8'>August</option> 
                  <option value='9'>September</option> 
                  <option value='10'>October</option> 
                  <option value='11'>November</option> 
                  <option value='12'>December</option> 
                </select>
                <select className={active === 'year' ? 'text-xs border-none flex justify-center p-2 rounded sm mr-8 bg-hex-blue text-white focus:outline-none' : 'text-xs border border-gray-200 flex justify-center items-center p-2 rounded sm focus:outline-none'} onChange={(e)=>setYear(e.target.value)}>2022
                  <option value=''>Year</option>
                  <option value='2022'>2022</option> 
                  <option value='2021'>2021</option> 
                  <option value='2020'>2020</option> 
                {/* <FontAwesomeIcon icon={faChevronDown} size='1x' className='ml-2' /> */}
                </select>
              </div>
            <div>
              <LineGraph />
            </div>
              
      

        <div className='mt-8 text-xs'>
          <div className='flex flex-row'>
            <p className='h-4 w-8 bg-hex-blue mx-4'></p>
            <label>Delivery</label>
          </div>

          <div className='flex flex-row'>
            <p className='h-4 w-8 mx-4' style={{backgroundColor:'#6DB637'}}></p>
            <label>Payment</label>
          </div>

        </div>       
    
        </div>

      </div>
      )} 

        <div className='px-4 mt-8 lg:hidden pb-8'>

         <div className='bg-white p-4 shadow-sm rounded-xl'>
              <div className="flex flex-row justify-between items-center">
                  <b>RIDERS DELIVERY SUMMARY</b>
                  <a href='#!' className="text-gray-500 hover:text-gray-500 underline text-xs">View All</a>
              </div>


              <img src={`${process.env.REACT_APP_IMAGE}/box2.png`} alt='' className="w-24 mx-auto mt-8" />
              <p className="text-center mt-4">No Active Delivery</p>
          </div>

         <div className='mt-8 bg-white p-4 shadow-sm rounded-xl'>
              <div className="flex flex-row justify-between items-center">
                  <b>RECENT REQUEST</b>
                  <a href='#!' className="text-gray-500 hover:text-gray-500 underline text-xs">View All</a>
              </div>


              <img src={`${process.env.REACT_APP_IMAGE}/folder2.png`} alt='' className="w-24 mx-auto mt-8" />
              <p className="text-center mt-4">No Active Request</p>
          </div>
        

        </div>


        <div className='table mt-12 pb-8 grid lg:grid-cols-2 gap-8'>
             <div className=''>
               <div className='flex flex-row justify-between items-center py-2'>
                    <b className='text-gray-500'>RECENT PAYMENT</b>

                    <Link to='/merchant-admin/wallet' className = 'bg-hex-blue hover:bg-blue-900 text-white hover:text-white text-xs px-2 border-none rounded-sm'>View all</Link>
               </div>
                  <Table 
                  dataSource={paymentData}
                  columns={columns}   
                  pagination = {{defaultPageSize:10}}
                  />
            </div>


            <div className=''>
               <div className='flex flex-row justify-between items-center py-2'>
                    <b className='text-gray-500'>RECENT ORDERS</b>

                    <Link to='/merchant-admin/orders' className = 'bg-hex-blue hover:bg-blue-900 text-white hover:text-white text-xs px-2 border-none rounded-sm'>View all</Link>

               </div>
                  <Table 
                  dataSource={orderData}
                  columns={columns2}    
                  pagination = {{defaultPageSize:10}}
                  />
                </div>
        </div>
   

    </div>) : (<Spinner />)}
    </div>
    </Fragment>
  )
}

export default Home
