import { Switch } from 'antd'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { interStateDeliveryActions, intraStateDeliveryActions, paymentDistanceActions } from '../../../../actions'

const Cards = () => {
    const dispatch = useDispatch()
    const interState = useSelector(state=>state.interState)
    const distance = useSelector(state=>state.distance)
    const intraState = useSelector(state=>state.intraState)
// eslint-disable-next-line
    const [checked,setChecked] = useState(true)
// eslint-disable-next-line
    const [checked2,setChecked2] = useState(true)

    useEffect(() => {
       dispatch(interStateDeliveryActions.getInterStateDelivery())
    }, [dispatch])

    useEffect(() => {
        dispatch(intraStateDeliveryActions.getIntraStateDelivery())
     }, [dispatch])

     useEffect(() => {
        dispatch(paymentDistanceActions.getDistancePayment())
     }, [dispatch])

     let interStateValue = interState?.interState?.data?.value
     let intraStateValue = intraState?.intraState?.data?.value
     let distanceDetails = distance?.distance?.data

     useEffect(() => {
         setTag(interStateValue)
         // eslint-disable-next-line
     }, [interState?.interState])

     useEffect(() => {
        setTags(intraStateValue)
        // eslint-disable-next-line
    }, [intraState?.intraState])

    console.log(distanceDetails)


     const [tag,setTag] = useState(interStateValue)
     const [tags,setTags] = useState(intraStateValue)


     const handleInput = async (value) => {


        if(value === 0){
           setTag(1)
            const response = await dispatch(interStateDeliveryActions.putInterStateDelivery(1))
                 if(response.status === 200) {
                    toast.success(response?.data?.message,{
                        autoClose: 2000,
                        hideProgressBar: true})
                    }
                    else {
                        toast.error(response?.data?.message,{
                        autoClose: 2000,
                        hideProgressBar: true })
                        }

        }
        else{
           setTag(0)
           const response = await dispatch(interStateDeliveryActions.putInterStateDelivery(0))
                if(response.status === 200) {
                    toast.success(response?.data?.message,{
                        autoClose: 2000,
                        hideProgressBar: true})
                    }
                    else {
                        toast.error(response?.data?.message,{
                        autoClose: 2000,
                        hideProgressBar: true })
                        }
        }
                dispatch(interStateDeliveryActions.getInterStateDelivery())
     }

     const handleInput2 = async (value) => {


        if(value === 0){
           setTags(1)
            const response = await dispatch(intraStateDeliveryActions.putIntraStateDelivery(1))
                 if(response.status === 200) {
                    toast.success(response?.data?.message,{
                        autoClose: 2000,
                        hideProgressBar: true})
                    }
                    else {
                        toast.error(response?.data?.message,{
                        autoClose: 2000,
                        hideProgressBar: true })
                        }

        }
        else{
           setTags(0)
           const response = await dispatch(intraStateDeliveryActions.putIntraStateDelivery(0))
                if(response.status === 200) {
                    toast.success(response?.data?.message,{
                        autoClose: 2000,
                        hideProgressBar: true})
                    }
                    else {
                        toast.error(response?.data?.message,{
                        autoClose: 2000,
                        hideProgressBar: true })
                        }
        }
                dispatch(intraStateDeliveryActions.getIntraStateDelivery())
     }

     
    return (
        <Fragment>
             {interStateValue !== '' ? (<div>
                <div className='w-4/6 border border-gray-300 px-4 py-3 text-sm flex flex-row justify-between items-center rounded-md'>
                    <span>Inter-State Delivery </span>
                        <Switch checked={tag === 1 ? checked : !checked} onChange={()=>handleInput(tag)} />
                </div>
                <div className='mt-6 w-4/6 border border-gray-300 px-4 py-3 text-sm flex flex-row justify-between items-center rounded-md'>
                    <span>Intra-State Delivery </span>
                        <Switch checked={tags === 1 ? checked2 : !checked2} onChange={()=>handleInput2(tags)} />

                    {/* <button className='text-hex-blue'> */}
                        {/* <Switch /> */}
                    {/* </button> */}
                </div>
                <div className='mt-6 w-4/6 border border-gray-300 px-4 py-3 text-sm flex flex-row items-center rounded-md'>
                    <div className='h-4 w-4 bg-hex-blue rounded-full mx-1'></div>
                    <div className='flex flex-col ml-4'>
                        <b>Determine Payment By Distance</b>
                        <label className='text-xs text-gray-400'>New price now cost N{distanceDetails?.price} per km  </label>
                    </div>
                    <Link to='/merchant-admin/setting/manage-orders/payment-distance' className='text-hex-blue hover:text-hex-blue ml-auto'>Edit</Link>
                </div>
                <div className='mt-6 w-4/6 border border-gray-300 px-4 py-3 text-sm flex flex-row items-center rounded-md'>
                    <div className='h-4 w-4 bg-hex-blue rounded-full mx-1'></div>
                    <div className='flex flex-col ml-4'>
                        <b>Determine Payment With Fixed Price</b>
                        <label className='text-xs text-gray-400'>Payment for Category.</label>
                    </div>
                    <Link to='/merchant-admin/setting/manage-orders/payment-category' className='text-hex-blue ml-auto'>Edit</Link>
                </div>
                <div className='mt-6 w-4/6 border border-gray-300 px-4 py-3 text-sm flex flex-row justify-between items-center rounded-md'>
                    <span>Determine price for delivery time</span>
                    <Link to='/merchant-admin/setting/manage-orders/delivery-time' className='text-hex-blue'>Edit</Link>
                </div>
                <div className='mt-6 w-4/6 border border-gray-300 px-4 py-3 text-sm flex flex-row justify-between items-center rounded-md'>
                    <span>Determine price for ride type</span>
                    <Link to='/merchant-admin/setting/manage-orders/ride-type' className='text-hex-blue'>Edit</Link>
                </div>
                <div className='mt-6 w-4/6 border border-gray-300 px-4 py-3 text-sm flex flex-row justify-between items-center rounded-md'>
                    <span>Determine price for package size</span>
                    <Link to='/merchant-admin/setting/manage-orders/package-size' className='text-hex-blue'>Edit</Link>
                </div>
            </div>) : ''}
        </Fragment>
    )
}

export default Cards
