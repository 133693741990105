import React, {useEffect} from 'react';
import { Table, Tag, Spin } from 'antd';
import DropDown from './DropDown'
import { useDispatch, useSelector } from 'react-redux'
import { orderActions } from '../../../actions'
import Utils from '../../../helpers/utils';
import EmptyData from './EmptyData';

const CompletedOrderTable = ({data}) => {
  const dispatch = useDispatch()
  const completedOrderDetails = useSelector(state=>state.completedOrderDetails)
  const completedOrderList = completedOrderDetails?.completedOrderList
  console.log(completedOrderList)
  const Loading = completedOrderDetails?.isLoading
  useEffect(() => {
    dispatch(orderActions.getCompletedOrders())
  }, [dispatch]);

  const handleTableChange = (pagination) =>{
    dispatch(orderActions.getCompletedOrders(pagination))
  }

  const dataSource = completedOrderList?.data
  const pagination =  completedOrderList?.pagination

    const columns = [
      {
        title: 'S/N',
        dataIndex: 'key',
        key: 'key',
        render:(item, record, index)=>{
          return <p>{Utils.getSerialNumber(pagination.current, index, pagination.pageSize)}</p>
        }
      },
      {
        title: 'Sender Name',
        dataIndex: 'sender_info',
        render: (sender_info) => {
          return <span>{sender_info.sender_name}</span>},
        key: 'sender_name',
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: status => {
            let color = status === "completed" ? '#7AAC52' : status === "Faulty" ? '#FFB000' :  '#E3000F';
             return(
                <Tag color={color} className="bikeTags">
                {status}
              </Tag>
             )
        },
      },
    
      {
        title: 'Receiver Name',
        dataIndex: 'receiver_info',
        render: (receiver_info) => {
          return <span>{receiver_info.receiver_name}</span>},
        // key: 'receiver_name',
      },
      
      {
        title: 'Description',
        dataIndex: 'item_description',
        key: 'item_description',
      },
      {
        title: 'Amount',
        dataIndex: 'payment',
        render: (payment) => {
          return <span>{payment.price}</span>},
        key: 'amount',
      },
      {
        title: 'Action',
        key: 'row',
        render: (row) => {
          return( <DropDown data={row} type="completedOrders"/>)
        },
      },
    ];
    
      
    return (
      <>
      {
        Loading || !completedOrderList?.data ? 
        <div className="spinWrapper">
        <Spin />
        </div> 
      :
        !Loading && completedOrderList?.data?.length > 0 ?
        <>
        <div className="d-sm-none">
        <Table dataSource={dataSource} columns={columns} className="bikeTable"
      pagination={ completedOrderList?.pagination} onChange={handleTableChange}/>
    </div>
    <div className="d-lg-none">
    {/* <MobileCardBike data={data}/> */}
    </div>
    </>
    :
    <EmptyData/>
      }
  </>
  );
}
    export default CompletedOrderTable;

    