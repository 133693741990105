import React, { Fragment } from 'react'

const LoginImage = () => {
    return (
        <Fragment>

            <h1 className='mt-12 lg:mt-32 text-white text-3xl font-bold text-center'>
                Moving your Products Across Borders
            </h1>

            <p className='text-white mt-4 text-md text-center'>
            Had some logistics hassle in the past. Worry no more, With our fast and fully automated system, you are guaranted a reliable system
            </p>

            <div className='mt-12 flex flex-row justify-center items-center'>
                <img src={`${process.env.REACT_APP_IMAGE}/BadgeiOS.png`} alt='' className='h-12 mx-2' />
                <img src={`${process.env.REACT_APP_IMAGE}/BadgeAndroid.png`} alt='' className='h-12 mx-2' />
            </div>
    
        </Fragment>
    
    )
}

export default LoginImage
