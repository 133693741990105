import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const columns = ({activeId, setActiveId,handleEdit,handleDelete}) => [
    {
        title: 'S/N',
        dataIndex: 'SN',
        key: 'SN',
      },
      {
        title: 'Delivery Time',
        dataIndex: 'DeliveryTime',
        key: 'DeliveryTime',
      },
      {
        title: 'Name',
        dataIndex: 'Name',
        key: 'Name',
      },
      {
        title: 'Price',
        dataIndex: 'Price',
        key: 'Price',
      },
      {
        title: 'Action',
           render: (row) => 
           (
           <div onClick={(e) => {
             e.stopPropagation()
             setActiveId(row.id)
           }}   className="relative flex flex-row justify-center">
               <FontAwesomeIcon  icon={faEllipsisV} color="#006fc1" size="sm" />
              
               {activeId === row.id && (
               <div className="absolute top-0 bottom-0 flex flex-col bg-white w-16 h-16 z-10 border border-gray-200 justify-center items-center">
                 <button onClick={()=>handleEdit(row)} className="hover:bg-gray-100 w-full h-full">Edit</button>
                 <button onClick={()=>handleDelete(row)} className="hover:bg-gray-100 w-full h-full">Delete</button>
               </div>
           )}
             </div>
             )
         },
]

export const columns2 = ({activeId, setActiveId,handleEdit,handleDelete}) => [
    {
        title: 'S/N',
        dataIndex: 'SN',
        key: 'SN',
      },
      {
        title: 'Ride Type',
        dataIndex: 'RideType',
        key: 'RideType',
      },
      {
        title: 'Price',
        dataIndex: 'Price',
        key: 'Price',
      },
      {
        title: 'Icon URL',
        dataIndex: 'IconURL',
        key: 'IconURL',
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        render: status => 
        (<div className={`${status === 0 ? 'bg-warning flex justify-center items-center text-black  rounded-sm text-xs' : status === 1 ? 'bg-success flex justify-center items-center text-black  rounded-sm text-xs' : 'flex justify-center items-center text-black  rounded-sm text-xs'}`}>
        {status === 0 ? 'Inactive' : status === 1 ? 'Active' : ''}
      </div> )
      },
    {
     title: 'Action',
        render: (row) => 
        (
        <div onClick={(e) => {
          e.stopPropagation()
          setActiveId(row.id)
        }}   className="relative flex flex-row justify-center">
            <FontAwesomeIcon  icon={faEllipsisV} color="#006fc1" size="sm" />
           
            {activeId === row.id && (
            <div className="absolute top-0 bottom-0 flex flex-col bg-white w-16 h-16 z-10 border border-gray-200 justify-center items-center">
              <button onClick={()=>handleEdit(row)} className="hover:bg-gray-100 w-full h-full">Edit</button>
              <button onClick={()=>handleDelete(row)} className="hover:bg-gray-100 w-full h-full">Delete</button>
            </div>
        )}
          </div>
          )
      },
]

export const columns3  = ({activeId, setActiveId,handleEdit,handleDelete}) => [
    {
        title: 'S/N',
        dataIndex: 'SN',
        key: 'SN',
      },
      {
        title: 'Package Size',
        dataIndex: 'PackageSize',
        key: 'PackageSize',
      },
      {
        title: 'Price',
        dataIndex: 'Price',
        key: 'Price',
      },
      {
        title: 'Image URL',
        dataIndex: 'ImageURL',
        key: 'ImageURL',
        width:'10%'
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        render: status => 
        (<div className={`${status === 0 ? 'bg-warning flex justify-center items-center text-black  rounded-sm text-xs' : status === 1 ? 'bg-success flex justify-center items-center text-black  rounded-sm text-xs' : 'flex justify-center items-center text-black  rounded-sm text-xs'}`}>
        {status === 0 ? 'Inactive' : status === 1 ? 'Active' : ''}
      </div> )
      },
      {
        title: 'Action',
        render: (row) => 
        (
        <div onClick={(e) => {
          e.stopPropagation()
          setActiveId(row.id)
        }}   className="relative flex flex-row justify-center">
            <FontAwesomeIcon  icon={faEllipsisV} color="#006fc1" size="sm" />
           
            {activeId === row.id && (
            <div className="absolute top-0 bottom-0 flex flex-col bg-white w-16 h-16 z-10 border border-gray-200 justify-center items-center">
              <button onClick={()=>handleEdit(row)} className="hover:bg-gray-100 w-full h-full">Edit</button>
              <button onClick={()=>handleDelete(row)} className="hover:bg-gray-100 w-full h-full">Delete</button>
            </div>
        )}
          </div>
          )
      },
]