import { categoryTypes } from "../types"


export function category (state = {}, action) {
  switch (action.type) {
    case categoryTypes.GET_CATEGORIES_REQUEST:
      return {
        ...state
      }
    case categoryTypes.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        category: action.category
      }
    case categoryTypes.GET_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case categoryTypes.ADD_CATEGORIES_REQUEST:
      return {
        ...state
      }
    case categoryTypes.ADD_CATEGORIES_SUCCESS:
      return {
        ...state,
      }
    case categoryTypes.ADD_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.error
      }
      case categoryTypes.UPDATE_CATEGORIES_REQUEST:
        return {
          ...state
        }
      case categoryTypes.UPDATE_CATEGORIES_SUCCESS:
        return {
          ...state,
        }
      case categoryTypes.UPDATE_CATEGORIES_FAILURE:
        return {
          ...state,
          error: action.error
        }
      
        case categoryTypes.DELETE_CATEGORIES_REQUEST:
      return {
        ...state
      }
    case categoryTypes.DELETE_CATEGORIES_SUCCESS:
      return {
        ...state,
      }
    case categoryTypes.DELETE_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.error
      }
      
    default:
      return state
  }
}
