import React, { useState } from 'react';
import {Button, Modal } from 'antd';
import { Icon } from '@iconify/react';

const ViewUserModal = ({data}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Button type="text" onClick={showModal} className="flex align-center">
                <Icon icon="ant-design:eye-outlined" className="icons" /> <small>View</small>
            </Button>
            <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false}>
                <h2>View User</h2>
                <p className="greyText">Please view all the details in the user list below carefully and confirm
                    all inputs</p>
                <div>
                <div className="flex space-btw left bord userInfo">
                    <p>User Name: </p>
                    <p>{data.full_name}</p>
                    </div>
                <div className="flex space-btw left bord userInfo">
                <p>Status: </p>
                    <p>{data.status}</p>
                </div>
                <div className="flex space-btw left bord userInfo">
                <p>Phone Number: </p>
                    <p>{data.phone}</p>
                </div>
                <div className="flex space-btw left bord userInfo">
                <p>Location: </p>
                    <p>{data.location}</p>
                </div>
                <div className="flex space-btw left bord userInfo">
                <p>Email Address: </p>
                    <p>{data.email}</p>
                </div>
                <div className="flex space-btw left userInfo">
                <p>Image: </p>
                    { <img src={data.profile_image ? data.profile_image : `${process.env.REACT_APP_IMAGE}/userImg.png`} className="userImg" alt="user"/> }
                </div>
                </div>
                <Button className="modalBtn"  onClick={handleCancel}>Close</Button>
            </Modal>
        </>
    );
};

export default ViewUserModal


