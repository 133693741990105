
import { authHeader } from "../helpers"
import { config } from '../config'
 const userInfo = JSON.parse(sessionStorage.getItem("user")) || {}
 // eslint-disable-next-line
 const token = userInfo.token || "";
export const axiosConfig = () =>{ 
  return {
    headers: {
       ...authHeader(),
      "Content-Type": "application/json",
    },
  }};
  export const requestStatus  ={
      success: "success",
      failed: "failed",
  }

export const BASE_URL = `${config.testUrl}`