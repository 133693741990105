import React from "react";
import { Card, Dropdown, Empty, Menu } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch , faCog , faBell , faAngleDown, faBars } from "@fortawesome/free-solid-svg-icons";
import { useHistory , Link } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { authActions } from "../actions";

const Header = (props) => {
  const dashboard = useSelector(state=>state.dashboard)
  const history = useHistory()
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(authActions.logout())
    history.push('/merchant-admin/login')
  }
  const notifications = dashboard?.dashboard?.data?.notifications



  const notification = ()=> {
  
    return(
    <>
      <Menu>
      {notifications?.length > 0 ? notifications?.slice(0,10).map((item)=>(
      <>
      <div className="p-2 border-b border-gray-200">
          <h1 className="mt-2 font-bold">{item.title}</h1>
           <p className="mt-6">{item.log}</p>
      </div>
      </>
    )) : (
        <div className="p-2 border border-gray-200 rounded-md">
             <div className='p-2'>
               <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No new notifications'} />
             </div>
      </div>
    )}
      </Menu>
    </>
  )
}
  return (
    <div className="w-full">
      <Card className="w-full" >
        <div className="w-full flex flex-row lg:justify-between items-center h-8">

                  <div className="block lg:hidden">
                    <img src={`${process.env.REACT_APP_MERCHANT_IMAGE}/merchantLogo.png`} alt="" className="w-8" />
                  </div>
                  
                   <span className="ml-4 lg:ml-0 company-name text-md text-gray-700 font-bold">Welcome to {" "} {process.env.REACT_APP_MERCHANT_TITLE}</span>

                  <div className="hidden border-none bg-gray-100 flex-1 lg:flex flex-row items-center px-4 py-2 rounded-3xl mx-12">
                    <button>
                        <FontAwesomeIcon  size="lg" icon={faSearch}/>
                    </button>
                    <input type={'text'} placeholder="Search transactions, merchants, invoice, tracking" className=" ml-4 h-full border-none bg-gray-100 flex-1 outline-none" />
                  </div>
              
                  
                  
                  <div className="hidden lg:grid grid-cols-3 gap-3">
                     
                      <Dropdown overlay={notification} placement="bottomCenter">
                      <button className="icon-badge-blue">
                        <FontAwesomeIcon icon={faBell} color="#006fc1" size="lg" />
                      </button>

                      </Dropdown>
                      <Link to='/merchant-admin/setting/customize-application'>
                      <button className="icon-badge-blue">
                        <FontAwesomeIcon icon={faCog} color="#006fc1" size="lg" />
                      </button>
                      </Link>

                      <button className="icon-badge-blue" onClick={()=>logout()}>
                          <LogoutOutlined size={'lg'} style={{color:'#006fc1'}}   />
                      </button>
                  </div>

                  <div className="grid grid-cols-2 gap-4 ml-auto lg:hidden">
                    <button>
                      <FontAwesomeIcon icon={faAngleDown} size="lg" />
                    </button>

                    <button>
                      <FontAwesomeIcon icon={faBars} size="lg" />
                    </button>
                  </div>
        </div>
      </Card>


                {/* <div className="mt-4 border-none flex-1 flex lg:hidden flex-row items-center px-4 py-4 rounded-md mx-4 bg-gray-200">
                    <button>
                        <FontAwesomeIcon  size="lg" icon={faSearch}/>
                    </button>
                    <input type={'text'} placeholder="Search transactions, merchants, invoice, tracking"  className=" ml-4 h-full border-none flex-1 outline-none bg-gray-200" />
                  </div> */}
    </div>
  );
};

export default Header;
