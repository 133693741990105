import React from 'react';

const Emptyuser = () => {
    return (
        <div className="flex center empty">
            <div>
                <div className="flex center"><img src={`${process.env.REACT_APP_IMAGE}/team.png`} alt="users" /></div>
                <p>You do not have transactions yet</p>
                <p>Immediately you perform a transaction, all records will be displayed here</p>
            </div>
        </div>
    );
}

export default Emptyuser;
