
  export const mountScript = (handleScriptLoad) => {
    window.google  =  undefined 
    window.addEventListener
    ? window.addEventListener("load", createScript(handleScriptLoad))
    : window.attachEvent("load", createScript(handleScriptLoad));
    
  }

export const unmountScript = () =>{
    window.google  =  undefined
    const scriptList = document.querySelectorAll("script[type='text/javascript']")
    const convertedNodeList = Array.from(scriptList)
    const testScript = convertedNodeList.find(script => script.id === "mapScript")
    document.getElementById("mapScript").parentNode.removeChild(testScript)
}
  
  export const createScript = (handleScriptLoad) => {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "mapScript"
    script.onload = () => handleScriptLoad();
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=geometry,drawing`;
    document.getElementsByTagName("head")[0].appendChild(script);
  };
