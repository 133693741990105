import { chartsTypes } from "../types"


export function chart (state = {}, action) {
  switch (action.type) {
    case chartsTypes.GET_CHARTS_REQUEST:
      return {
        ...state
      }
    case chartsTypes.GET_CHARTS_SUCCESS:
      return {
        ...state,
        chart: action.chart
      }
    case chartsTypes.GET_CHARTS_FAILURE:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}
