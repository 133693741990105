export const TeamTypes = {

    GET_TEAM_OPTIONS_REQUEST: 'GET_TEAM_OPTIONS_REQUEST',
    GET_TEAM_OPTIONS_SUCCESS: 'GET_TEAM_OPTIONS_SUCCESS',
    GET_TEAM_OPTIONS_FAILURE: 'GET_TEAM_OPTIONS_FAILURE',

    ADD_TEAM_OPTIONS_REQUEST: 'ADD_TEAM_OPTIONS_REQUEST',
    ADD_TEAM_OPTIONS_SUCCESS: 'ADD_TEAM_OPTIONS_SUCCESS',
    ADD_TEAM_OPTIONS_FAILURE: 'ADD_TEAM_OPTIONS_FAILURE',

    UPDATE_TEAM_OPTIONS_REQUEST: 'UPDATE_TEAM_OPTIONS_REQUEST',
    UPDATE_TEAM_OPTIONS_SUCCESS: 'UPDATE_TEAM_OPTIONS_SUCCESS',
    UPDATE_TEAM_OPTIONS_FAILURE: 'UPDATE_TEAM_OPTIONS_FAILURE',

    DELETE_TEAM_OPTIONS_REQUEST: 'DELETE_TEAM_OPTIONS_REQUEST',
    DELETE_TEAM_OPTIONS_SUCCESS: 'DELETE_TEAM_OPTIONS_SUCCESS',
    DELETE_TEAM_OPTIONS_FAILURE: 'DELETE_TEAM_OPTIONS_FAILURE',

}

export const TeamOptionsTypes = {

    GET_SINGLE_TEAM_REQUEST: 'GET_SINGLE_TEAM_REQUEST',
    GET_SINGLE_TEAM_SUCCESS: 'GET_SINGLE_TEAM_SUCCESS',
    GET_SINGLE_TEAM_FAILURE: 'GET_SINGLE_TEAM_FAILURE',
    
}