export const mappingsTypes = {
   
    GET_MAPPINGS_REQUEST: 'GET_MAPPINGS_REQUEST',
    GET_MAPPINGS_SUCCESS: 'GET_MAPPINGS_SUCCESS',
    GET_MAPPINGS_FAILURE: 'GET_MAPPINGS_FAILURE',

    ADD_MAPPINGS_REQUEST: 'ADD_MAPPINGS_REQUEST',
    ADD_MAPPINGS_SUCCESS: 'ADD_MAPPINGS_SUCCESS',
    ADD_MAPPINGS_FAILURE: 'ADD_MAPPINGS_FAILURE',

    UPDATE_MAPPINGS_REQUEST: 'UPDATE_MAPPINGS_REQUEST',
    UPDATE_MAPPINGS_SUCCESS: 'UPDATE_MAPPINGS_SUCCESS',
    UPDATE_MAPPINGS_FAILURE: 'UPDATE_MAPPINGS_FAILURE',

    DELETE_MAPPINGS_REQUEST: 'DELETE_MAPPINGS_REQUEST',
    DELETE_MAPPINGS_SUCCESS: 'DELETE_MAPPINGS_SUCCESS',
    DELETE_MAPPINGS_FAILURE: 'DELETE_MAPPINGS_FAILURE',

}